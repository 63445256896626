.add-new-product {
  .section-a {
    width: 990px;
    .form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: 12px;
      label {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-align: left;
        margin-bottom: 6px;
      }
      .input {
        width: 100%;;
        height: 56px;
        border-radius: 100px;
        border: 1px solid lightgray;
        background-color: white;
        padding-left: 12px;
        padding-right: 12px;
      
      }
      .select{
        padding-right: 20px;
        ::placeholder{
          font-size: 16px;
        }
      }
    }
  }
  
  .section-b {
    width: 1010px;
    .sub-section {
        width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .sec-a{
        width: 50%;

      }
      trash-a{
        
      }
      .sec-b{
        width: 49%;
      }
      .trash-b{
        width: 2%;
        margin-top: 8px;
      }
      .form-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        margin-top: 15px;
       
        label {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          text-align: left;
          margin-bottom: 6px;
             span{
            color: green ;
           .fa-plus{
            color: green ;
          }}
        }
     
        
        }
        .input {
          width: 100%;
          height: 56px;
          border-radius: 100px;
          border: 1px solid lightgray;
          background-color: white;
          padding-left: 12px;
          
        }
    }
  }
  .container-add-location{
    margin-top: 2rem;
    padding-top: 9px;
    padding-bottom: 9px;
     border: 1px solid lightgray;
  }
   .container-no-location{
    margin-top: 2rem;
    padding-top: 9px;
    padding-bottom: 9px;
  }
  
 

  // .uploadImg {
  //   .title {
  //     font-weight: 500;
  //     font-size: 16px;
  //     line-height: 150%;
  //     color: #000000;
  //   }
  //   .desc {
  //     font-weight: 500;
  //     font-size: 10px;
  //     line-height: 140%;
  //     color: #868484;
  //   }

  //   .addbtn {
  //     width: 46px;
  //     height: 63px;
  //     left: 561px;
  //     top: 683px;
  //     background: rgba(52, 186, 150, 0.1);
  //     border-radius: 10px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     cursor: pointer;
  //     i {
  //       cursor: pointer;
  //     }
  //   }

  //   .uploadeIpx;mgCont {
  //     position: relative;
  //     width: 63px;
  //     height: 63px;
  //     border: 1px solid #dedede;
  //     border-radius: 10px;
  //     .btn {
  //       position: absolute;
  //       top: 0;
  //       right: 0;
  //     }
  //     img {
  //       width: 63px;
  //       height: 53px;
  //     }
  //   }
  //   .inst {
  //     font-weight: 500;
  //     font-size: 10px;
  //     line-height: 140%;
  //     color: #868484;
  //   }
  // }

  .uploadedImgCont{
    position: relative;
    display: flex;
    width: 200px;
    height: 163px;
    justify-content: center;
    align-items: flex-start;
    margin-left: 50px;
    margin-top: 16px;
    padding-left: 26px;
    border: 1px solid lightgray;
    img{
          margin-right: 9px;


   width: 133px;
    height: 120px;
    margin-top: 20px;
    span{
      width: 26px ;
      height: 26px;
      position: absolute;
      background-color: #f9f9f9;
      top: 2px;
      right: 0;
    }

}
  }

  .add-img-btn{
    width: 219px;
    height: 163px;
    background-color:  #f9f9f9;
    border:  1px solid  #dfe4ea;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
.submit-btn{
background-color: #34BA96;
height: 56px;
width: 230px;
border-radius: 100px;
padding: 12px 24px 12px 24px;
color: #ffffff;
margin-bottom: 5rem;
padding-bottom: 20px;
  }
  .footer{
    margin-right: auto;
    margin-left: auto;
  }
  
}
