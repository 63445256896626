.notice-comp{
    .dropdown-menu{ 
        width: 309px; 
        height: 340px; 
        left: -90px !important;
        transition: all .2s ease-out; 
        
        li{ 
            &.dropdown-item{
                height: 90px;
                width: 100%;
                display: flex;
                align-items: center; 
                position: relative;  
                border-bottom: 1px solid #b8b8b8;
                svg{
                    margin-top: 6px;
                }
                .text{
                    width: 100%;
                    word-wrap: break-word;
                    white-space: pre-wrap;
                    word-break: break-word; 
                    font-style: normal;
                    font-weight: 500;
                    font-size: .81rem;
                    line-height: 150%;   
                    color: #222222; 
                    margin-bottom: 5px;

                }

                .date{
                    font-size: .6rem;

                }

            }
        }
    }

    .notice-bottom{
        width: 100%;
        height: 40px !important;
        position: absolute !important;
        bottom: 0 !important; 
        display: flex;
        justify-content: center;
        border: none !important;
    }
}