@import "../../../../../../Assets/Scss/primaryColor";

.EstateSubscriberProjects{
    .page-title{
        h2{ 
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left; 
            color: #303030;

        }
    }

    .description{  
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left; 
        color: #6D6D6D;
    }




    .seach-field{
        position: relative;

        @media (max-width:800px) {
            margin-top: 20px;
        }

        .search-input{
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 100px;
            height: 40px;
            padding-left:40px; 

            &::placeholder{  
                font-weight: 500;
                font-size: .73rem;
                line-height: 150%; 
                color: #868484;
            }
        } 
    
    }


    .search-icon{
        position: absolute;
        top:12px;
        left:10px;
    }

    .btn-filter{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        font-style: normal;
        font-weight: 500;
        font-size: .76rem;
        line-height: 150%; 
        color: #868484;
        height: 40px;
        width: 100%;
        
        @media (max-width:800px) {
            margin-top: 20px;
        }


        .btn-icon{
            margin-right: 10px;
            width: 16px;
        }

    }


    .pagination-container .pagination-item.selected {
        background-color: $primary-estate;
        color: white;
        font-weight: bold;
      }
}