@import "../../../../../../../Assets/Scss/primaryColor";

.enterpriseWalletWithdrawal-modal{
  margin-top: 200px;  
  background: #FFFFFF;
  border: 1px solid #DFE4EA;
  border-radius: 28px; 

  .modal-title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 32px; 
    display: flex;
    align-items: center; 
    color: #333333;
  }

  .modal-content{
      background: #FFFFFF;
      border: 1px solid #DFE4EA;
      border-radius: 28px; 
  }
  .form-control{ 
      background: #FFFFFF;
      border: 1px solid #DFE4EA;
      border-radius: 100px;
      height: 50px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px; 
      font-feature-settings: 'liga' off; 
      color: #828282;

    

      &:focus{
          outline: none !important;
          box-shadow: none !important;
      }
  }

  select{
      font-size: .8rem !important;
      option{ 
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: .8rem !important;
          line-height: 18px; 
          font-feature-settings: 'liga' off; 
          color: #828282;
      }

  }

  .apply{  
      width: 100%;
      height: 56px; 
      background: #3378F3; 
      color: #DFE4EA;
      font-weight: 800;
      box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
      border-radius: 100px;
  }

  .apply-outline{ 
      width: 100%;
      height: 56px; 
      font-weight: 800;
      color: #3378F3;
      background: #3379f300; 
      box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
      border-radius: 100px;

  }
 
 .btn-close {
      height: 15px;
      width: 15px; 
      border: 1px solid grey;
      border-radius: 50px; 
      font-size: .7rem !important;
  } 


    .add-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      border: 1px solid #F5A623;
      box-sizing: border-box;
      border-radius: 100px; 
      font-weight: 600;
      font-size: 0.9rem;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 0.03em;
      color: $primary;
      background-color: #F5A623;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 20px 0;
      &:hover {
        color: rgb(243, 242, 242);
      }
  }

  label{
    font-style: normal;
    font-weight: 400;
    font-size: .8rem;
    line-height: 19px; 
    color: #333333;

  }
}

 

.fws{
   
  label{
      font-weight: 400;
      font-size: .87rem;
      line-height: 19px; 
      color: #333333;
      margin-bottom: 12px;
  }

  .form-control{
      background: #FFFFFF;
      border: 1px solid #DFE4EA;
      border-radius: 100px;
  }

.btn{
  width: 100%;
  height: 40px;
  background: #F5A623;
  border: 1px solid #DFE4EA;
  border-radius: 100px;
  border: 1px solid #F5A623;
  border-radius: 100px; 
  font-weight: 700;
  font-size: .9rem;
  line-height: 18px;   
  color: #fdfdfd;

}

.details{
  font-weight: 400;
  font-size: .8rem;
  line-height: 19px;
  text-align: center; 
  color: #333333;

} 
}