.faq-item-list {
    list-style: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    cursor: pointer;
    margin-bottom: 24px;

    >img {
        margin-right: 12px;
    }

    .faq-item-answer-modal {
        display: flex;
        align-items: center;
    }
    .custom-modal-close-btn {
        &:hover {
            cursor: pointer;
            opacity: 0.6;
        }
    }
}