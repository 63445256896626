.card-slider {
  .prevCard {
    left: 0;
  }

  .activeCard {
    left: 50%;
    transform: translateX(-50%);
  }

  .nextCard {
    right: 0;
  }

  .descprod {
  }
  .descprod--1 {
    font-size: 0.9rem;
    font-weight: 300;
  }
}
