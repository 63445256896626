.enterpriseInvoice{ 
    margin-top: 17px;

    .order-management-menu{
        font-style: normal;
        font-weight: 400;
        font-size: .8rem;
        line-height: 150%; 
        color: #636262; 
    } 

    .bulk-order-title{
        h5{
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 20px; 
            color: #303030;
            
        }
        div{
            font-style: normal;
            font-weight: 500;
            font-size: .8rem;
            line-height: 150%;  
            color: #606060;
            
        }
    }

    .processing-status{ 
        background: rgba(237, 184, 46, 0.15);
        border-radius: 100px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;  
        color: #EDB82E;
        padding: 7px 20px;
    }


    .seach-field{
        position: relative;

        @media (max-width:800px) {
            margin-top: 20px;
        }

        .search-input{
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 100px;
            height: 40px;
            padding-left:40px; 

            &::placeholder{  
                font-weight: 500;
                font-size: .73rem;
                line-height: 150%; 
                color: #868484;
            }
        } 
    
    }


    .search-icon{
        position: absolute;
        top:12px;
        left:10px;
    }

    .btn-filter{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        font-style: normal;
        font-weight: 500;
        font-size: .76rem;
        line-height: 150%; 
        color: #868484;
        height: 40px;
        width: 100%;
        
        @media (max-width:800px) {
            margin-top: 20px;
        }


        .btn-icon{
            margin-right: 10px;
            width: 16px;
        }

    }

     



    .enterprise-table{
        padding-bottom:100px;
        .table{
            border: 1px solid #DFE4EA;
            border-radius: 20px !important;
            margin-top: 20px;  

            thead{
                th{
                    font-style: normal;
                    font-weight: 500;
                    font-size: .77rem;
                    line-height: 18px; 
                    color: rgba(76, 76, 76, 0.81);
                }
            }

            tbody{
                td{
                    font-style: normal;
                    font-weight: 500;
                    font-size: .88rem;
                    line-height: 150%;  
                    color: #606060;

                    .created-status{ 
                        height: 28px; 
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: rgba(52, 186, 150, 0.15);
                        border-radius: 100px;
                        font-weight: 500;
                    }

                    .status-pending{
                        font-weight: 400;
                        font-size: .7rem;
                        line-height: 18px; 
                        display: flex;
                        align-items: center;
                        text-align: center; 
                        color: #F5A623; 
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                        border: 1px solid #F5A623;
                        border-radius: 20px;
                        height: 28px;
                        width: 70px;
                        margin: 0 4px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .dropdown-item{
                        font-style: normal;
                        font-weight: 400;
                        font-size: .72rem;
                        line-height: 18px; 
                        display: flex;
                        align-items: center; 
                        color: #333333; 
                        flex: none;
                        order: 1;
                        flex-grow: 0;
                    }
                }

                @media (max-width:800px) {  
                    .profile-title{
                        
                    }

                    .profile-detail{
                        font-size: .74rem !important;
                        line-height: 13px;
                    }

                    .profile-btn{
                        height: 70px; 
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .btn{ 
                            border: 1px solid #DFE4EA;
                            border-radius: 100px;
                            font-style: normal;
                            font-weight: 500;
                            width: 60px;

                        }
                    }
                }
                @media (min-width:800px) {  

                    .profile-detail{
                        // font-size: .5rem !important;
                        // line-height: 14px;
                    }
                }
            }

            .table-profile{
                @media (max-width:800px) { 
                    width:55px;
                    height:55px;
                    border-radius: 5px; 
                }
                @media (min-width:800px) { 
                    width:28px;
                    height:28px;
                    border-radius: 50px; 
                }
            }

            .dropdown-toggle{
                &::after{
                    display: none;
                }

                .d-icon{
                    color: #606060;
                    font-size: .9rem; 
                }
            }
        }
    }

    .dropdown-menu{
        li{
            font-style: normal;
            font-weight: 400;
            font-size: .9rem;
            line-height: 18px; 
            display: flex;
            align-items: center; 
            color: #333333;
        }
    }



    .bulk-action{
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center; 

        .order-details{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px; 
            color: #F5A623; 
            height: 32px;  
            border-radius: 100px;
            border:1px solid #F5A623;
            margin: 10px 10px 10px 0;
        }

        .pay-now{
            width: 104px; 
            height: 32px;  
            background: #F5A623; 
            border-radius: 100px;
            color: #DFE4EA;
            font-weight: 400;
            font-size: .8rem;
            line-height: 18px;  
            color: #FFFFFF;
            margin: 10px;
            
        }
    }

    .status{ 
        background-color: #8eff94;
        justify-content: center;
        align-items: center;
        text-align: center; 
        font-size: .8rem;
        padding: 5px;
        border-radius: 7px;
    }
}
