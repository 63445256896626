@import "../../../../../../../Assets/Scss/primaryColor";

.wallet {
  .wallet-card {
    // height: 255px;
    // width: 436px;
    .card {
      height: 255px;
      width: 436px;
      border-radius: 8px;
      background-color: #34ba96;
      .btn-div {
        height: 98px;
        p{
          color:  #ffffff;
        }
      }
       .walletName {
          font-weight: 600;
          font-size: 20px;
          color: #303030;
        }
        .walletBalance {
          font-weight: 800;
          font-size: 30px;
          color: #ffffff;
        }
      .outer {
        height: 156px;
      }
      .inner {
        height: 98px;
      }
    }
  }
  .wallet-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    border: 1px solid #34ba96;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: #34ba96;
    flex: none;
    order: 1;
    flex-grow: 0;
    &:hover {
      color: rgb(243, 242, 242);
      background-color: #34ba96;
    }
  }

  .wallet-inner {
    background: $seller-light;
    border-radius: 10px;
    height: 200px;
    .wallet-icon {
      padding: 1px;
      width: 64px;
      height: 64px;
      text-align: center;
      background-color: #34ba96;
      display: flex;
      justify-content: center;
      align-items: center;
      .d-icon {
        font-size: 2rem;
        padding: 20px;
      }
    }
    @media (max-width: 1007px) {
      .walletName {
        font-weight: 500;
        font-size: 16px;
        color: #303030;
      }
      .walletBalance {
        font-weight: 800;
        font-size: 1.24rem;
        color: #303030;
      }
    }

    @media (min-width: 1007px) {
      .wallet-card {
        height: 255px;
        width: 434px;
        .card {
          height: 255px;
          width: 433px;
          border-radius: 8px;
          background-color: #34ba96;
          .btn-div {
            height: 98px;
            width: 50%;
          }
          .outer {
            height: 156px;
            width: 434px;
          }
          .inner {
            height: 98px;
            width: 433px;
          }
        }
        .walletName {
          font-weight: 600;
          font-size: 20px;
          color: #303030;
        }
        .walletBalance {
          font-weight: 800;
          font-size: 30px;
          color: #ffffff;
        }
      }
    }
  }

  .btn-close {
    font-size: 0.8rem;
  }
  .modal--title {
    font-weight: 600;
    font-size: 1.1rem !important;
    line-height: 24px;

    color: #151c2f;
  }

  .wallet-modal {
    .form-control {
      height: 45px;
      background: #ecf1f4;
      border: none;
      box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1) !important;
      border-radius: 8px;
      padding-left: 50px;
    }
    .form-input-wrap {
      position: relative;
      .form-input-symbol {
        position: absolute;
        height: 45px;
        width: 45px;
        left: 50;
        border: 1px solid #dedede;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #868484;
      }
      .form-input-logo {
        position: absolute;
        top: 0;
        right: 0;
        padding-right: 20px;
        padding-top: 7px;
        img {
          width: 37px;
          height: 24px;
          margin: 5px;
          background-color: $white;
          padding: 5px;
        }
      }
    }
  }

  .bank-transfer-details {
    td {
      font-size: 0.9rem;
    }
  }
}

//React bootstrap modal
.reactModalB {
  .btn-close {
    font-size: 0.8rem;
  }
  .modal--title {
    font-weight: 600;
    font-size: 1.1rem !important;
    line-height: 24px;

    color: #151c2f;
  }
}
.wallet-modal {
  .form-control {
    height: 45px;
    background: #ecf1f4;
    border: none;
    box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1) !important;
    border-radius: 8px;
    padding-left: 50px;
  }
  .form-input-wrap {
    position: relative;
    .form-input-symbol {
      position: absolute;
      height: 45px;
      width: 45px;
      left: 50;
      border: 1px solid #dedede;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #868484;
    }
    .form-input-logo {
      position: absolute;
      top: 0;
      right: 0;
      padding-right: 20px;
      padding-top: 7px;
      img {
        width: 37px;
        height: 24px;
        margin: 5px;
        background-color: $white;
        padding: 5px;
      }
    }
  }
}
.seller-btn-outline--primary {
  background: #ffffff;
  border: 1px solid #34ba96;
  box-sizing: border-box;
  border-radius: 8px;
  color: #34ba96;

  &:hover {
    background: #34ba96 !important;
  }
}
.seller-btn-primary {
  background-color: white;
}
