.buyer-quote-request{
    .sub-nav{ 
        width: fit-content;
        height: 46px; 
        background: #F3F3F3;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; 
        padding-right:24px;
        padding-left: 24px;

        @media (max-width:600px) { 
            padding-right:0;
            padding-left: 0;  
        }

        .nav-link{
            font-weight: 400;
            font-size: .9rem;
            line-height: 18px; 
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center; 
            color: #333333; 
            flex: none;
            order: 0;
            //flex-grow: 0;
        }

        .active{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center; 
            gap: 10px;  
            height: 35px; 
            font-size: .9rem;
            background: #FFFFFF;
            border-radius: 10px;
        }
    }
}