.walletInformation{
    .title{ 
        font-weight: 400;
        font-size: .9rem;
        line-height: 150%; 
        color: #303030;
    }
 
    .my-node-enter {
        opacity: 0;
      }
      .my-node-enter-active { 
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
      }
      .my-node-exit {
        opacity: 1;
      }
      .my-node-exit-active {
        opacity: 0;
        transform: scale(0.1);
        transition: opacity 300ms, transform 300ms;
      }
}

@keyframes inAnimation {
    0% {
      transform: scale(0.7);
      opacity: 0;
    }
    60% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes outAnimation {
    20% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(0.7);
      opacity: 0;
    }
  }