 .ImageCollage{
    .full-height{ 
        height: 410px;  

        @media (max-width: 388px) { 
            height:250px;
         }

         @media (max-width: 788px) { 
             height:350px;
          }

        .card{ 
            flex: 20%;
            margin: 2px;
            height: 410px;  
            overflow: hidden;
            border-radius: 10px;

            @media (max-width: 388px) { 
                height:220px;
             }

             @media (max-width: 788px) { 
                height:310px;
             }
      
            img {
                width: 100%;
                height: 100%;
                object-fit: cover; 
                object-position: center;
                transition: transform 0.3s ease;  
                border-radius: 10px;

                &:hover   {
                    transform: scale(1.1);  
                }
            }
        }
    }


    .half-height {  
        display: flex;
        flex-wrap: wrap; 
        height: 390px;  
        flex-direction: row;

        @media (max-width: 709px) { 
            height:250px;
         }

        .card{ 
            flex: 20%;
            margin: 2px;
            height: 320px; 
            overflow: hidden;
            border-radius: 10px;

            @media (max-width: 709px) { 
                height:220px;
             }
      
            img {
                width: 100%;
                height: 100%;
                object-fit: cover; 
                object-position: center;
                transition: transform 0.3s ease;  
                border-radius: 10px;

                &:hover   {
                    transform: scale(1.1);  
                }
            }
        }
    }

    .third-height{ 
        .main-image{
            height: 400px; 
            overflow: hidden;
            border-radius: 10px;

            @media (min-width:768px) and (max-width: 1409px) { 
                height: 300px;
              }
              @media (max-width: 709px) { 
                 height:290px;
              }

            img{ 
                width: 100%;
                height: 100%;
                object-fit: cover; /* Ensure the image covers the entire container */
                object-position: center;
                transition: transform 0.3s ease; /* Add smooth transition for zoom effect */

                &:hover   {
                    transform: scale(1.1); /* Zoom effect on hover */
                  }
            }

        }

        .small-image{
            height: 200px; 
            overflow: hidden;
            border-radius: 10px;

            @media (min-width:768px) and (max-width: 1409px) { 
                height: 150px;
              }

            @media (max-width: 709px) { 
                margin-top: 10px;
                height: 160px;
            }

            img{ 
                width: 100%;
                height: 100%;
                object-fit: cover; /* Ensure the image covers the entire container */
                transition: transform 0.3s ease; /* Add smooth transition for zoom effect */

                &:hover   {
                    transform: scale(1.1); /* Zoom effect on hover */
                  }
            }
        }
    }



    .fourth-height{ 
        .main-image{
            height: 400px; 
            overflow: hidden;
            border-radius: 10px;

            @media (min-width:768px) and (max-width: 1409px) { 
                height: 300px;
              }
              @media (max-width: 709px) { 
                 height:290px;
              }

            img{ 
                width: 100%;
                height: 100%;
                object-fit: cover; /* Ensure the image covers the entire container */
                object-position: center;
                transition: transform 0.3s ease; /* Add smooth transition for zoom effect */

                &:hover   {
                    transform: scale(1.1); /* Zoom effect on hover */
                  }
            }

        }

        .small-image{
            height: 200px; 
            overflow: hidden;
            border-radius: 10px;

            @media (min-width:768px) and (max-width: 1409px) { 
                height: 150px;
              }

            @media (max-width: 709px) { 
                margin-top: 10px;
                height: 160px;
            }

            img{ 
                width: 100%;
                height: 100%;
                object-fit: cover; /* Ensure the image covers the entire container */
                transition: transform 0.3s ease; /* Add smooth transition for zoom effect */

                &:hover   {
                    transform: scale(1.1); /* Zoom effect on hover */
                  }
            }
        }

        .tiny-image{
            height: 100px; 
            overflow: hidden;
            border-radius: 10px;

            @media (min-width:768px) and (max-width: 1409px) { 
                height: 150px;
              }

            @media (max-width: 709px) { 
                margin-top: 10px;
                height: 160px;
            }
            
            img{ 
                width: 100%;
                height: 100%;
                object-fit: cover; /* Ensure the image covers the entire container */
                transition: transform 0.3s ease; /* Add smooth transition for zoom effect */

                &:hover   {
                    transform: scale(1.1); /* Zoom effect on hover */
                  }
            }
        }
    }
}