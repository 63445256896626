.addNewDeliveryAddress{
   .title{  
        font-weight: 700;
        font-size: .9rem;
        line-height: 24px;  
        color: #333333;
   }
   .title-req{ 
    font-size: .7rem;
   }
   .title-desc{
    font-size: .75rem;
    font-weight: 400; 
    line-height: 19px; 
    color: #333333;
   }

    .form-input{   
        margin-top: 20px;

        label{
            font-weight: 400;
            font-size: .8rem;
            line-height: 19px; 
            color: #333333;
            margin-bottom: 10px;
        }

        .form-control{ 
            width: 100%;
            height: 46px; 
            background: #FFFFFF;
            border: 1px solid #DFE4EA !important;
            border-radius: 100px;
            &:focus{ 
              border: 1px solid #DFE4EA !important;
            }
        }

        textarea{
            width: 100%;
            height: 211px;  
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 20px;
            &:focus{ 
                border: 1px solid #DFE4EA !important; 
              }
            &:active{ 
                border: 1px solid #DFE4EA !important; 
            }
        }

        .form-control{
            &:focus{ 
                border: 1px solid #DFE4EA !important; 
                box-shadow: none !important;
              }
            &:active{ 
                border: 1px solid #DFE4EA !important; 
            }
        }

        select{ 
            option{
                font-size: .9rem !important;
            }
        }
    }


    .btn{ 
        box-sizing: border-box;  
        width: 100%;
        height: 43px;  
        background: #4379EB;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        color: #FFFFFF;
        font-size: .9rem;
    }

    .consent-text{
        font-weight: 400;
        font-size: .8rem;
        line-height: 24px; 
        color: #333333;
    }

    .countryCode{
        height:44px;
        width:50px; 
        background: #EFF2F6;
        border: 1px solid #DFE4EA;
        border-radius: 100px 0px 0px 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: .8rem;

        font-weight: 400; 
        line-height: 18px;  
        font-feature-settings: 'liga' off; 
        color: #333333;
        position: absolute;
        bottom:2px;

    }

    .phoneContact{
        padding-left: 60px;
    }


   
}