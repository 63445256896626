.cac-document {
  .text {
    font-size: 16px;
    font-weight: 500;

  }
  .form-group {
    height: 199px;
    border-radius: 8px;
    border: 1px solid #DFE4EA;
    background-color:#DFE4EA;
    display: flex;
    justify-content: center;
    align-items: center;
    .input-div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
    }
  }
  .save{
    height: 56px;
    border: 1px solid #DFE4EA;
    border-radius: 100px;
  }
}
