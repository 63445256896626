.pending-response{
    .pending-response-inner{
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 10px;
        padding: 0 20px;

        thead{ 
            height: 58px; 

        
            th{
                width: 101px;
                font-style: normal;
                font-weight: 600;
                font-size: .9rem;
                line-height: 18px; 
                color: #333333;  
                height: 58px;   
                text-align: left; 
                vertical-align: middle;
            }
        }

        tbody{
            td{
                height: 58px;   
                text-align: left; 
                vertical-align: middle;

                font-weight: 400;
                font-size: .8rem;
                line-height: 18px; 
                color: #333333;

                .btn{
                    width: 104px;
                    height: 30px; 
                    border: 1px solid #4379EB;
                    border-radius: 100px; 
                    font-weight: 400;
                    font-size: .8rem;
                    line-height: 18px;  
                    color: #4379EB;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                } 

            }
            .processingText{
                font-size: .8rem !important;
            }
                
        }
        tr{

            border-bottom: 1px solid #DFE4EA;
        }

        .product-image-container {
            background: #FEFEFE;
            box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.07);
            border-radius: 10px;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .product-image {
            height: 16px ;
            width: 8px;
        }
    } 
}