.product-table{
  .table{
       .title{
   color: #303030;
margin-right:16px;
font-size: 20px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

  }
    margin-top: 9px;
    border: 1px solid lightgrey;
    background-color: white;
    width: 100%;
    
        th{
            font-weight: 300;
        }
    
}}
