.seller-update-bank{ 
        > div{
            border-radius: 28px !important;
        }
        .custom-modal-title {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #333333;
        }
        .custom-modal-close-btn {
            &:hover {
                cursor: pointer;
                opacity: 0.6;
            }
        }
     
        .modal-title {
          font-style: normal;
          font-weight: 700;
          font-size: 1.3rem;
          line-height: 32px; 
          display: flex;
          align-items: center; 
          color: #333333;
        }
      
        .modal-content{
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 28px; 
        }
        .form-control{ 
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 100px;
            height: 50px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px; 
            font-feature-settings: 'liga' off; 
            color: #828282;
      
          
      
            &:focus{
                outline: none !important;
                box-shadow: none !important;
            }
        }
      
        select{
            font-size: .8rem !important;
            option{ 
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: .8rem !important;
                line-height: 18px; 
                font-feature-settings: 'liga' off; 
                color: #828282;
            }
      
        }
      
        .apply{  
            width: 100%;
            height: 56px; 
            background: #F5A623;
            color: #DFE4EA;
            font-weight: 800;
            box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
            border-radius: 100px;
        }
      
        .apply-outline{ 
            width: 100%;
            height: 56px; 
            font-weight: 800;
            color: #F5A623;
            background: #3379f300; 
            box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
            border-radius: 100px;
      
        }
       
       .btn-close {
            height: 15px;
            width: 15px; 
            border: 1px solid grey;
            border-radius: 50px; 
            font-size: .7rem !important;
        } 
      
      
          .add-btn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 12px 24px;
            border: 1px solid #34ba96;
            box-sizing: border-box;
            border-radius: 100px; 
            font-weight: 600;
            font-size: 0.9rem;
            line-height: 19px;
            display: flex;
            align-items: center;
            letter-spacing: 0.03em;
            color: #0e0d0c;
            background-color: #34ba96;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin: 20px 0;
            &:hover {
              color: rgb(243, 242, 242);
            }
        }
      
        label{
          font-style: normal;
          font-weight: 400;
          font-size: .8rem;
          line-height: 19px; 
          color: #333333; 
        }
    
}