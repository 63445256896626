.CBcastDetail{
    iframe{
        width: 100% !important;
        height: 501px !important;
    }
    .back-arrow{
        color: rgb(255, 255, 255);
        font-weight: 900;
        font-size: 1rem;
        height: 30px;
        display: flex;
        align-items: center; 

        i{ 
           font-size: 1rem;
           margin-top: 7px;
           font-weight: 900;
           margin-right: 10px;
        }
    }
    .card{ 
        width: 100%;
        background-color: rgb(252, 252, 252) !important;
        background-size: cover; 

        @media (min-width:800px) {
             background-attachment: fixed; 
        }
       
        .card-body{ 
            .featured-type{ 
                color:rgb(255, 145, 0);
                font-size: 1rem;
                font-weight: 700;
            }
            h2{ 
                color:rgb(4, 4, 4);
                cursor: pointer;
                
                @media (max-width:800px) {
                    font-weight: 700;
                    font-size: 2rem;
                }

                @media (min-width:800px) {
                    width: 70%;
                    font-weight: 700;
                    font-size: 2.5rem;
                }
            }

            .post-by{  
                color:rgb(255, 145, 0);
                font-size: .8rem;
                font-weight: 700;
            }

            
        }
    }
   
}