.bank-details {
  .title {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 150%;
    color: #303030;
  }

  .card {
    background: #eff2f6;
    border: 1px solid #dfe4ea;
    border-radius: 8px;
    margin-top: 10px;
    height: 255px;
    width: 675.48;
    .card-body {
      height: 125px;
      .add-bank {
        width: 90px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #dfe4ea;
        border-radius: 100px;
        font-size: 0.8rem;
      }

      .nobank {
        font-weight: 500;
        font-size: 0.8rem;
        line-height: 150%;
        color: #303030;
        margin-bottom: 10px;
      }
    }
    .card-footer {
      background: #eff2f6;

      .btn {
        width: 100%;
        height: 44px;
        background: #ffffff;
        border: 1px solid #dfe4ea;
        border-radius: 100px;

        font-weight: 600;
        font-size: 0.9rem;
        line-height: 18px;

        color: #3378f3;
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      .account-name {
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 19px;
        color: #000000;
        margin-bottom: 10px;
      }
      .bank {
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 25px;
        letter-spacing: 0.335em;
        color: #000000;
      }
    }
  }
}
