.EstateHeroSection{
    .heroSection-inner{
        height: 75vh;
        background-size: cover; 
 
        .hero-content{
            height: 60vh;
            display: flex; 
            align-items: center;
            .title{  
                font-weight: 700;
                line-height: 70px;
                letter-spacing: 0px;
                text-align: left;
                color: #FFFFFF;

                @media (max-width: 400px) { 
                    font-size:2.8rem;
                    line-height: 60px;
                 }

                 @media (min-width: 400px) { 
                    font-size:4rem;
                    line-height: 70px;
                 }

            }

            .btn{
                padding: 10px;
                border-radius: 100px;
                border: 1px;
                gap: 4px;
                border: 1px solid #FFFFFF;
                color: #FFFFFF; 

                @media (max-width: 400px) {  
                    width: 150px;
                    height: 46px;
                 }

                 @media (min-width: 400px) {  
                    width: 180px;
                    height: 46px;
                 }
            }
        }
    }

        .search-container{
            width: 100%;
            display: flex;
            justify-content: center;
   
            .search-container-inner {
                min-height: 130px;
                width: 90%;
                margin-top: -50px;
                z-index: 2px;
                background-color: #FFFFFF;
                display: flex;
                align-items: center; 
                padding-top: 25px;
                padding-bottom: 25px;
            
                .form-control{
                    width: 100%;
                    height: 46px;
                    padding: 10px, 10px, 10px, 20px;
                    border-radius: 40px;
                    border: 1px solid #AFAFB0;
                    color: #AFAFB0;

                }

                label{ 
                    font-family: Lato;
                    font-size: .8rem;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #696666;


                }

                .btn-search{
                    width: 100%;
                    height:  46px;
                    padding: 10px;
                    border-radius: 100px;  
                    background: #3A0CA3;  
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 26px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: #FFFFFF;

                } 
                .more-text{ 
                    font-size: .7rem;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #3A0CA3; 
                    height: 10px;
                    display: flex;
                    align-items: center; 
                    margin-top: 10px;
                    cursor: pointer;

                    span{
                        margin-top: 4px;
                    }

                }
            }
        }  
        .search-result { 
            width: 100%;
            display: flex;
            justify-content: center;


            .search-result-inner { 
                width: 90%;
                margin-top:-20px;
                z-index: 2px;
                background-color: #FFFFFF;
                display: flex;
                align-items: center; 
                padding-top: 25px;
                padding-bottom: 25px;
 

                .search-title{
                    font-size: .9rem;
                    margin-bottom: 20px;
                }

                .search-link{
                    width: 100%;
                    text-align: right;  

                    a{
                        font-size: .9rem; 
                        height: 15px;
                        display: inline-flex;
                        align-items: center;  
                        color: #1c1c1c;
                        text-decoration: underline;
                        line-height: 500px;
                        text-underline-offset: 10px; 
 
                    }
                }

                
            } 
            .request-error{
                font-size: .9rem;
                color: #696666;
            }
        } 
    }    