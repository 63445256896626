.custom-dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
}

.BuyerbasicDetailInput{
    .form-section-header{

    }

    .form-section-description{
        font-weight: 400;
        font-size: .84rem !important; 
        color: #606060;
        margin-top: 20px;

    } 
}