.brandSkeleton{ 
    .card{ 
        margin: 5px;
        .card-body{
            text-align: center;
            width: 100%;
            height: 150px; 
            background: #FFFFFF;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center; 
            overflow: hidden;
            padding: 0 10px;
      }
    }

    

}