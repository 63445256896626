 
.checkout{
    &.order-completed{
        position: relative;
        .section-header{
            h2{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 38px;
                display: flex;
                align-items: center; 
                color: #333333;
            }
        }
    
        h1{
            font-weight: 700;
            font-size: 2rem;
            line-height: 48px;  
            color: #333333;
    
        }
    
        .location-selected{
            width:15px;
        }
    
        .location-address{
            font-size: .7rem;
        }
    
        .steps{ 
            display: inline-block;
            .badge{ 
                width: 25px;
                height: 25px;
                font-style: normal;
                font-weight: 700;
                font-size: .8rem; 
                text-align: center; 
                display: inline-flex;
                justify-content: center;
                color: #FFFFFF;
                background: #333333;
                border-radius: 50px;
                margin-right: 7px;
    
                &.inactive{
                    font-weight: 700; 
                    background: #BDBDBD!important;  
                    color: #FFFFFF !important;
                }
            }
    
            span{ 
                font-style: normal;
                font-weight: 700;
                font-size: .9rem; 
                color: #333333;
            }
        }
       
    
        .ordertype{
            .btn{ 
                @media (max-width:1200px) {  
                    width: 100%;
                    height: 51px;  
                    margin-top:10px
                }
    
                @media (min-width:1200px) {
                    width: 339px;
                    height: 51px; 
                }
    
                border-radius: 8px;
     
                font-style: normal;
                font-weight: 400;
                font-size: .8rem;
                line-height: 19px; 
                color: #333333;
                border: 1px solid #232323;
    
                &.active{ 
                  border-width: 2px;
                  border: 2px solid #2a69f0;
                }
    
                img{
                  width:15px;
                  margin-right: 7px;
                }
            }
        }
    
    
       .cart-item-list{
            margin-top: 30px;
            position: relative;
    
            .select-pickup{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: .9rem;
                line-height: 19px;
                display: flex;
                align-items: center; 
                color: #333333;
                
            }
    
         .cart-item{
            border-bottom: 1px solid rgb(231, 231, 231);
            padding-bottom: 15px; 
            position: relative;
            border-radius: 8px;
    
            hr{
                border: 1px solid rgb(231, 231, 231);
                margin-top: 20px;
            }
          
         .cart-item-list-image{
            width: 100%;
            height: 137px; 
            background: #D9D9D9;
            border-radius: 8px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
    
            img{
                width: 100%;
                height: 100%; 
            }
        }
       
        .cart-item-name{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 1rem;
            line-height: 19px;
            display: flex;
            align-items: center; 
            color: #333333;
    
        }
    
        .cart-item-variation{ 
            font-weight: 400;
            font-size: .9rem;
            line-height: 17px; 
            display: flex;
            align-items: center; 
            color: #353434; 
            margin-top: 5px;
        }
    
        .cart-item-price{ 
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            height: 137px;
            display: flex-end;
            align-items: center;  
            color: #F2994A; 
    
        }
    
      
       .product-attibutes{ 
            position: absolute;
            bottom:15px;
            width: 100%;
            .product-qty{
                width: 80px;
                height: 35px; 
                background: #FFFFFF;
                border: 1px solid #DFE4EA;
                border-radius: 100px; 
                padding: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                .form-control{ 
                    height: 30px; 
                    border: 1px solid #DFE4EA;
                    border-radius: 100px; 
                    text-align: center;
                    &.active{
                        border: 1px solid #d9d9d900;
                        outline: none !important;
                        box-shadow: none !important;
                    }
    
                    &.focus{
                        border: 1px solid #d9d9d900;
                        outline: none !important;
                        box-shadow: none !important;
                    }
            }
            } 
        }
    
        .remove-product{
            text-align: center; 
            height: inherit; 
            padding-top: 6px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px; 
            display: flex;
            align-items: center; 
            color: #333333;
        }
    
        .save-product{
            text-align: center;
            height: inherit; 
            padding-top: 6px; 
            font-weight: 400;
            font-size: 14px;
            line-height: 17px; 
            display: flex;
            align-items: center; 
            color: #333333;
        }
    
       } 
    }
    
    .print-btn{ 
        font-size: .9rem;
    }
    
    .btn-continue{ 
        width: 100% !important;
        height: 46px; 
        background: #F5A623;
        border: 1px solid #DFE4EA;
        border-radius: 100px; 
        border-radius: 100px;
        margin-top: 30px;
        font-style: normal;
        font-weight: 400;
        font-size: .8rem;
        line-height: 18px;   
        color: #FFFFFF; 
       }
    
       .btn-download{ 
            background: #FFFFFF; 
            border: 1px solid #000000;
            border-radius: 100px;
            width: 100%;
            font-size: .9rem;
       }
    .order-completed-message{
        font-size: .9rem;
        font-weight: 400; 
        line-height: 24px;
      }
     .ads-big{
            width: 100%;
            height: auto !important; 
            background: #EFF2F6;  
        }
    
        .ads-small{
            width: 100%;
            height: 256px; 
            background: #EFF2F6; 
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            
        }
    
    
        .cart-item-list{
            border: 1px solid rgb(239, 238, 238) !important;
            .card{
                border: 1px solid red !important;
            }
        }
    
    
    
    
    
    .similar-product-title{ 
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        display: flex;
        align-items: center;  
        color: #333333;
    }
    
        .col-6{
            flex: 20%;
            margin: 10px; 
    
            .product-card{ 
                background: #FFFFFF; 
                border: 1px solid #E0E0E0 !important;
                border-radius: 8px; 
                position: relative;
    
                &:hover{
                    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
                }
              
                .brand-logo{
                    width:40px;
                }
    
                .wish-icon{
                    width:20px
                }
    
                .product-image{
                    height:160px;
                }
    
                .col-12{
                    position: relative;
    
                    .promo{
                        position: absolute;
                        top:0 ;
                        width: 60px;
                        height: 25px;
                        left: 0; 
                        background: #FF0000;
                        border-radius: 0px 24px 24px 0px;
                        margin-top: 10px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: .8rem;
                        line-height: 120%; 
                        color: #FFFFFF;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                  } 
    
                  .product-title{ 
                    font-style: normal;
                    font-weight: 500;
                    font-size: .9rem;
                    line-height: 150%; 
                    color: #333333;
                    width: max-content;
                    word-wrap: break-word; 
                    text-overflow: ellipsis;
                  }
    
                  .price{
                    display: flex;
                    flex-direction: row;
    
                    .old-price{
                        flex: 60%; 
                        font-weight: 400;
                        font-size: .8rem;
                        line-height: 17px; 
                        text-decoration-line: line-through; 
                        color: #717171;   
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                    }
                    .new-price{
                        flex: 60%;
                        font-weight: 700;
                        font-size: .9rem;
                        line-height: 19px; 
                        color: #F2994A; 
                        flex: none;
                        order: 1;
                        flex-grow: 0;
                    }
                  }
    
                  .company-name{ 
                    font-style: normal;
                    font-weight: 700;
                    font-size:.8rem;
                    line-height: 17px; 
                    color: #828282;
                }
    
                .rating{
                    span{
                        font-size: .7rem !important; 
                        color: #F2994A !important;
                    }
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: .8rem;
                    line-height: 17px; 
                    color: #828282;
                }
    
                .location{
                    span{
                        font-size: .7rem !important;
                    }
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: .8rem;
                    line-height: 17px; 
                    color: #828282;
                }
            }
        }
        
       .ppduct-cart-item{
        @media (max-width: 700px) {
            
            .productName{
                font-size: .8rem;
                font-weight: 600 ;
            }
            .quantity{
                font-size: .8rem; 
            }
            .warehouse{
                font-size: .8rem; 
            }  
            .price{
                font-size: .6rem;
            } 
        }
    
        @media (min-width: 700px) { 
            .productName{
                font-size: .9rem; 
                font-style: normal;
                font-weight: 700; 
                line-height: 19px; 
                color: #333333;
            }
            .quantity{
                font-size: .87rem;
                 font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 25px; 
                color: #333333;
            }
            .warehouse{
                font-size: .87rem; 
            }  
            .price{
                font-size: .87rem; 
                font-weight: 700;
                font-size: 14px;
                line-height: 17px; 
                color: #F2994A;
            } 
        }
       } 
    
       .total-calculations{ 
            background: #F2F2F2;  
            border: 1px solid #E0E0E0;
            border-radius: 0px 0px 8px 8px;
       }

   }
}