@import "../../../../../../Assets/Scss/primaryColor";
.sellerProducts {
  margin-top: 1rem; 

  .container-fluid{
    border: 1px solid #dfe4ea;
  }
    .h4{
      size: 20px;
      background: #303030;
      margin-right:  3rem;
    }
    .btn-addnew {
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 19px;
    @media (min-width: 1200px) {
      width: 149px;
    }
    @media (max-width: 1200px) {
      width: 100%;
    }
    height: 37px;
    color: #ffffff;
    background: $sellerPrimary;
    box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
      inset 0px -1px 0px rgba(14, 14, 44, 0.4);
    border-radius: 8px;
  }
  .search-container {
    position: relative;
    width: 268px;
    height: 37px;
    background-color: white;
     .fa-search {
      position: absolute;
      top: 15px;
      left: 10px;
      // opacity: 0.5;
      font-size: 0.9rem;
      border: 1px solid lightgray;
    }
    .filter{
      width: 228px;
      height: 37px;
      left: 0px;
    }
    .span-filter{
      position: absolute;
      font-size: 0.9rem;
      top: 8px;
      left: 56px;
      height: 37px;
      
    }
    .search {
      position: absolute;
      top: 0px;
      left: 0px;
      
      border-radius: 10px;
      height: 37px;
      width: 268px;
      border: 1px solid #dfe4ea ;
      font-size: 0.9rem;

      &:focus {
        border-color: rgba(224, 224, 224, 0.651);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
          0 0 8px rgba(206, 206, 206, 0.6);
        outline: 0 none;
      }
    }
  }
  .tanle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }



  .seach-field{
    position: relative;

    @media (max-width:800px) {
        margin-top: 20px;
    }

    .search-input{
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        height: 40px;
        padding-left:40px; 

        &::placeholder{  
            font-weight: 500;
            font-size: .73rem;
            line-height: 150%; 
            color: #868484;
        }
    } 
  
}

.filter-product{
  position: relative;

  select{
      padding-left: 70px;
      padding-right: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 23px; 
      color: #34BA96;
      option {
        background-color: #868484;
      }
  }

  .filter-icon{
      position: absolute;
      left:10px;
      font-size: .8rem;
      color: #34BA96;
      display: flex;
      align-items: center;

      @media (max-width:1095px) { 
         top:9px;
      }
      @media (min-width:1095px) { 
          top:10px;
      }
  }
}
.search-icon{
  position: absolute;
  top:12px;
  left:10px;
}
.add-new-product-wraper{
  .btn{
    width:100%;
    height: 37px; 
    background: #34BA96;
    border: 1px solid #DFE4EA;
    border-radius: 100px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: .9rem;
    line-height: 18px; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; 
    color: #FFFFFF;
    text-align: center;
  }
}

.filter-product{
  .btn-filter{ 
    width: 100%;
    height: 37px; 
    background: #FFFFFF;
    border: 1px solid #DFE4EA;
    border-radius: 100px;
  }
}

.price-text{
  color: #34BA96; 
}
  .seller-table{
    th {
       border-bottom:1px solid rgb(199, 199, 199) !important
     }
     td {
        border-bottom:1px solid rgba(199, 199, 199, 0.346) !important
      }

    .table{
        border: 1px solid #DFE4EA;
        border-radius: 20px !important;
        margin-top: 20px;

        thead{
            th{
                font-style: normal;
                font-weight: 500;
                font-size: .77rem;
                line-height: 30px; 
                color: rgba(76, 76, 76, 0.81);
            }
        }

        tbody{
            td{
                font-style: normal;
                font-weight: 500;
                font-size: .88rem;
                line-height: 150%;  
                color: #606060;  

                .product-image{ 
                  width: 30px;
                  height: 34px;
                  margin-right:20px;
              }

                .created-status{ 
                    height: 28px; 
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: rgba(52, 186, 150, 0.15);
                    border-radius: 100px;
                    font-weight: 500;
                }

              

                .status-pending{
                    font-weight: 400;
                    font-size: .7rem;
                    line-height: 18px; 
                    display: flex;
                    align-items: center;
                    text-align: center; 
                    color: #F5A623; 
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    border: 1px solid #F5A623;
                    border-radius: 20px;
                    height: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .dropdown-item{
                    font-style: normal;
                    font-weight: 400;
                    font-size: .72rem;
                    line-height: 18px; 
                    display: flex;
                    align-items: center; 
                    color: #333333; 
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }

            @media (max-width:800px) {  
                .profile-title{
                    
                }

                .profile-detail{
                    font-size: .74rem !important;
                    line-height: 13px;
                }

                .profile-btn{
                    height: 70px; 
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .btn{ 
                        border: 1px solid #DFE4EA;
                        border-radius: 100px;
                        font-style: normal;
                        font-weight: 500;
                        width: 60px;

                    }
                }
            }
            @media (min-width:800px) {  

                .profile-detail{
                    // font-size: .5rem !important;
                    // line-height: 14px;
                }
            }
        }

        .table-profile{
            @media (max-width:800px) { 
                width:55px;
                height:55px;
                border-radius: 5px; 
            }
            @media (min-width:800px) { 
                width:28px;
                height:28px;
                border-radius: 50px; 
            }
        }

        .dropdown-toggle{
            &::after{
                display: none;
            }

            .d-icon{
                color: #606060;
                font-size: .9rem; 
            }
        }
    }
}


.pagination{
  position: relative;
  .pagination-item{
      background-color: #ffffff!important;
      border: 1px solid #ffffff;

      &.selected{ 
          font-weight: 500;
          font-size: 14px;
          line-height: 150%; 
          color: #F5A623 !important;  
          background-color: #ffffff!important;
          border: 1px solid #ffffff;
      }
  } 

  .pagination-container { 
    margin-top: 5px; 
  }

  
  .pagination-item:last-child{ 
      border: 1px solid grey;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 50px;
      position: absolute;
      right: 0; 
      
      .right{
          content: "\2192"; 
      }
  }

  .pagination-item:first-child{ 
      border: 1px solid grey;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 50px;
      position: absolute;
      left: 0;

      
  }  
}

}
