@import "../../../Assets/Scss/primaryColor";
.estatePageTitle{
    h2{ 
        font-style: normal;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 38px; 
        color: #333333;
    }

    a{ 
      color: $primary-estate !important; 
    }
}