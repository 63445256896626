.variationList{
    .accordion-button{ 
        width: 100%;
        height: 57px;  
        background: rgba(52, 186, 150, 0); 
        border-radius: 100px;
    }
    .accordion-header{
        width: 100%;
        height: 57px;  
        background: rgba(52, 186, 150, 0.15);
        margin-top: 10px;
        border-radius: 100px;
        
    }

    .accordion-button{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px; 
        display: flex;
        align-items: center;
        font-feature-settings: 'liga' off; 
        color: #34BA96;
        text-align: center;
        justify-content: center;
    }

    .accordion-button::after{ 
        color: #34BA96;
        display: none;
    }
}