 

.enterpriseAccountBalance{
    .title{ 
        font-weight: 400;
        font-size: .9rem;
        line-height: 150%; 
        color: #303030;
    }

    .card{ 
        border: 1px solid #DFE4EA;
        border-radius: 8px;
        margin-top: 10px;   
        border-radius: 8px;
        height: 200px;
        background-size:cover;
        
       .card-body{
           position: relative;

            .wallet-type{
                font-weight: 500;
                font-size: 16px;
                line-height: 150%; 
                color: #DFE4EA;
            }

            .balance{
                font-size: 2rem;
                font-weight: 700;
                color: #FFFFFF;
            }

            .btn{
                width: 140px;
                height: 40px; 
                border: 1px solid #F5A623;
                border-radius: 100px;
                color: #F5A623;
                position: absolute;
                bottom: 20px;
                font-size: .8rem;
            }

            .btn-wallet-type{
                position: absolute;
                top:8px;
                right:0;font-style: normal;
                font-weight: 500;
                font-size: .8rem;
                line-height: 120%; 
                color: #000000;
                width:100px;
                border: none !important;
            }

            .add-btn{
                background-color: #F5A623 !important; 
            }


        border-radius: 8px;
         
    } 
  } 
}

// .enterpriseAccountBalance{
//     .title{ 
//         font-weight: 400;
//         font-size: .9rem;
//         line-height: 150%; 
//         color: #303030;
//     }

//     .card{ 
//         border: 1px solid #DFE4EA;
//         border-radius: 8px;
//         margin-top: 10px;   
//         border-radius: 8px;
//         height: 200px;
//         background-size:cover;
        
//        .card-body{
//            position: relative;

//             .wallet-type{
//                 font-weight: 500;
//                 font-size: 16px;
//                 line-height: 150%; 
//                 color: #DFE4EA;
//             }

//             .balance{
//                 font-size: 2rem;
//                 font-weight: 700;
//                 color: #FFFFFF;
//             }

//             .btn{
//                 width: 140px !important;
//                 height: 40px !important;
//                 border: 1px solid #F5A623 !important;
//                 border-radius: 100px !important;
//                 color: #F5A623 !important;
//                 position: absolute !important;
//                 bottom: 20px !important;
//                 font-size: .8rem !important;
//             }

//             .btn-wallet-type{
//                 position: absolute;
//                 top:8px;
//                 right:0;
//                 font-style: normal;
//                 font-weight: 500;
//                 font-size: .8rem;
//                 line-height: 120%; 
//                 color: #000000;
//                 width:100px;
//                 border: none !important;
//             }

//             .add-btn{
//                 background-color: #F5A623 !important; 
//             }


//         border-radius: 8px;
         
//     } 
//   } 
// }



