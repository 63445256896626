@import "../../../../../../../Assets/Scss/primaryColor";
.statistics {
  background-color: $white;
  border-radius: 10px;

  .stats-card {
    height: 110px;
  }
  .stats-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #606060;
  }
  .stats-amount {
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: #303030;
  }
  .stats-icon-1 {
    color: #fb607f;
    transform: rotate(45.2deg);
    font-size: 1.3rem;
    padding: 3px 5px;
    border-radius: 50px;
    background: #ffeaee;
    font-weight: 900;
    width: 32px;
    height: 32px;
  }
  .stats-icon-2 {
    color: #10bdff;
    transform: rotate(45.2deg);
    font-size: 1.3rem;
    padding: 3px 5px;
    border-radius: 50px;
    background: #dcf5ff;
    font-weight: 900;
    width: 32px;
    height: 32px;
  }
  .stats-icon-3 {
    color: #fb607f;
    transform: rotate(45.2deg);
    font-size: 1.3rem;
    padding: 3px 5px;
    border-radius: 50px;
    background: #ffeaee;
    font-weight: 900;
    width: 32px;
    height: 32px;
  }
  .stats-icon-4 {
    color: #7f59fe;
    transform: rotate(45.2deg);
    font-size: 1.3rem;
    padding: 3px 5px;
    border-radius: 50px;
    font-weight: 900;
    width: 32px;
    height: 32px;
    background: #e1d8ff;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
}
