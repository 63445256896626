@import "../../../../../../../Assets/Scss/primaryColor";
.sites{

    .site-count{
        font-style: normal;
        font-weight: 400;
        font-size: .95rem;
        line-height: 24px; 
        color: #303030;

    }

    .btn-new-user{ 
        background: #F5A623;
        border: 1px solid #DFE4EA;
        border-radius: 100px;font-style: normal;
        font-weight: 400;
        font-size: .8rem;
        line-height: 18px; 
        display: flex;
        align-items: center;
        text-align: center;
        height: 40px;
        width: 100%;
        display: flex;
        justify-content: center; 
        color: #FFFFFF;

        @media (max-width:800px) {
            margin-top: 20px;
        }

        svg{
            color: #ffffff;
            width: 16px;
            margin-right: 10px;
        }
    }


    .btn-new-user-estate{ 
        background: $primary-estate;
        border: 1px solid #DFE4EA;
        border-radius: 100px;font-style: normal;
        font-weight: 400;
        font-size: .8rem;
        line-height: 18px; 
        display: flex;
        align-items: center;
        text-align: center;
        height: 40px;
        width: 100%;
        display: flex;
        justify-content: center; 
        color: #FFFFFF;

        @media (max-width:800px) {
            margin-top: 20px;
        }

        svg{
            color: #ffffff;
            width: 16px;
            margin-right: 10px;
        }
    }

    .site-item-container {

        .card{ 
         background: #FFFFFF;   
         border: 1px solid #DFE4EA !important;
         border-radius: 8px;
 
         .card-header{
             background-color: #ebebeb;
             background-image: url(./../../../../../../../Assets/Images/warehouse-card-head.png);
             background-size: cover;
             .warehouse-name  {
                 font-style: normal;
                 font-weight: 600;
                 font-size: .84rem;
                 line-height: 150%; 
                 color: #FFFFFF;  
             }

             .site-location-img{
                width: 50px;
             }
          }
 
          .card-body{
             div{
                 font-size: .8rem; 
                 line-height: 30px; 
             }
 
             .fAddres{
                 height:37px;
                 overflow-y: hidden;
             }
          }
 
          .card-footer{
             border-top: .7px solid rgb(223, 222, 222); 
 
             .btn{
                font-style: normal;
                font-weight: 500;
                font-size: .8rem;
                line-height: 18px;  
                color: #F5A623;

            }
            .btn-estate{  
                color: $primary-estate !important; 
            }
          }
        } 
     }
}


.addNewSiteModal{  
    max-width: 436px;   
    background: #FFFFFF;
    border: 1px solid #DFE4EA;
    border-radius: 28px;
    padding-bottom: 20px;
    //   }
    .modal-title{
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 32px; 
        color: #333333;
    }


    .modal-content{
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 28px;
    }

    .form-control{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        height: 50px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px; 
        font-feature-settings: 'liga' off; 
        color: #828282;

      

        &:focus{
            outline: none !important;
            box-shadow: none !important;
        }
    }

    select{
        font-size: .8rem !important;
        option{ 
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: .8rem !important;
            line-height: 18px; 
            font-feature-settings: 'liga' off; 
            color: #828282;
        }

    }
    
    .textarea-pk{
        &::placeholder{
            font-size: .7rem;
        }
    }
    .btn-close {
        height: 15px;
        width: 15px; 
        border: 1px solid grey;
        border-radius: 50px; 
        font-size: .7rem !important;
    }
    label{
        font-weight: 400;
        font-size: .8rem;
        line-height: 19px;
        padding-bottom: 8px;
        color: #333333;
    }

    .btn{
        width: 100%;
        height: 46px;  
        border: 1px solid #DFE4EA;
        border-radius: 100px;


        background: #F5A623;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        font-style: normal;
        font-weight: 700;
        font-size: .85rem;
        line-height: 18px;  
        color: #FFFFFF;

        &:active{
            border: 1px solid #F5A623; 
        }
        &:focus{
            border: 1px solid #F5A623; 
        }
    }

    .btn-estate{  
        background-color: $primary-estate !important; 
        &:active{
            border: 1px solid $primary-estate !important;
        }
        &:focus{
            border: 1px solid $primary-estate !important;
        }
    }

    .defaultaddress-row{
        display: flex;
        flex-direction: row; 
        align-items: center;
        height: 20px;

        .checkbox-pk{
            height: 14px !important;
            width: 14px !important;
            background-color: #3378F3;
            margin-right: .6rem;
        }
        label{ 
            display: flex;
            align-items: center;
        }
    }

    .contact-title{
        font-size: 1rem;
        margin-top: 20px;
        font-weight: 700; 
        line-height: 32px;  
        color: #333333;
    }
}