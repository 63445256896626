@import "../../../../../Assets/Scss/primaryColor";
.quoteModal {
  .offcanvas-header{

        border-bottom: 1px solid #DFE4EA;
        height: 100px;
        color: #333333;  
        .offcanvas-title{

          font-weight: 700;
          font-size: 1.3rem;
          line-height: 32px; 
          display: flex;
          align-items: center;  
        }

        .btn-close{ 
          border: 1.5px solid #333333;
          border-radius:50px;
          width:7px;
          height:7px;
          font-size: .66rem;
          margin-right: 10px;
        }
    }
    .deliveryLocation {
      height: 48px;
      left: 557px;
      top: 678px;
      background: #ecf1f4;
      box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
      border-radius: 8px;
      &:focus {
        box-shadow: none !important;
      }
    }
  
      
    .bg--lights {
      background: rgba(82, 140, 245, 0.1);
    }
    .product-min-order {
      font-size: 0.6rem;
    }
    .prod-title{
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 24px; 
      display: flex;
      align-items: center;  
      color: #1f1f1f;
    }
    .quote-title {
      font-weight: 800;
      font-size: 24px;
      line-height: 29px;
      color: #151c2f;
    }
    .req-btn {
      background: #4379EB;
      border: 1px solid #DFE4EA;
      border-radius: 100px;
      height: 45px;
    }

    input{ 
      background: #FFFFFF!important ;
      border: 1px solid #DFE4EA!important ;
      border-radius: 100px!important ;
    }

    textarea{
      background: #FFFFFF !important;
      border: 1px solid #DFE4EA;
      border-radius: 20px !important;
      height: 101px;

        &::placeholder{
          font-size: .8rem; 
        }

        &:focus{
          box-shadow: none !important;

        }
      }

      select{ 
        background: #FFFFFF!important ;
        border: 1px solid #DFE4EA!important ;
        border-radius: 100px!important ;
      }
      
    .textarea-label{
      font-weight: 400;
      font-size: .74rem;
      line-height: 19px; 
      color: #4b4a4a; 
      margin-bottom: 10px;
    }
   
  
    .container-radio {
      .radio {
        margin: 0.5rem;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #303030;

        .radio-btn{
          width: 100%;
          height: 46px;  
          border: 1px solid #bab9b9;
          border-radius: 8px;
          background-color: #ffffff !important;

          &.active{ 
             border: 2px solid darken($primary, 15%);;
          }
        }

  
        input[type="radio"] {
          position: absolute;
          opacity: 0;
          + .radio-label {
            &:before {
              content: "";
              background: $white;
              border-radius: 100%;
              border: 1px solid darken($primary, 25%);
              display: inline-block;
              width: 1.4em;
              height: 1.4em;
              position: relative;
              top: -0.2em;
              margin-right: 1em;
              vertical-align: top;
              cursor: pointer;
              text-align: center;
              transition: all 250ms ease;
            }
          }
          &:checked {
            + .radio-label {
              &:before {
                background-color: black;
                box-shadow: inset 0 0 0 4px $white;
              }
            }
          }
          &:focus {
            + .radio-label {
              &:before {
                outline: none;
                border-color: $primary;
              }
            }
          }
          &:disabled {
            + .radio-label {
              &:before {
                box-shadow: inset 0 0 0 4px $white;
                border-color: darken($white, 25%);
                background: darken($white, 25%);
              }
            }
          }
          + .radio-label {
            &:empty {
              &:before {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    .tandc{
      label{ 
        height: 30px;
        display: flex;
        align-items: center;
        flex-direction: row;
        font-size: .8rem; 
  
        font-size: .8rem;

        span{
          margin-left: 10px;
        }
      }
    }

    .new-delivery-address{
      font-size: .7rem;
   }


   .QRaddAddress{
      .form-control{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 70px;
        height: 50px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px; 
        font-feature-settings: 'liga' off; 
        color: #828282;

      

        &:focus{
            outline: none !important;
            box-shadow: none !important;
        }
    }

    select{
        font-size: .8rem !important;
        option{ 
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: .8rem !important;
            line-height: 18px; 
            font-feature-settings: 'liga' off; 
            color: #828282;
        }

    }

    .textarea-pk{
        &::placeholder{
            font-size: .7rem;
        }
    }
    .btn-close {
        height: 15px;
        width: 15px; 
        border: 1px solid grey;
        border-radius: 50px; 
        font-size: .7rem !important;
    }
    label{
        font-weight: 400;
        font-size: .8rem;
        line-height: 19px;
        padding-bottom: 8px;
        color: #333333;
    }

    .btnadd{
        width: 481px;
        height: 46px;  
        border: 1px solid #DFE4EA;
        border-radius: 100px;
    }

    .defaultaddress-row{
        display: flex;
        flex-direction: row; 
        align-items: center;
        height: 20px;

        .checkbox-pk{
            height: 14px !important;
            width: 14px !important;
            background-color: #3378F3;
            margin-right: .6rem;
        }
        label{ 
            display: flex;
            align-items: center;
        }
    }

    .contact-title{
        font-size: 1rem;
        margin-top: 20px;
        font-weight: 700; 
        line-height: 32px;  
        color: #333333;
    }
  }
  }

  .custom-qr-modal {
    padding-top: 40px;
    .custom-offcanvas-close-btn {
       margin-top: -20px;
        &:hover {
            cursor: pointer;
            opacity: 0.6;
        }
    }

    .custom-offcanvas-title {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: #333333;
    }
}