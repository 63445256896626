@import "../../../../../../../Assets/Scss/primaryColor";
.enterpriseBankDetails{
    .title{
        font-weight: 400;
        font-size: .9rem;
        line-height: 150%; 
        color: #303030;
    }

    .card{
        background: #EFF2F6; 
        border: 1px solid #DFE4EA;
        border-radius: 8px;
        margin-top: 10px;

        .card-body{  
            height: 95px;
            .add-bank{
                width: 90px;
                height: 40px; 
                background: #FFFFFF;
                border: 1px solid #DFE4EA;
                border-radius: 100px;
                font-size: .8rem;
            }

            .nobank{
                font-weight: 500;
                font-size: .8rem;
                line-height: 150%; 
                color: #303030;
                margin-bottom: 10px;
            }
        }
        .card-footer{
            background: #EFF2F6; 

            .btn{
                width: 100%;
                height: 44px; 
                background: #FFFFFF;
                border: 1px solid #DFE4EA;
                border-radius: 100px;

                font-weight: 600;
                font-size: .9rem;
                line-height: 18px;  

                color: #F5A623;
                flex: none;
                order: 0;
                flex-grow: 0;
            }

            .btn2{ 
                color: $primary-estate !important;
            }

            .name{ 
                font-weight:600;
                font-size: .9rem;
                line-height: 19px; 
                color: #000000; 
                margin-bottom: 10px;
            }
            .bank{
                font-weight: 600;
                font-size: .9rem;
                line-height: 25px; 
                letter-spacing: 0.335em; 
                color: #000000;
            }
        }
    }
}