.cbdmodal{
    .modal-content{
        @media (max-width:600px) {
            padding: 10px;  
            border-radius: 20px;  
        }
        @media (min-width:600px) {
            padding: 20px; 
            border-radius: 20px;  
        }
    } 
    .modal-header{
        border: none;
        .modal-title{
         font-size: 1rem;
        }
        .btn-close{
            border: 1px solid grey;
            border-radius: 50px;
            width: 10px;
            height: 10px;
            font-size: .6rem;
            font-weight: 700;
        }
    }
}