.seller-quote-response-modal {  
  .success{
    height: 40vh;
  }
  .success-icon{
    width: 80px; 
    height: 80px;
  }
  label {
    font-size: .8rem;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }

  .wallet-modal {    
     .form-control {
      height: 56px;
      background: #fff;
      border: 1px solid #b0b0b0cc;
      border-radius: 100px;
      padding-left: 50px;
      margin-top: 8px;
    }
   
    .input-wrap {
      position: relative;
      .input-symbol {
        position: absolute;
        left: 16px;
        top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        color: #868484;
         
      }
    }
    .wallet-btn{
      border-radius: 100px;
      height: 56px;
      background-color: #34ba96;
      color: white;
    }
  }
}
