.give-us-a-call  {
    position: relative;
    cursor: pointer;
    .next-icon {
        position: absolute;
        color:#4379EB;
        top: 30px;
        right: 20px;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #EFF2F7;

        > img {
           
        }
    }

    > a {
        padding: 30px 0px;
        border: 1px solid #eae6df;
        border-radius: 8px;
        row-gap: 30px;
    }

    .call-icon-container {
        width: 81px;
        height: 81px;
        background: #EFF2F6;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 57px;
            height: 57px;
        }
    }
}