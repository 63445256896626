.editCreateVariation{
  height: 100%;
  .input-tag {
      background: white;
      border: 1px solid #d6d6d6;
      border-radius: 2px;
      display: flex;
      flex-wrap: wrap;
    }
    
    .input-tag input {
      border: none;
      width: 100%;  
      height: inherit;
    }
    
    .input-tag__tags {
      display: inline-flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      width: 100%;
    }
    
    .input-tag__tags li {
      align-items: center;
      background: rgba(52, 186, 150, 0.15);;
      height: 30px;
      border-radius: 18px;
      color: #34BA96;
      display: flex;
      font-size: .7rem;
      font-weight: 500;
      list-style: none;
      margin-bottom: 5px;
      margin-right: 5px;
      padding: 5px 10px;
    }
    
    .input-tag__tags li button {
      align-items: center;
      appearance: none;
      background: #34BA9626;
      border: none;
      border-radius: 50%;
      color: #34BA96;
      cursor: pointer;
      display: inline-flex;
      font-size: 12px;
      height: 15px;
      justify-content: center;
      line-height: 0;
      margin-left: 8px;
      padding: 0;
      transform: rotate(45deg);
      width: 15px;
    }
    
    .input-tag__tags li.input-tag__tags__input {
      background: none;
      flex-grow: 1;
      padding: 0;
    }


    .add-attribute{
      height: 30px; 
      display: flex;
      align-items: center;
      button{ 
        display: block;
        color: #34BA96;
        font-size: .8rem;
        
      }
    }


    .uploadedImgvariation{ 
      width: 100%;
      height: 175px; 


      .card-body{
        position: relative;
        overflow: hidden;
        label{ 
          cursor: pointer;
        }
        .image-wrap{ 
          width: 100%;
          height: 175px; 
          display: flex;
          justify-content: center;
          align-items: center;
        }
         .btn{
          width: 15px;
          height: 15px;
          position:absolute;
          right:0;
          border:1px solid #000000;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px;
          i{
            font-size: .7rem;
          }
        } 
      }
    }
    small{
      font-size: .6rem;
    }
}