@import "../../../../../../Assets/Scss/primaryColor";
.enterpriseWareHouseManagement{
    margin-top: 17px;

    .heading-text{
        font-style: normal;
        font-weight: 400;
        font-size: .9rem;
        line-height: 19px; 
        color: #868686;  

    }
    .heading-count{
        font-style: normal;
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 22px;
        margin-top: 20px; 
        color: #303030;
    }

    .security-btn-primary{ 
        height: 44px; 
        background: #4379EB;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        color: #DFE4EA;
        font-size: 1rem;
    }

    .form-control{
        border-radius: 100px;
    }

    label{ 
        font-style: normal;
        font-weight: 400;
        font-size: .8rem; 
        color: #333333;
        padding-bottom: 10px;
    }

    .addnew-address{    
        background: #F5A623;
        border: 1px solid #F5A623;
        border-radius: 100px;

        font-style: normal;
        font-weight: 400;
        font-size: .8rem;
        line-height: 18px;  

        color: #FFFFFF; 
        flex: none;
        order: 1;
        flex-grow: 0;

        &:hover{
            color: #ffffff; 
            background: #f5ab35;
            border: 1px solid #F5A623;
        }

        &:focus{
            color: #ffffff; 
            border: 1px solid #F5A623;
            background: #f5ab35;
        }
    }

    .addnew-address-estate{ 
        background: $primary-estate !important;
        border: 1px solid $primary-estate !important;

        &:hover{ 
            background: $primary-estate !important;
            border: 1px solid $primary-estate !important;
        }

        &:focus{ 
            border: 1px solid $primary-estate !important;
            background: $primary-estate !important;
        }
    }


    .warehouse-item-container {
       .card{ 
        background: #FFFFFF;   
        border: 1px solid #DFE4EA !important;
        border-radius: 8px;

        .card-header{
            background-color: #ebebeb;
            background-image: url(./../../../../../../Assets/Images/warehouse-card-head.png);
            background-size: cover;
            .warehouse-name  {
                font-style: normal;
                font-weight: 600;
                font-size: .84rem;
                line-height: 150%; 
                color: #FFFFFF;  
            }
         }

         .card-body{
            div{
                font-size: .8rem; 
                line-height: 30px; 
            }

            .fAddres{
                height:37px;
                overflow-y: hidden;
            }
         }

         .card-footer{
            border-top: .7px solid rgb(223, 222, 222); 

            .btn{
                font-style: normal;
                font-weight: 500;
                font-size: .8rem;
                line-height: 18px;  
                color: #F5A623;

            }

            .btn-estate{
                color: $primary-estate !important;  
            }
         }
       } 
    }
}




.addNewAWareHouseModal{  
    max-width: 436px;   
    background: #FFFFFF;
    border: 1px solid #DFE4EA;
    border-radius: 28px;
    padding-bottom: 20px;
    //   }
    .modal-title{
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 32px; 
        color: #333333;
    }


    .modal-content{
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 28px;
    }

    .form-control{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        height: 50px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px; 
        font-feature-settings: 'liga' off; 
        color: #828282;

      

        &:focus{
            outline: none !important;
            box-shadow: none !important;
        }
    }

    select{
        font-size: .8rem !important;
        option{ 
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: .8rem !important;
            line-height: 18px; 
            font-feature-settings: 'liga' off; 
            color: #828282;
        }

    }
    
    .textarea-pk{
        &::placeholder{
            font-size: .7rem;
        }
    }
    .btn-close {
        height: 15px;
        width: 15px; 
        border: 1px solid grey;
        border-radius: 50px; 
        font-size: .7rem !important;
    }
    label{
        font-weight: 400;
        font-size: .8rem;
        line-height: 19px;
        padding-bottom: 8px;
        color: #333333;
    }

    .btn{
        width: 100%;
        height: 46px;  
        border: 1px solid #DFE4EA;
        border-radius: 100px; 
        background: #F5A623;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        font-style: normal;
        font-weight: 700;
        font-size: .85rem;
        line-height: 18px;  
        color: #FFFFFF;

        &:active{
            border: 1px solid #F5A623; 
        }
        &:focus{
            border: 1px solid #F5A623; 
        }
    }

    .btn-estate{
        background: $primary-estate !important;  

        &:active{
            border: 1px solid $primary-estate !important;; 
        }
        &:focus{
            border: 1px solid $primary-estate !important;; 
        }
    }

    .defaultaddress-row{
        display: flex;
        flex-direction: row; 
        align-items: center;
        height: 20px;

        .checkbox-pk{
            height: 14px !important;
            width: 14px !important;
            background-color: #3378F3;
            margin-right: .6rem;
        }
        label{ 
            display: flex;
            align-items: center;
        }
    }

    .contact-title{
        font-size: 1rem;
        margin-top: 20px;
        font-weight: 700; 
        line-height: 32px;  
        color: #333333;
    }


    .default-wh{
        height: 20px;
        display: flex;
        align-items: center; 
        
        input { 
            height: 15px;
            accent-color: #F5A623;
            margin-right: 10px;
            margin-top: 0px;
            margin-bottom: 0px;
          }
    }
}