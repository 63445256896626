@import "../../../../../../../Assets/Scss/primaryColor";

.prodDetails {
  margin-bottom: 100px;

  .prodDetails-inner {
    .prodDetails-right {
      @media (max-width: 500px) {
        .card {
          margin: 0px !important;
        }
      }
      .card-body {
        @media (min-width: 500px) {
          .prod-detail-title {
            font-weight: 800;
            font-size: 32px !important;
            line-height: 38px;
            letter-spacing: -0.01em;
            color: #000000;
          }
        }

        @media (max-width: 500px) {
          .prod-detail-title {
            font-weight: 800;
            font-size: 20px !important;
            line-height: 28px !important;
            letter-spacing: -0.01em;
            color: #000000;
          }
        }

        .price {
          font-weight: 800;
          font-size: 24px;
          line-height: 29px;
          color: #1a1a1a;
        }
        .rating {
          font-weight: 500;
          font-size: 0.8rem;
          line-height: 150%;
          color: #303030;
        }
        .qtycont {
          display: flex;
          .qty {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: #303030;
          }
        }

        .qtyInput {
          width: 50px;
        }
      }

      .card-footer {
        .addtocart {
          padding: 12px 24px;
          width: 203px;
          height: 48px;
          background: #3378f3;
          box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
            inset 0px -1px 0px rgba(14, 14, 44, 0.4);
          border-radius: 8px;
        }
      }
    }

    .prodDetails-left {
      .card {
        height: 430px;

        @media (min-width: 500px) {
          .prod-img {
            width: 35%;
          }
        }
        @media (max-width: 500px) {
          .prod-img {
            width: 80%;
          }

          .row {
            padding-right: 0px !important;
          }
        }
      }
      @media (max-width: 500px) {
        margin: 10px !important;
      }
    }

    .vertScrolImg {
      #image-slider {
        display: block;
        margin: 0 auto;
        overflow: hidden;
        background: #fff;
        height: 200px;
      }
      #image-slider {
        ul {
          list-style: none;
        }
      }
      #image-slider {
        ul {
          li {
            display: inline-block;
            margin: 0;
            padding: 0;
          }
        }
      }
      #image-slider ul img {
        width: 100%;
        max-width: 500px;
        height: 50px;
      }
      .controls {
        padding: 1em 0;
        width: 100%;
        display: block;
        margin: 0 auto;
      }
      .controls a {
        text-decoration: none;
        color: #34495e;
      }
      .controls a:hover {
        cursor: pointer;
      }
      .img-up {
        margin-top: -300px;
      }
    }
  }

  .about-seller {
    @media (max-width: 984px) {
      height: auto;
    }
    @media (min-width: 984px) {
      height: 90px;
    }

    background-color: $white;
    .seller-name-label {
      font-weight: 500;
      font-size: 0.8rem;
      line-height: 150%;
      color: #303030;
    }
    .seller-name {
      font-weight: 700;
      font-size: 0.9rem;
      line-height: 24px;
      color: #3378f3;
    }
    .msg-seller {
      // padding: 10px 24px;
      width: 100%;
      height: 41px;
      background: #ffffff;
      border: 1px solid #3378f3;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }

  .prod-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #868484;

    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      font-weight: 800;
      font-size: 1.3rem;
      line-height: 29px;
      color: #303030;
      background-color: transparent !important;

      @media (max-width: 500px) {
        font-size: 1rem !important;
      }
    }
    .nav-pills .nav-link {
      font-weight: 800;
      font-size: 1.3rem;
      line-height: 29px;
      color: #868484;

      @media (max-width: 500px) {
        font-size: 1rem !important;
      }
    }
  }

  .review {
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #868484;
    }

    .review-name {
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 150%;
      color: #303030;
    }
    .review-date {
      font-weight: 500;
      font-size: 0.7rem;
      line-height: 150%;
      color: #868484;
    }
  }

  ::ng-deep .modal-content {
    font-family: Lato, sans-serif;
    width: 496px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
  }

  .modal-header {
    border-bottom: 0 none;
  }

  .modal-footer {
    border-top: 0 none;
  }

  .modalInput {
    width: 100%;
    background-color: #ecf1f4;
    color: #868484;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    box-shadow: inset 0 2px 2px -1px rgba(74, 74, 104, 0.1);
    border-radius: 8px;
    float: left;
    margin-top: 32px;
  }

  .modalTitleText {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }

  .product-quote {
    width: 100%;
    height: 116px;
    background: rgba(82, 140, 245, 0.1);
    float: left;
  }

  .product-quote-image {
    width: 73px;
    height: 73px;
    background: #ffffff;
    border-radius: 8px;
    float: left;
  }

  .product-min-order {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 140%;
    color: #868484;
    float: left;
    margin-left: 20px;
    margin-right: 10px;
  }

  .checkmark {
    height: 24px;
    width: 24px;
    background: #3378f3;
  }

  .req-btn {
    background: $primary;
    border-radius: 8px;
  }

  .modalTextareaInput {
    width: 100%;
    height: 101px;
    background: #ecf1f4;
    box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
    border-radius: 8px;
    &:focus {
      border-color: rgba(224, 224, 224, 0.651);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
        0 0 8px rgba(206, 206, 206, 0.6);
      outline: 0 none;
      font-size: 0.8rem;
      padding: 5px;
    }
  }

  .modalTextInput {
    width: 100%;
    height: 48px;
    background: #ecf1f4;
    box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
    border-radius: 8px;
  }

  .bg--light {
    background: rgba(82, 140, 245, 0.1);
    width: 100%;
    height: auto;
  }
  .req-order-preview {
    width: 100%;
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #000000;
    }
  }

  .modal-title {
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #151c2f;
  }
}

.quoteModal {
  .deliveryLocation {
    height: 48px;
    left: 557px;
    top: 678px;
    background: #ecf1f4;
    box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
    border-radius: 8px;
    &:focus {
      box-shadow: none !important;
    }
  }

  .messageInput {
    height: 101px;
    border-radius: 8px;

    /* Accent */

    background: #ecf1f4 !important;
    /* Field/Inset */

    box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
    border-radius: 8px;
    &:focus {
      box-shadow: none !important;
    }
  }

  .bg--lights {
    background: rgba(82, 140, 245, 0.1);
  }
  .product-min-order {
    font-size: 0.7rem;
  }
  .quote-title {
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #151c2f;
  }
  .req-btn {
    padding: 12px 24px;
    width: 450px;
    height: 49px;

    background: #3378f3;
    box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
      inset 0px -1px 0px rgba(14, 14, 44, 0.4);
    border-radius: 8px;
  }

  .container-radio {
    .radio {
      margin: 0.5rem;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #303030;

      input[type="radio"] {
        position: absolute;
        opacity: 0;
        + .radio-label {
          &:before {
            content: "";
            background: $white;
            border-radius: 100%;
            border: 1px solid darken($primary, 25%);
            display: inline-block;
            width: 1.4em;
            height: 1.4em;
            position: relative;
            top: -0.2em;
            margin-right: 1em;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
            transition: all 250ms ease;
          }
        }
        &:checked {
          + .radio-label {
            &:before {
              background-color: black;
              box-shadow: inset 0 0 0 4px $white;
            }
          }
        }
        &:focus {
          + .radio-label {
            &:before {
              outline: none;
              border-color: $primary;
            }
          }
        }
        &:disabled {
          + .radio-label {
            &:before {
              box-shadow: inset 0 0 0 4px $white;
              border-color: darken($white, 25%);
              background: darken($white, 25%);
            }
          }
        }
        + .radio-label {
          &:empty {
            &:before {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
