@import "./../../../../Assets/Scss/primaryColor";

.pageheader{
    .breadcrumb-item+.breadcrumb-item::before {
        float: left;
        padding-right: 1rem;
        color: #868484;
        content: "\003E" !important;
    } 
    @media (max-width:600px) { 
        .breadcrumb-item{
           font-size: .7rem;
        } 
    }
    @media (min-width:600px) { 
        .breadcrumb-item{

        } 
    }

    .pageheader-banner{
        height:78vh; 
        background-image: linear-gradient(to bottom, rgba(10, 10, 10, 0.925), rgba(0, 15, 100, 0.73)), 
        url("./../../../../Assets/Images/pageHeader.png"); 
        background-position:center;
        background-size:contain;

        h1{ 
            font-weight: 800;
            font-size: 64px;
            line-height: 77px;
            text-align: center;
            letter-spacing: -0.01em;  
            color: #F9F9F9;
        }
    }

}

@media(max-width:765px){
    .h--1000 {
        height: 100%!important;
        width: 100%!important;
    }
}
    @media(max-width:500px){
    .pageheader-banner{
        height:50vh !important;  
        background-position:center !important;;
        background-size:cover!important; 
    }
    
    
        h1{ 
            font-weight: 800;
            font-size: 2.2rem !important;
            line-height: 50px !important; 
        }
}

@media(min-width:500px) and (max-width:1000px){

        h1{ 
            font-weight: 800;
            font-size: 2.7rem !important;
            line-height: 50px !important; 
        }
}


