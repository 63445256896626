.newDeliveryItem{
    .card{  
         
        width: 100%;  
        border: 1px solid #DFE4EA;
        border-radius: 8px; 

        @media (max-width:600px) {
            margin-top: 10px;
        }
   
        .label{ 
            height: 149px;
            display: flex;
            justify-content: center;
            align-items: center; 
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 32px; 
            text-align: center;  
            color: #3378F3; 
        }
    }

}