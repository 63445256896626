.entfaq-container {
    background: #ffffff;
    border-radius: 8px;
    margin-top: 20px;
    border: 1px solid rgb(222, 220, 220);

    @media (min-width:600px) {
        padding: 49px 35px; 
    }

    .user-first-name {
        font-style: normal;
        font-weight: 800;
        font-size: 33.291px;
        line-height: 40px;
        color: rgb(24, 24, 24);
        letter-spacing: -0.01em;
    }

    .cta-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: rgb(24, 24, 24);
    }
}