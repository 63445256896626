.CreateProject{
    label{
        font-size: .77rem;
        margin-bottom: 5px;
        color: #414040;
    }
    .form-control{
        border-radius: 30px;
        height: 40px;

        &::placeholder{ 
          font-size: .8rem;
          color: rgb(145, 145, 145);
        }
        
        &:focus{
            border: 1px solid #818181;
        }
    }

    textarea{
        font-size: .8rem;
        min-height: 70px;
        border-radius: 20px  !important;
        &:focus{
            border: 1px solid #818181;
            box-shadow: none;
        }
    }

    .create-btn{
        width: 100%;
        height: 46px; 
        font-weight: 400;
        border-radius: 100px;
        font-size: .8rem;
        line-height: 18px; 
        color: #ffffff; 
        margin-top: 20px;
        background-color: #3A0CA3;
        margin-bottom: 40px;

        &:hover{ 
            width: 100%;
            height: 46px; 
            font-weight: 600;
            border-radius: 100px;
            font-size: .8rem;
            line-height: 18px; 
            color: #ffffff; 
            margin-top: 20px;
            background-color: #481fa9;
        }
    }

    .error{
        font-size: .6rem;
        font-weight: 400;
    }

}