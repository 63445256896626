.custom-modal {
    padding-top: 40px;
    .custom-offcanvas-close-btn {
       margin-top: 2px;
        &:hover {
            cursor: pointer;
            opacity: 0.6;
        }
    }

    .custom-offcanvas-title {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: #333333;
    }
}