.order-tracking-card {
    margin-bottom: 20px;
    .order-tracking-card-header{
        font-weight: bold;
        font-size: 20px;
        list-style: 24px;
        background-color: white;
        border:none !important;
        padding-top: 30px;
        color:#333333;

        &::after {
            border: .1px solid #E0E0E0;
            width: 100%;
            background-color: black;
            display: block;
            content: " ";
            margin-top:30px ;   
        }

        span {
            font-size: 16px;
            line-height: 18px;
            font-weight: normal;
        }
    }

  
}