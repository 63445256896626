.order-delivery-address {
    padding:32px 0px;
    
    p {
        margin:0px ;
        padding:0px
    }

    .list-item-title {
        color:#333333;
        font-size: 16px;
        line-height: 19px;
        font-weight: 700 !important;
        //margin-bottom: 20px;
    }

    .order-delivery-address-title {
        margin-bottom: 20px;
    }

    >div{
        column-gap: 12px;
    }
}