.seller-profile-page{
    .security-btn-primary{ 
        height: 44px; 
        background: #4379EB;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        color: #DFE4EA;
        font-size: 1rem;
    }

    .form-control{
        border-radius: 100px;
    }

    label{ 
        font-style: normal;
        font-weight: 400;
        font-size: .8rem; 
        color: #333333;
        padding-bottom: 10px;
    }

    .addnew-address{   
        height: 44px;  
        border: 1px solid #3378F3;
        border-radius: 100px;
        padding-left: 15px;
        padding-right: 15px;

        font-weight: 600;
        font-size: .8rem;
        line-height: 32px;   
        color: #3378F3; 

        &:hover{
            color: #ffffff; 
        }
    }


    .address-book{
       .card{ 
        background: #FFFFFF;  
        border: 1px solid #dedede;
        border-radius: 8px;

        .card-header{
            .item-count{
               background: #3378F3;
               color: #FFFFFF;
               width:17px;
               height:17px;
               font-weight: 500;
               display: flex;
               justify-content: center;
               align-items: center;
               border-radius: 50px;
               font-size: .8rem;
            }
            .default-item{ 
                height: 24px;  
                padding: 10px 1px;
                background: rgba(242, 153, 74, 0.09);
                border-radius: 100px; 
                font-size: .8rem;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #F2994A;

            }
         }

         .card-body{
            div{
                font-size: .8rem;
            }

            .fAddres{
                height:37px;
                overflow-y: hidden;
            }
         }

         .card-footer{
            border-top: .7px solid rgb(223, 222, 222); 

            .btn{
                font-style: normal;
                font-weight: 700;
                font-size: .9rem;
                line-height: 18px;  
                color: #3378F3;
            }
         }
       } 
    }
}

.addNewAddressBookModal{  
    max-width: 436px;  
    // .modal-content {
    background: #FFFFFF;
    border: 1px solid #DFE4EA;
    border-radius: 28px;
    padding-bottom: 20px;
    //   }
    .modal-title{
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 32px; 
        color: #333333;
    }


    .modal-content{
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 28px;
    }

    .form-control{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        height: 50px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px; 
        font-feature-settings: 'liga' off; 
        color: #828282;

      

        &:focus{
            outline: none !important;
            box-shadow: none !important;
        }
    }

    select{
        font-size: .8rem !important;
        option{ 
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: .8rem !important;
            line-height: 18px; 
            font-feature-settings: 'liga' off; 
            color: #828282;
        }

    }
    
    .textarea-pk{
        &::placeholder{
            font-size: .7rem;
        }
    }
    .btn-close {
        height: 15px;
        width: 15px; 
        border: 1px solid grey;
        border-radius: 50px; 
        font-size: .7rem !important;
    }
    label{
        font-weight: 400;
        font-size: .8rem;
        line-height: 19px;
        padding-bottom: 8px;
        color: #333333;
    }

    .btn{
        width: 481px;
        height: 46px;  
        border: 1px solid #DFE4EA;
        border-radius: 100px;
    }

    .defaultaddress-row{
        display: flex;
        flex-direction: row; 
        align-items: center;
        height: 20px;

        .checkbox-pk{
            height: 14px !important;
            width: 14px !important;
            background-color: #3378F3;
            margin-right: .6rem;
        }
        label{ 
            display: flex;
            align-items: center;
        }
    }

    .contact-title{
        font-size: 1rem;
        margin-top: 20px;
        font-weight: 700; 
        line-height: 32px;  
        color: #333333;
    }
}