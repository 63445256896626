.seller-wrapper-navbar {
  height: 72px;
  background: white;
  border-bottom: 2px solid rgba(235, 235, 235, 0.747);
  z-index: 999;
  .profile-avarter {
    width: 40px;
    height: 40px;
  }
  .search-container {
    position: relative;
    .fas {
      position: absolute;
      top: 15px;
      left: 15px;
      opacity: 0.5;
    }
    .search {
      width: 500px !important;
      height: 43px;
      border: 1px solid rgba(235, 235, 235, 0.747);
      box-sizing: border-box;
      border-radius: 8px;
      &:focus {
        border-color: rgba(224, 224, 224, 0.651);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
          0 0 8px rgba(206, 206, 206, 0.6);
        outline: 0 none;
      }
    }
  }

  .dropdown-menu {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 200px;
    padding-bottom: 20px;
  }
  .dropdown-toggle::after {
    display: none;
  }

  a.nav-link,
  .nav-custom {
    text-decoration: none !important;
    font-weight: 600;
  }

  .nav-custom {
    .btn {
      background: blue;
      box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
        inset 0px -1px 0px rgba(14, 14, 44, 0.4);
      border-radius: 8px;
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .navbar-light .navbar-toggler {
    padding: 0px !important;
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
    &:focus {
      border-color: rgba(224, 224, 224, 0.651) !important;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
        0 0 8px rgba(206, 206, 206, 0.6) !important;
      outline: 0 none !important;
    }
    &:active {
      border-color: rgba(224, 224, 224, 0.651) !important;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
        0 0 8px rgba(206, 206, 206, 0.6) !important;
      outline: 0 none !important;
    }
  }

  .navbar-toggler {
    span {
      padding: 0px !important;
      font-size: 1.6rem !important;
    }
  }

  .nav-link {
    color: blue;
  }

  @media (min-width: 994px) and (max-width: 1190px) {
    .search {
      width: 230px !important;
      height: 40px !important;
      font-size: 0.7rem;
    }

    a.nav-link,
    .nav-custom {
      text-decoration: none !important;
      font-weight: 600 !important;
      font-size: 0.9rem !important;
    }

    .nav-custom {
      .btn {
        background: blue;
        font-size: 0.8rem !important;
        font-weight: 500;
      }
    }

    .mx-md-3 {
      margin: 0 5px !important;
    }

    .fas {
      position: absolute;
      top: 13px !important;
      left: 15px;
      opacity: 0.5;
    }
  }

  @media (min-width: 1190px) and (max-width: 1399px) {
    .search {
      width: 360px !important;
      height: 40px !important;
      font-size: 0.7rem;
    }

    a.nav-link,
    .nav-custom {
      text-decoration: none !important;
      font-weight: 600 !important;
      font-size: 0.9rem !important;
    }

    .nav-custom {
      .btn {
        background: blue;
        font-size: 0.9rem !important;
        font-weight: 600;
        padding: 10px;
      }
    }

    .mx-md-3 {
      margin: 0 10px !important;
    }

    .fas {
      position: absolute;
      top: 12px !important;
      left: 15px;
      opacity: 0.5;
    }
  }

  @media (max-width: 614px) {
    .search {
      width: 100% !important;
      height: 40px !important;
      font-size: 0.7rem;
    }
    .container-00 {
      background-color: white;
      padding-bottom: 20px;
      border-bottom: 2px solid rgba(235, 235, 235, 0.747);
      z-index: 200;
    }

    a.nav-link,
    .nav-custom {
      text-decoration: none !important;
      font-weight: 600;
      color: blue;
    }
  }

  .noticount {
    background-color: blue;
    width: 20px;
    border-radius: 100px;
    text-align: center;
    font-size: 0.7rem;
    margin-top: -8px;
    color: rgb(255, 255, 255);
    font-weight: 900;
  }
}
