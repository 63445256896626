@import "../../../../../../../Assets/Scss/primaryColor";
.history {
  position: relative;
  @media (max-width: 984px) {
    b {
      font-weight: 600;
      font-size: 0.8rem;
      line-height: 24px;
      color: #303030;
    }
    .iconImage {
      width: 18px;
    }
    .desc {
      font-weight: 700;
      font-size: 0.6rem;
      line-height: 24px;
    }
  }
  @media (min-width: 984px) {
    b {
      font-weight: 600;
      font-size: 1rem;
      line-height: 24px;
      color: #303030;
    }
    .iconImage {
      width: 20px;
    }

    .desc {
      font-weight: 700;
      font-size: 0.77rem;
      line-height: 24px;
    }
  }
  .history-page-title {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
  }

  .timeTrans {
    font-size: 0.67rem;
  }

  .d-circle {
    font-size: 0.5rem;
    width: 11px;
    height: 11px;
  }

  .amount {
    font-weight: 700;
    font-size: 0.77rem;
    line-height: 24px;
  }

  .history-desc {
    min-width: 100px;
  }

  .pagination {
    .page-link {
      font-size: 0.8rem;
      color: #868484;
      font-weight: 500;
      padding: 12px;
    }
    .page-item.active {
      .page-link {
        background: rgba(82, 140, 245, 0.1);
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        border: 1px solid rgba(82, 140, 245, 0.1);
        color: $dark;
      }
    }
  }
  .formSlectOption-wrap {
    height: auto;
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 30px;

    .form-control {
      background: #ffffff;
      box-shadow: inset 0px 1px 1px -1px rgba(86, 86, 121, 0.1);
      border-radius: 5px;
      font-size: 0.9rem;
      padding: 10px 10px;
      width: 100px;
      height: 43px;
      &:focus {
        border-color: rgba(224, 224, 224, 0.651);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
          0 0 8px rgba(206, 206, 206, 0.6);
        outline: 0 none;
      }
    }
  }
  @media (max-width: 984px) {
    .atr {
      width: 150px;
    }
    .amount {
      font-weight: 700;
      font-size: 0.6rem !important;
      line-height: 24px;
    }
  }
}
