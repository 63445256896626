.productsByBrand{
    position: relative;

    .brand-header{
        .card{
            border:none;
            // border-radius: 10px;
            .card-body{
                background-color: #3378F3;
                height: 300px;
            }
            .card-footer{
                height: auto; 
                background: #e8eff8; 

                h2{
                    font-size: .9rem;
                    font-weight: 800; 
                    line-height: 17px; 
                    color: #303030;
                }

                img{
                    width:100%;
                    height:auto;
                    border-radius: 10px;
                }

                .sold-count{
                    font-size: .9rem;
                    font-weight: 500; 
                    line-height: 150%; 
                    color: #868484;
                }

            }
        }
    }
    .section-header{
        h2{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 38px;
            display: flex;
            align-items: center; 
            color: #333333;
        }
        .nav-icon{
            width:15px;
        }

        .col-1{
            font-size: .8rem;
            font-weight: 400; 
            line-height: 22px; 
            color: #3378F3; 
            flex: none;
            order: 0;
            flex-grow: 0;

            button{
                border: 1px solid rgba(255, 255, 255, 0);

                &:active{
                    border: 1px solid rgba(255, 255, 255, 0);
                }
                &:focus{
                    border: 1px solid rgba(255, 255, 255, 0);
                }
            }
        }

        .btn-popular{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 2px 8px;
            gap: 10px; 
            width: 99px;
            height: 27px; 
            background: #EFF2F6;
            border-radius: 13.5px; 
            flex: none;
            order: 2;
            flex-grow: 0;
            font-style: normal;
            font-weight: 400;
            font-size: .9rem;
            line-height: 23px; 
            color: #3378F3;
        }
    }

 

    .products-row{
        display: flex;
        flex-direction: row;

        .col-5{
            flex: 20%;
            margin: 10px;
           

            .product-card{ 
                background: #FFFFFF; 
                border: 1px solid #E0E0E0 !important;
                border-radius: 8px; 
                position: relative;

                &:hover{
                    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
                }
              
                .brand-logo{
                    width:40px;
                }

                .wish-icon{
                    width:20px
                }

                .product-image{
                    height:160px;
                }

                .col-12{
                    position: relative;
    
                    .promo{
                        position: absolute;
                        top:0 ;
                        width: 60px;
                        height: 25px;
                        left: 0; 
                        background: #FF0000;
                        border-radius: 0px 24px 24px 0px;
                        margin-top: 10px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: .8rem;
                        line-height: 120%; 
                        color: #FFFFFF;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                  }

                  .product-title{ 
                    font-style: normal;
                    font-weight: 500;
                    font-size: .9rem;
                    line-height: 150%; 
                    color: #333333;
                  }

                  .price{
                    display: flex;
                    flex-direction: row;

                    .old-price{
                        flex: 60%; 
                        font-weight: 400;
                        font-size: .8rem;
                        line-height: 17px; 
                        text-decoration-line: line-through; 
                        color: #717171;   
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                    }
                    .new-price{
                        flex: 60%;
                        font-weight: 700;
                        font-size: .9rem;
                        line-height: 19px; 
                        color: #F2994A; 
                        flex: none;
                        order: 1;
                        flex-grow: 0;
                    }
                  }

                  .company-name{ 
                    font-style: normal;
                    font-weight: 700;
                    font-size:.8rem;
                    line-height: 17px; 
                    color: #828282;
                }

                .rating{
                    span{
                        font-size: .7rem !important; 
                        color: #F2994A !important;
                    }
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: .8rem;
                    line-height: 17px; 
                    color: #828282;
                }

                .location{
                    span{
                        font-size: .7rem !important;
                    }
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: .8rem;
                    line-height: 17px; 
                    color: #828282;
                }
            }
        }
    }
 
    .carousel-control-container{ 
        width:80px !important; 
    
        .carousel-control-next{ 
            height: 35px;
            width: 35px;
            background-color: #DFE4EA !important;
            opacity: 1;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center; 
            
            .islarge{
                font-size: 1.3rem;  
                color: #333333;
                font-weight: 600 !important;
            } 
        } 
        
        .carousel-control-prev{ 
            height: 35px;
            width: 35px;
            background-color: #DFE4EA !important;
            opacity: 1;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center; 
            
            .islarge{
                font-size: 1.3rem;  
                color: #333333;
                font-weight: 600 !important;
            } 
        } 
    }
    .carousel-indicators-container{
        position: relative;
        margin-top: 50px;

        .carousel-indicators{
            position: absolute;
            left: 0;
            display: flex;
            flex-direction: row;
            
            button{
                flex: 20%;
                width: 100%;
                height: 1px !important;
                margin: 0;
                background: #a4a4a4 ;
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent; 
                &.active{
                    background: #333333 !important;
                    border-radius: 1px;
                    height: 4px;
                }
            }
        }
    }
}