
.success {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 79vh;
  padding-bottom: 20px;

  .success-icon {
    width: 162px;
    height: 162px;
    color: #34ba96;
  }

  .success-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > p {
      width: 237px;
      height: 28px;
      font-size: 24px;
      font-weight: 400;
    }

    .success-info-detail {
      width: 416px;
      height: 96px;
      margin-top: 16px;
      > p {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  .btn-store-setup {
    width: 379px;
    height: 54px;
    color: white;
    font-size: 16px;
    background-color: #34ba96;
    padding: 18px, 92px, 18px, 92px;
    border: 1px solid #dfe4ea;
    border-radius: 100px;
  }
  
  @media (max-width: 500px) {
   .btn-store-setup {
    width: 235px;
    height: 54px;
    color: white;
    font-size: 16px;
    background-color: #34ba96;
    padding: 18px, 92px, 18px, 92px;
    border: 1px solid #dfe4ea;
    border-radius: 100px;
  }
  .success-info{
      margin-bottom: 16px;
    .success-info-detail {
      width: 235px;
      height: 96px;
      margin-top: 26px;
    
      > p {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
      }
    }}
  
}
}

@media (min-width: 768px) {
  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
}


