.custom-dropdown {
    position: relative;

    .btn-title {
        font-weight: 400;
        font-size: .99rem;
        line-height: 22px;
        color: #3378F3;

    }
    img{
        width:17px
    }

    .custom-dropdown-menu {
        position: absolute;
        z-index: 888;
        top:100%;
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 8px;
        position: absolute;
        width: 234px;
        height: fit;
        max-height: 296px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px;
        margin: 0px;
        box-shadow: -17px 29px 46px 13px rgba(239,242,246,1);
        -webkit-box-shadow: -17px 29px 46px 13px rgba(239,242,246,1);
        -moz-box-shadow: -17px 29px 46px 13px rgba(239,242,246,1);

        .custom-dropdown-item {
            padding: 20px;
            width: 100%;
            cursor: pointer;
            list-style: none !important;
            padding-left: 24px;
            padding-right: 24px;
            border-bottom: 1px solid #DFE4EA;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: #333333;

            &:hover, &:focus {
                background-color: #4379EB;
                color:white
            }
            
            a {
                width: 100%;
                display: block;
                color: inherit !important;
            }
        }

        .custom-dropdown-menu:last-child {
            border-bottom: none !important
        }
    }
    
}