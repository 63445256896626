.document-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 69px;
    padding-top: 30px;
    padding-bottom: 30px;  
    background: #FFFFFF;
    border: 1px solid #DFE4EA;
    border-radius: 8px;

    .document-title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        text-align: center;
        height: 20px;
    }

    .document-desc {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #606060;
    }

    .file-upload {
        width: 127px;
        height: 46px;
        border: 1px solid #4379EB;
        border-radius: 100px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .file-upload-input {
            display: none;
        }

        .file-upload-label { 
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            text-align: center; 
            display: flex;
            align-items: center;
            text-align: center;
            color: #4379EB;
        }
    }

}