@import "../../../../../../../Assets/Scss/primaryColor";
.avatar {
    background: #FFFFFF;
    border: 2px solid #F5A623;
    width: 104px;
    height: 104px;
    padding: 7px;
    border-radius: 100%;

    img {
        width: 100%;
        height: 100%;
    }
}
.avatar-estate{
    background: $primary-estate;
    border: 2px solid $primary-estate;
    width: 104px;
    height: 104px;
    padding: 7px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 80%;
        height: 80%;
    }
}