@import "../../../../Assets/Scss/primaryColor";
.about{
    .about-head-tab{
        position:relative;
        width: 100%;
         
        .about-head-tab-inner{
            position:absolute;
            height:70px;
            margin-top:-30px;
            background: $white;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
            border-radius: 8px; 
           
            
            .tab-btn{
              background: #303030;
              border-radius: 8px;
              color: $white;
              font-size:.9rem;
              padding: 10px 30px;
              width: 167px;
              height: 48px;
              left: 404px;
              top: 722px;
              background: #303030;
              border-radius: 8px;
                
                &:focus{
                    border-color: rgba(224, 224, 224, 0.651);
                    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(206, 206, 206, 0.6);
                    outline: 0 none;
                  }
            }
        }

    }

    .about-content{
        padding: 150px 0;
       
        h2{
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            /* identical to box height */

            letter-spacing: -0.01em;

            /* Onyx */

            color: #303030;
        }
    }

   .vision-container{
           height: 100%;  
           background-color: $npf_dark_2;
           width: 100%;
        .ourVision-left{
            height: 604px; 
            width: 100%;
        }
        .ourVision-right-cont{
            background-color: $npf_dark_2;
            .ourVision-right{  
                background-color: $npf_dark_2;
                padding:200px 100px;
                color: $white;
                h2{
                    font-weight: 800;
                    font-size: 40px;
                    line-height: 48px; 
                    letter-spacing: -0.01em;
                }
            }
        }
    }


.core-values-container{
    padding:120px 0;
    .bg-1{
        //    background-image:url("../../../../Assets/Images/Group 3615 copy.png"); 
           background-size:cover;
           border-radius: 10px;
       }
       .bg-2{
        //    background-image:url("../../../../Assets/Images/Group 3615.png"); 
           background-size:cover;
           border-radius: 10px;
       }
       .bg-3{
        //    background-image:url("../../../../Assets/Images/Group 3617.png");
           background-size:cover;
           border-radius: 10px;
       }
       .bg-4{
        //    background-image:url("../../../../Assets/Images/Group 3614.png");
           background-size:cover;
           border-radius: 10px;
       }
       .bg-5{
        //    background-image:url("../../../../Assets/Images/Group 3613.png"); 
           background-size:cover;
           border-radius: 10px;
       }

    .card-small{
        width: 100%;
        height: 155px;  
        color: $white; 
        border-radius: 10px;

        h5{
            font-weight: 800;
            font-size: 20px;
            line-height: 24px;
        }
       
    }
    .card-hory{ 
        height: 322px;  
        border-radius: 10px; 
        color: $white;

        h5{
            font-weight: 800;
            font-size: 20px;
            line-height: 24px;
        }
       
    }

    .core-values-desc{
        padding:5px 20%;
        h2{
            font-weight: 800;
            font-size: 40px;
            line-height: 48px; 
            letter-spacing: -0.01em; 
            color: #303030;

        }
    }
}

}

@media(max-width:765px){
    .about-content{
        padding: 40px 0!important;
    }
    .about-head-tab-inner{ 
        width:95% !important; 
        .h-100 { 
            width: 100%!important;;
        }
        .tab-btn{ 
            font-size:.7rem!important;
            padding: 5px 10px !important;
            width: 87px !important;
            height: 38px!important; 
            } 
        .col-4{
            padding:5px !important;
        }    
        button.btn{
            padding:5px;
            font-size:.9rem!important;
        }
 
            

    } 

    .vision-container{ 
        .ourVision-left{
            height: 340px !important; 
        }

       .ourVision-right{
            padding:30px !important; 
        }
    }

     .core-values-container {
        padding: 10px 0 !important;
        .card-hory {
           margin-top:10px !important;
        }
        .core-values-desc {
            padding: 5px 10% !important;
        }

         .card-hory{ 
            height: 152px!important; 
         }
     }   
     .about-head-tab-inner{
            position:relative !important;
     }

      .ourVision-right-cont{ 
        .ourVision-right { 
            padding: 20px 50px!important; 
        }
        .row {
            margin-right:0px !important;
        }
     }

}
 
@media (min-width:766px){
    .about-head-tab-inner{ 
        width:60% !important; 
    }
}
@media(min-width:765px) and (max-width:1292px){

        h1{ 
            font-weight: 800;
            font-size: 2.7rem !important;
            line-height: 50px !important; 
        }


     .ourVision-right-cont{ 
        .ourVision-right { 
            padding: 20px 100px!important; 
        }
        .row {
            margin-right:0px !important;
        }
     }
        
}