.productSinglePageskeleton{
    position: relative;
    .section-header{
        h2{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            display: flex;
            align-items: center; 
            color: #333333;
        }
    }

  .product-element{

    h1{ 
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px; 
        display: flex;
        align-items: center; 
        color: #333333;
    }

    .desc{ 
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: .77rem;
        line-height: 18px; 
        display: flex;
        align-items: center; 
        color: #333333;
        img{
            width:12px
        }
    }
   


    .price{ 
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 29px; 
        color: #F2994A;
        margin-bottom: 10px;
    }

    .soldby{

    }

    hr{  
      border: 1px solid #cdd1d6;
    } 

}


.product-description{
    .nav-link{ 
        font-weight: 600;
        font-size: .9rem;
        line-height: 24px; 
        color: #2a69f0;

        &.active { 
            height: 60px !important;
            width: 50% !important; 
            background: #F2F2F2; 
            border-radius: 8px 8px 0px 0px;
            margin: 0px;
        }


            background: #F2F2F2;
            border: 1px solid #DFE4EA;
            border-radius: 8px 8px 0px 0px;
            margin: 0 6px 6px 6px;
            width: 48%; 
      
            &.reviewtab{ 
                span{
                    position: relative;
                }
                .review-count{
                    width: 12px;
                    height: 12px;
                    padding: 8px; 
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: .7rem;
                    border-radius: 50px; 
                    background: #F2994A;
                    position: absolute;
                    right:-30px;
                    top:0;
                    color:rgb(255, 255, 255);
                  }
            }
     
    } 

    .tab-content{ 
        /* Gray 6 */ 
        background: #F2F2F2;
        border: 1px solid #DFE4EA;
        border-radius: 0px 0px 8px 8px;
        padding: 30px;
        font-size: .83rem;
    }

    hr{ 
        border: 1px solid #c7c8c9;
    }

    .ads-big{
        width: 100%;
        height: 376px; 
        background: #EFF2F6; 
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ads-small{
        width: 100%;
        height: 256px; 
        background: #EFF2F6; 
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
}



 
    .carousel-control-container{ 
        width:80px !important; 
    
        .carousel-control-next{ 
            height: 35px;
            width: 35px;
            background-color: #DFE4EA !important;
            opacity: 1;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center; 
            
            .islarge{
                font-size: 1.3rem;  
                color: #333333;
                font-weight: 600 !important;
            } 
        } 
        
        .carousel-control-prev{ 
            height: 35px;
            width: 35px;
            background-color: #DFE4EA !important;
            opacity: 1;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center; 
            
            .islarge{
                font-size: 1.3rem;  
                color: #333333;
                font-weight: 600 !important;
            } 
        } 
    }
    .carousel-indicators-container{
        position: relative;
        margin-top: 50px;

        .carousel-indicators{
            position: absolute;
            left: 0;
            display: flex;
            flex-direction: row;
            
            button{
                flex: 20%;
                width: 100%;
                height: 1px !important;
                margin: 0;
                background: #a4a4a4 ;
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent; 
                &.active{
                    background: #333333 !important;
                    border-radius: 1px;
                    height: 4px;
                }
            }
        }
    }
}