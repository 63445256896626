.buyer-saved-list {
    .saved-list-item-count {
        display: flex;
        column-gap: 10px;
        align-items: center;
        .count {
            font-style: normal;
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 29px;
            color: #303030;
        }

        .count-modifier {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 29px;
            color: #303030;
        }
    }

    .sort-value-display {
        background: #EFF2F6;
        border-radius: 13.5px;
        padding: 2px 8px;
        margin-left: 8px;
        font-weight: 400;
        font-size: .8rem;
        line-height: 23px;
        color: #3378F3;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn-link{
        font-size: .99rem;
    }
 
}