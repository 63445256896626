.mobile-header{
    .logo{
        width: 150px
    }
    .fa-bars{
       font-size: 1.4rem; 
    }
    .nav-icon{
        width:20px;
        margin-right: 8px;
    } 

    ul li {
        display: inline;
    }

    .dropdown-toggle::after {
        display: none !important;
    }

    .dropdown{
        .avarter{ 
            width:22px; 
        }
        .nav-icon-sub{
        width:18px;
        margin-right: 8px; 
        }

        .dropdown-item{ 
            img{
              padding-right: 5px;
              width: 20px;
            }
            font-size: .8rem;
        }
    }

    .search { 
        width: 100%; 
        margin-top: 20px;
        .header-search-box {
            width: 100%; 
        }
    }


    .offcanvas{
        .offcanvas-title{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px; 
            display: flex;
            align-items: center; 
            color: #333333;
        }

        .accordion{
         .accordion-header{
            .accordion-button{
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;  
                color: #333333;   
                background-color: #ffffff;
                border: none !important;

                &:focus{ 
                  border: none !important;
                  box-shadow: none !important;
                }
            }
         }

         .accordion-item-links{
            padding: 13px 20px; 
            a{
                font-weight: 400;
                font-size: .95rem;
                line-height: 18px; 
                color: #333333;    
            }
         }

        .mega-menu-tab{
            .list-group{
                .list-group-item{ 
                    height: 55px; 
                    border-bottom: 1px solid #DFE4EA !important;
                    border-radius: 0px;
                    background-color: #eff2f6;
                    cursor: pointer; 

                    a{ 
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: .9rem;
                        line-height: 18px;  
                        color: #333333; 
                        flex: none;
                        order: 1;
                        flex-grow: 0;
                    }

                    .img{
                        width: 34px;
                        height: 34px; 
                        background: #D9D9D9;
                        border-radius: 8px; 
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                    }

                    .nav-icon{
                        width: 7px;
                    }
                }
            }
        }
      }
    }


    .cartMenu{
        position: relative;
         .cart-count{
           position:absolute;
           top: -1px;
           left: 20px; 
           background: #489bf4;
           padding: 2px;
           width:17px;
           height:17px;
           display: flex;
           justify-content: center;
           align-items: center;
           border-radius: 50px;
           color: rgb(255, 255, 255);
           font-weight: 700;
           font-size: .7rem;
     
         }
     }
   
} 