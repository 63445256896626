.enterpriseOrderDetails{

    .order-status{
        width: 147px;
        height: 28px; 
        background: rgba(0, 193, 43, 0.09);
        border-radius: 100px;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px; 
        display: flex;
        align-items: center;
        text-align: center; 
        color: #00C12B;
    }

    .enterprisePageTitle{
        h2{
            font-style: normal;
            font-weight: 400;
            font-size: 1.1rem !important;
            line-height: 24px; 
            color: #303030;

        }
    }
    .order-summary{ 

        background: #FFFFFF;
        border: 1px solid #DFE4EA !important;
        border-radius: 8px;

        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 24px; 
        color: #333333;
    }

    .order-time{
        font-weight: 400;
        font-size: 16px;
        line-height: 19px; 
        text-align: right; 
        color: #333333;
    }

    .card-custom-header {
        border-bottom: none !important;
        margin-top: 20px;
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
    }
    
    .orde-summary-total {
        border-top:  1px solid #E0E0E0;
        padding-top: 20px;
    }
    
    .price-breakdown {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
    }

    .price-total {
        font-weight: bold;
        font-size: 20px;
        line-height: 19px;
        color: #333333;
    }
    .buyer-order-details-navlink {
        background-color: #4379EB;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        padding: 8px 20px;
        font-size: .9rem;
        line-height: 18px;
        color:white;
        font-weight: 500;
    }

    .custom-card-footer {
        padding-top: 30px;

    }
}