@import "../../../Assets/Scss/primaryColor";
.buyerSidebar {
  background: $white-6; 
  padding: 15px; 
  border-right: 1px solid #E1E1E1;
  height: 100%;
  .nav-item {
    .nav-link {
      font-weight: 400;
      font-size: 0.9rem;
      color: #606060;

      .icon {
        width: 20px;
        height: 20px;
        opacity: 0.8;
      }
    }

    .active {
      background: $primary;
      border-radius: 10px;
      color: $white;
    }
  }
}
