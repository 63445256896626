@import "../../../../Assets/Scss/primaryColor";
.estate-managers-wraper {   
  position: relative;
  
  @media (min-width:1092px) {
    .col-25{ 
      flex: 0 0 auto;
      width: 20%;
    } 
    .col-85{ 
        flex: 0 0 auto;
        width: 80%; 
        height: 100vh;
        overflow-x: hidden;
      } 
  }


  @media (max-width:1092px) {
    .col-25{ 
      flex: 0 0 auto;
      width: 100%;
    } 
    .col-85{ 
        flex: 0 0 auto;
        width: 100%;
      } 
  }

  .dropdown-toggle::after {
    display: none !important; 
  }

  button{
    margin: 0 0;
  }
 
}

@media (min-width: 1900px) {
  .container-xxxxl {
   max-width: 1326px !important;
  }

 .container-xxxxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
  }
}

._1szgW, ._1hnkw, ._1OmM8 {
  background: #F5A623 !important;
  border: 1px solid #F5A623 !important;
  color: #fff;
}
._3t0Wm, ._2rcQQ, ._3PCpd{
  color: #F5A623 !important;
}
