.quote-request {
    .card {
    height: 100%;
  }

  position: relative;
  .see-link {
    font-size: 0.8rem;
    color: #34BA96;
  }
  .spinner-border {
    width: 5rem;
    height: 5rem;
    color: rgb(10, 10, 10);
  }
  .w--75 {
    width: 40px;
  }
  .recent-prod-title {
    font-weight: 500;
    font-size: 0.87rem;
    color: #606060;
    line-height: 150%;
  }
  .recent-prod-price {
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 150%;
    color: #ff0000;
  }

  .line {
    background-color: rgba(0, 0, 0, 0.377);
  }
  .title{
    font-size: .9rem;
    font-weight: 500;
  }
}
