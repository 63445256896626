.expenses{ 
    margin-top: 20px;

    .card{
        background: #FFFFFF; 
        border: 1px solid #DFE4EA;
        border-radius: 8px;

        .card-header{
            font-style: normal;
            font-weight: 500;
            font-size: .8rem;
            line-height: 150%; 
            color: #000000;

        }

        .no-product{
            height: 223px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%; 
            color: #606060;

        }
    }
}