@import "./../../../Assets/Scss/primaryColor";
.enterprise-navbar{
        padding: 20px 0; 
        
        .profile-label{
            height: 24px;
            width: 24px;
            background-color: $primary-estate;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            font-weight: 600;
            font-size: .8rem;
            line-height: 10px;

            @media (max-width: 1402px) {
                position: absolute;
                left: -10px;
            }
        }
        .profile-location{
            font-size: .6rem;
            line-height: 10px;
        }


        .navbar-brand{
            .logo{
                width: 100px;
            }
        }

       

        .nav-item{  
            font-style: normal;
            font-weight: 400;
            font-size: .9rem;
            line-height: 18px; 
            display: flex;
            align-items: center; 
            color: #333333;
            padding: 0 10px;
            background-color: #fefeff;
            border-radius: 5px;

           
           
            .nav-link{
                &.nav-responsive{ 
                    @media (min-width: 990px) and (max-width: 1398px) { 
                        font-size: .7rem;
                        text-align: center;
                        display: flex;
                        width:77px;
                        text-overflow: ellipsis;
                        white-space:nowrap; 
                        align-items: center; 
                            img{ 
                                display: block;
                            }
                    }  
                } 
            }

            .dropdown-toggle{
                font-style: normal;
                font-weight: 400;
                font-size: .9rem;
                text-decoration: none;
                line-height: 18px; 
                display: flex;
                align-items: center; 
                color: #333333; 

                @media (min-width: 990px) and (max-width: 1398px) { 
                    font-size: .7rem; 
                    text-overflow: ellipsis;
                    white-space:nowrap; 
                    align-items: center;  
                }

                .avarter{
                    width:20px;
                    border-radius: 50px;
                    // border: 2px solid rgba(0, 0, 0, 0.388);
                    // padding-right: 20px;
                } 
                
            }
            .dropdown-menu{
                background: #FFFFFF;
                border: 1px solid #DFE4EA;
                border-radius: 8px;

               .dropdown-item{ 
                    // border-top: 1px solid #DFE4EA;
                    padding-top: 10px;
                    padding-bottom: 10px;  
                    font-weight: 400;
                    font-size: .8rem;
                    line-height: 18px; 
                    display: flex;
                    align-items: center; 
                    color: #333333;  

                    &:focus{
                        background-color:   #DFE4EA                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     #333333;
                    }

                    img{
                        width: 15px;
                        margin-right: 10px;
                    }
               }
            } 
            .nav-icon{
                width:18px;
                margin-right: 8px;
            }

            // .dropdown [data-bs-toggle="dropdown"]:after {
            //     content: "\005E" !important; 
            //   }
        }
    }
 