.productImage{
    position: relative;

    @media (min-width:600px) {
        margin-bottom: 0px;
    } 
    @media (max-width:600px) {
        margin-bottom: 140px;
    } 

   
 
    .carousel-indicators-container{
        position: relative;
        margin-top: 18px !important;
       
        .carousel-indicators{
            position: absolute;
            left: 0;
            bottom: -108px;
            display: flex;
            flex-direction: row;  
            height: fit-content;
            
            div{
                // flex: 10%;
                width: 140px;   
                margin: 0 4px!important;
                background: #a4a4a4 ;
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent; 
                border-radius: 8px;
                height: 90px !important; 
                margin-left: auto auto;

                margin-bottom: -60px; 
                &.active{
                    background: rgba(51, 51, 51, 0) !important;
                    border-radius: 1px;
                    height: 4px;
                }
                img{
                    border-radius: 8px;
                    height: 90px;
                }
            }
        }

        
    }

    .carousel-item {
        position: relative; 
        max-height: 428px; 
        // margin:0 auto; 
        // display: flex;
        justify-content: center;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
        border-radius: 8px;
        padding: 10px 0;

        @media (max-width:600px) { 
            img{
                height: 318px;
                width: auto;
            }
        }
        @media (min-width:600px) { 
            img{
                height: 418px;
                width: auto;
            }
        }

        // .enlarge{
        //     margin-top: -900px;
        // }
      }

    .carousel-control-container{
        height: inherit;  

        @media (max-width:600px) { 
            width: 500px;
            overflow: scroll;
        }
        .carousel-control-next{ 
            height: 35px;
            width: 35px;
            background-color: #DFE4EA !important;
            opacity: 1;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center; 
            margin-top: auto;
            margin-bottom: auto; 
            margin-right: 20px!important;
            
            .islarge{
                font-size: 1.3rem;  
                color: #333333;
                font-weight: 600 !important;
            } 
        } 

            
        .carousel-control-prev{ 
            height: 35px;
            width: 35px;
            background-color: #DFE4EA !important;
            opacity: 1;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;  
            margin-top: auto;
            margin-bottom: auto; 
            margin-left: 20px!important;

            .islarge{
                font-size: 1.3rem;  
                color: #333333;
                font-weight: 600 !important;
            } 
        } 
    } 
}