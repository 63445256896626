.buyer-plp-stepper  {
    .stepper-tracker {
        background: rgba(242, 153, 74, 0.09);
        border-radius: 100px;
        box-sizing: border-box;
        color: #F2994A;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 30px;
        width: fit-content !important;
        max-width: 100px !important;
        padding: 5px 14px;
    }

    .steps {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        align-items: center;
    }
}