.home-testimony{
    padding: 50px 0;
    
    .testimony{
        .card{
            height: 100%;
            
            @media (min-width: 600px) {
              border: none !important;
            }
            @media (max-width: 600px) {
              padding-bottom: 9px !important; 
              font-size: .9rem; 
              border: 0.545895px solid #DEDEDE;
              margin-top: 20px;
            }

            .card-header{
              @media (max-width: 600px) {
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 17px; 
                color: #000000;
              }
            }


            .card-body{
              
              @media (max-width: 600px) {
                padding-bottom: 9px !important; 
                font-size: .9rem;
                color: #828282;
              }
                
            } 
            .avarter {
                width: 50px;
                height: 50px;
            }
        }
    }
}