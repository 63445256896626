.seller-subpage-title{
    p{
        font-family: Lato;
font-size: 20px;
font-weight: 600;
line-height: 24px;

        font-style: normal;
        color: #333333;
        margin-left: 13px;
        margin-top: 15px;
    }


    .backArrow {
        background: #EFF2F7;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        display: flex;
        margin-left: 0;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    // .custom-breadcumbs {
    //     font-weight: 500;
    //     font-size: 14px;
    //     line-height: 150%;
    //     color: #868484 !important;
    // }

    // .custom-breadcumbs-active {
    //     font-style: normal;
    //     font-weight: 500;
    //     font-size: 14px;
    //     line-height: 150%;
    //     color: #3378F3;

    // }
}