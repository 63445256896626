
   .similarProduct{
    .section-header{
        h2{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            line-height: 38px;
            display: flex;
            align-items: center; 
            color: #333333;
           
            @media (max-width:600px) { 
               font-size: 1.5rem !important;
            }
            @media (min-width:600px) {
               font-size: 2rem !important; 
            }

        }
    } 

    .bestdeals-row{
        display: flex;
        flex-direction: row;

        .col-5{
            flex: 20%;
            margin: 10px; 

            .product-card{ 
                background: #FFFFFF; 
                border: 1px solid #E0E0E0 !important;
                border-radius: 8px; 
                position: relative;

                &:hover{
                    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
                }
              
                .brand-logo{
                    width:40px;
                }

                .wish-icon{
                    width:20px
                }

                .product-image{
                    height:160px;
                }

                .col-12{
                    position: relative;
    
                    .promo{
                        position: absolute;
                        top:0 ;
                        width: 60px;
                        height: 25px;
                        left: 0; 
                        background: #FF0000;
                        border-radius: 0px 24px 24px 0px;
                        margin-top: 10px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: .8rem;
                        line-height: 120%; 
                        color: #FFFFFF;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                  }

                  .product-title{ 
                    font-style: normal;
                    font-weight: 500;
                    font-size: .9rem;
                    line-height: 150%; 
                    color: #333333;
                    height: 100px !important;
                  }

                  .price{
                    display: flex;
                    flex-direction: row;

                    .old-price{
                        flex: 60%; 
                        font-weight: 400;
                        font-size: .8rem;
                        line-height: 17px; 
                        text-decoration-line: line-through; 
                        color: #717171;   
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                    }
                    .new-price{
                        flex: 60%;
                        font-weight: 700;
                        font-size: .9rem;
                        line-height: 19px; 
                        color: #F2994A; 
                        flex: none;
                        order: 1;
                        flex-grow: 0;
                    }
                  }

                  .company-name{ 
                    font-style: normal;
                    font-weight: 700;
                    font-size:.8rem;
                    line-height: 17px; 
                    color: #828282;
                }

                .rating{
                    span{
                        font-size: .7rem !important; 
                        color: #F2994A !important;
                    }
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: .8rem;
                    line-height: 17px; 
                    color: #828282;
                }

                .location{
                    span{
                        font-size: .7rem !important;
                    }
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: .8rem;
                    line-height: 17px; 
                    color: #828282;
                }
            }
        }
    }
}