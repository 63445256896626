@import "../../../../../../../Assets/Scss/primaryColor";

.EstateSubscriptionDetails{
    .card{
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        
        .card-image{
            position: relative;
            height: 241px;
            
            img{
                border-radius: 10px; 
                height: 241px;
            }

            .featured-text{
                width: 65px;
                height: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px, 10px, 5px, 10px;
                border-radius: 20px; 
                background: #F0F3FC;  
                font-size: .6rem;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0px;
                text-align: left;
                color: #3A0CA3; 
                position: absolute;
                bottom: 20px;
                right: 15px;

            }
        }

        .card-body{
            .price{  
                font-size: 1.7rem;
                font-weight: 700;
                line-height: 22px;
                letter-spacing: 0px;
                text-align: left;
                color: #3A0CA3; 

                small{
                    font-size: .6rem;
                    font-weight: 400;
                }
            }

            .units{ 
                padding: 5px, 8px, 5px, 8px;
                border-radius: 20px;  
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0px;
                text-align: left;
                color:  #797A7C;
                display: flex;
                justify-content: center;
                align-items: center;
            }


            .project-title{ 
                font-size: .9rem;
                font-weight: 600;
                line-height: 24px; 
                text-align: left;
                color: #000000; 
            }
            .project-description{ 
                font-size: .83rem;
                font-weight: 400;
                line-height: 22px; 
                color: #000000; 
            }

            .project-location{ 
                font-size: .7rem;
                font-weight: 400;
                line-height: 22px; 
                color: #797A7C;

            }
        }

        a{
            text-decoration: none; 
        }
        .btn{
            background-color: #ffffff; 
            height: 43px; 
            border-radius: 100px;   
            color: $white;
            font-size: .8rem;  
            color: #3A0CA3;
            border: 1px solid #3A0CA3

        }
    }

    .details{
        ul{
            list-style: none;
            padding-left: 0px !important;
            li{
                span{ 
                    font-size: .83rem;
                    font-weight: 600; 
                    letter-spacing: 0px;
                    text-align: left; 
                    color: #303132;
                    margin-right: 20px;

                }

                font-size: .83rem;
                color: #797A7C;
                line-height: 30px;

            }
        }
    }

    .terms{
        input{
            margin-right: 7px;
            accent-color: $primary-estate;
        }
        height: 20px;
        display: flex;
        align-items: center;
        font-size: .9rem;

        a{
            color: $primary-estate;
        }
    }
    .amount-label{ 
        font-size: .9rem;
        font-weight: 400; 
        letter-spacing: 0px;
        text-align: left;
        color: #000000; 
    }
    .amount{  
        font-size: 2rem;
        font-weight: 700; 
        letter-spacing: 0px;
        text-align: left;
        color: #3A0CA3; 
    }
    .alert{
        background: linear-gradient(0deg, #FCEAEA, #FCEAEA),
           linear-gradient(0deg, #E73F3F, #E73F3F);  
        font-size: 12px;
        font-weight: 400; 
        letter-spacing: 0px;
        text-align: left;
        border: 1px solid #E73F3F

    }

    .btn-pay-now{
        width: 100%;
        height: 70px; 
        border-radius: 100px; 
        background-color: $primary-estate !important; 
        color:white !important;  
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;

    }

    .walletInformation{
        .btn{ 
          background-color: $primary-estate; 
        }
    }


    .additiontal-details{
        ul{
           list-style: none;
        }
    }


 
    
    input[type="range"] {
        /* removing default appearance */
        -webkit-appearance: none;
        appearance: none; 
        /* creating a custom design */
        width: 100%;
        cursor: pointer;
        outline: none;
        /*  slider progress trick  */
        overflow: hidden;
        border-radius: 16px;
      }
      
      /* Track: webkit browsers */
      input[type="range"]::-webkit-slider-runnable-track {
        height: 15px;
        background: #ccc;
        border-radius: 16px;
      }
      
      /* Track: Mozilla Firefox */
      input[type="range"]::-moz-range-track {
        height: 9px;
        background: #ccc;
        border-radius: 16px;
      }
      
      /* Thumb: webkit */
      input[type="range"]::-webkit-slider-thumb {
        /* removing default appearance */
        -webkit-appearance: none;
        appearance: none; 
        /* creating a custom design */
        height: 15px;
        width: 15px;
        background-color: #fff;
        border-radius: 50%;
        border: 2px solid $primary-estate;
        /*  slider progress trick  */
        box-shadow: -407px 0 0 400px $primary-estate;
      }
      
      
      /* Thumb: Firefox */
      input[type="range"]::-moz-range-thumb {
        height: 15px;
        width: 15px;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid $primary-estate;
        /*  slider progress trick  */
        box-shadow: -407px 0 0 400px $primary-estate;
      }

      .form-range {
        width: 100%;
        height: 0.9rem;
      }
      
   
  
}