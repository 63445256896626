.bestdeals{
    position: relative;
    .section-header{
        h2{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            line-height: 38px;
            display: flex;
            align-items: center; 
            color: #333333;
           
            @media (max-width:600px) { 
               font-size: 1.5rem;
            }
            @media (min-width:600px) {
               font-size: 2rem; 
            }

        }
    } 
           

    .product-card{ 
        background: #FFFFFF; 
        border: 1px solid #E0E0E0 !important;
        border-radius: 8px; 
        position: relative;

        &:hover{
            box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
        }
        .brand-logo-cont{
            height: 30px;  
            overflow: hidden;
            .brand-logo{
                width:30px;
            } 
        }
        .product-image-outter{
            display: flex;
            justify-content: center; 
            padding-bottom: 20px;
            padding-top: 30px;
            
            .product-image-container{  
                height:160px;  
                width:190px;  
                overflow: hidden;
                display: flex;
                justify-content: center; 
                .product-image{
                    height:160px;  
                    cursor: pointer;
                }
            }
        }

        .col-12{
            position: relative;

            .promo{
                position: absolute;
                top:0 ;
                width: 60px;
                height: 25px;
                left: 0; 
                background: #FF0000;
                border-radius: 0px 24px 24px 0px;
                margin-top: 10px;
                font-style: normal;
                font-weight: 600;
                font-size: .8rem;
                line-height: 120%; 
                color: #FFFFFF;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }
          }

          .product-title{ 
            font-style: normal;
            font-weight: 500;
            font-size: .9rem;
            line-height: 150%; 
            color: #333333;
          }

          .price{
            display: flex;
            flex-direction: row;

            .old-price{
                flex: 60%; 
                font-weight: 400;
                font-size: .8rem;
                line-height: 17px; 
                text-decoration-line: line-through; 
                color: #717171;   
                flex: none;
                order: 0;
                flex-grow: 0;
            }
            .new-price{
                flex: 60%;
                font-weight: 700;
                font-size: .9rem;
                line-height: 19px; 
                color: #F2994A; 
                flex: none;
                order: 1;
                flex-grow: 0;
            }
          }

          .company-name{ 
            font-style: normal;
            font-weight: 700;
            font-size:.8rem;
            line-height: 17px; 
            color: #828282;
        }

        .rating{
            span{
                font-size: .7rem !important; 
                color: #F2994A !important;
            }
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: .8rem;
            line-height: 17px; 
            color: #828282;
        }

        .location{
            span{
                font-size: .7rem !important;
            }
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: .8rem;
            line-height: 17px; 
            color: #828282;
        }
    } 
    .carousel-control-container{ 
        width:70px !important; 
    
        .carousel-control-next{ 
            height: 30px;
            width: 30px;
            background-color: #DFE4EA !important;
            opacity: 1;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center; 
            
            .d-icon{
                font-size: 1.3rem !important;  
                color: #333333;
                font-weight: 300 !important; 
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
            .islarge{
                font-size: 1.3rem;  
                color: #333333;
                font-weight: 600 !important;
            } 
        } 
        
        .carousel-control-prev{ 
            height: 30px;
            width: 30px;
            background-color: #DFE4EA !important;
            opacity: 1;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center; 
             
            .d-icon{
                font-size: 1.3rem !important;  
                color: #333333;
                font-weight: 300 !important; 
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
            .islarge{
                font-size: 1.3rem;  
                color: #333333;
                font-weight: 600 !important;
            } 
        } 
    }
    .carousel-indicators-container{
        position: relative;
        margin-top: 50px;

        .carousel-indicators{
            position: absolute;
            left: 0;
            display: flex;
            flex-direction: row;
            
            button{
                flex: 20%;
                width: 100%;
                height: 1px !important;
                margin: 0;
                background: #a4a4a4 ;
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent; 
                &.active{
                    background: #333333 !important;
                    border-radius: 1px;
                    height: 4px;
                }
            }
        }
    }

 



.swiper {
    width: 100%;
    // height: 100%;
    padding-bottom: 60px; 
  }
  
  .swiper-slide {    
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
//   .swiper-slide img {
//     display: block;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
  
  .swiper-slide {
    width: 240px;
  }
  
  .swiper-slide:nth-child(2n) {
    width: 240px;
  }
  
  .swiper-slide:nth-child(3n) {
    width: 240px;
  }
  .swiper-pagination{
    margin-bottom: -20px;
    padding-bottom: 10px;

    display: flex;
    flex-direction: row;
  }

  .swiper-pagination-bullet { 
    font-size: 0px;
    color: #a4a4a4; 
    flex: 20%;
    width: 100%; 

    height: 1px !important; 
    background: #a4a4a4 ;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent; 
  }
  
  .swiper-pagination-bullet-active {
    color: #fff; 
    background: #333333 !important;
    border-radius: 1px;
    height: 4px;
  }
  
}