.builder360{

  .showcase-title{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px; 
    color: #333333;
    margin-bottom: 50px;

  }

  .mid-heading{
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 38px; 
    color: #333333;
    margin-top: 30px;

    @media (max-width:800px) {
      &.mid-heading-mb{
        text-align: center;
        font-size: 1.3rem; 
      }
    }

    @media (max-width:800px) {
      text-align: center;
    }
  }

  .section-img{
    transition: all .5s ease-in-out;
    border-radius: 10px;

    @media (max-width:800px) { 
      width: 100%;
      margin-top: 20px;
    }

     @media (min-width:800px) {
      width: 90%;
      &:hover{
        transform: scale(1.1);
      }
    }
  
  }

  .row-content{
    font-style: normal;
    font-weight: 400;
    font-size: .9rem;
    line-height: 29px;
    display: flex;
    align-items: center; 
    width: 90%;

    color: #333333; 
    flex: none;
    order: 1;
    flex-grow: 0;

    @media (max-width:800px) {
      text-align: center;
      line-height: 24px;
    }
  }

  .showcase{
    margin-top: 150px;
    margin-bottom: 150px;
    .card{
      background-color: transparent;
      border: none !important;
      margin-top: 20px;
  

      .card-header{
        height: 72px; 
        background: #ffffff;
        border-radius: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 29px;   
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        box-shadow: 0 .1rem .3rem rgba(73, 73, 73, 0.054) !important;
       
        .icon-wraper{

          background: rgba(245, 166, 35, 0.15);
          border-radius: 24px;  
          padding-left: 10px;
          padding-right: 10px;
          padding-bottom: 5px;
           
          img{
            width: 20px; 
          }
       }

        &.header-middle{
          border: 1px solid #E1DFD3;
          background: #E1DFD3;
        }

        &.header-last{
          border: 1px solid #E0E0E0;
          background: #E9D596;
        }
 
      }
      .card-body{
        background: #E0E0E0; 
        border: 1px solid #f3f1f100 !important;
        border-radius: 5px; 

        .card-inner-header{

            height: 72px; 
            background: #000000;
            border-radius: 8px; 
            line-height: 20px;  
            display: flex; 
            align-items: center;
            justify-content: center;
            color: #d3d3d3;  
            box-shadow: 0 .1rem .3rem rgba(73, 73, 73, 0.054) !important; 
            font-style: normal;
            font-weight: 700;
            font-size: 1rem;
            line-height: 128%;  
            text-transform: uppercase; 
            color: #FFFFFF;
        }
        ul{ 
          border: none !important;
          li{
            border-right: none !important;
            border-left: none !important;
            border-top: 1px solid #e0e0e000; 
            border-bottom: 1px solid #e0e0e0a7; 
            text-align: center;
            background: #F4F4F4;
            font-size: 1rem;
            padding: 15px 0;
            height: 102px; 
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .list-group-item{
          background-color: #ffffff;
          padding-top: 37px;
          padding-bottom: 37px;

          .item-value{
            font-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 29px; 
            display: flex;
            align-items: center; 
            text-align: center;
            justify-content: center;
            color: #000000; 
            flex: none;
            order: 0;
            flex-grow: 0;
          }

          .item-title{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 198%; 
            display: flex;
            align-items: center; 
            justify-content: center;
            color: #333333; 
            flex: none;
            order: 0;
            flex-grow: 0;
          }
        }
      }
      .card-footer{
         background-color: rgb(253, 253, 253);
         padding-bottom: 20px;
         display: flex;
         justify-content: center;
         align-items: center;
         border: 1px solid #E0E0E0;
         border-radius: 5px;
        //  height: 180px;
         box-shadow: 0 .1rem .3rem rgba(73, 73, 73, 0.054) !important;

        .footer-title{
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          line-height: 29px;  
          color: #333333;
          text-align: center;
        }

        .text{
          font-style: normal;
          font-weight: 500;
          font-size: .89rem;
          line-height: 150%; 
          display: flex;
          align-items: center;
          text-align: center; 
          color: #828282;
          text-align: center;
          margin-top: 10px;
          padding: 0 30px;
        }
      }
    }
  }

  .btn-360-getstarted{
    width: 224px;
    height: 54px; 
    background: #3378F3;
    border: 1px solid #DFE4EA;
    border-radius: 100px;
    margin-top: 90px;
  }

  .bg-newsletter{
    background: #EFF2F6; 
    padding-top: 50px;
    padding-bottom: 50px;

    .form-control{ 
      background: #FFFFFF;
      border: 1px solid #DFE4EA;
      border-radius: 100px;
    }
  }

  
  .swiper {
    width: 100%; 
    padding-bottom: 60px; 
  }
    
  .swiper-pagination{
    margin-bottom: -20px;
    padding-bottom: 10px;

    display: flex;
    flex-direction: row;
  }

  .swiper-pagination-bullet { 
    font-size: 0px;
    color: #a4a4a4; 
    flex: 20%;
    width: 100%; 

    height: 1px !important; 
    background: #a4a4a4 ;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent; 
  }
  
  .swiper-pagination-bullet-active {
    color: #fff; 
    background: #333333 !important;
    border-radius: 1px;
    height: 4px;
  }







.swiper {
  width: 100%;
  // height: 100%;
  padding-bottom: 60px; 
}

.swiper-slide {    
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 340px !important;
  
}

//   .swiper-slide img {
//     display: block;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }

.swiper-slide {
  width: 240px !important;
}

.swiper-slide:nth-child(2n) {
  width: 240px !important;
}

.swiper-slide:nth-child(3n) {
  width: 240px;
}
.swiper-pagination{
  margin-bottom: -20px;
  padding-bottom: 10px;

  display: flex;
  flex-direction: row;
}

.swiper-pagination-bullet { 
  font-size: 0px;
  color: #a4a4a4; 
  flex: 20%;
  width: 100%; 

  height: 1px !important; 
  background: #a4a4a4 ;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent; 
}

.swiper-pagination-bullet-active {
  color: #fff; 
  background: #333333 !important;
  border-radius: 1px;
  height: 4px;
}
}