 
.buyer-my-account {

    .my-account-outlet {
        margin-top: 17px;
    }

    @media (max-width:500px) {
        padding-left: 10px;
    }
}