.cartItem{
    
   .cart-item-list{
        margin-top: 30px;
        position: relative; 
    }


    .warehouse{
        .card{
            border: 1px solid #f2f1f1 !important; 
            background: #F2F2F2;
            border: 1px solid #DFE4EA;
            border-radius: 12px;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 20px;
            margin-bottom: 20px;

            .card-body{
                border-radius: 12px; 
            }
        }
        background-color: aliceblue;

        @media (max-width:700px) {
            width: 100%;
        }
        @media (min-width:700px) { 
            width: 100%;
        }
        label{
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px; 
            color: #333333;

        }
        
        .form-control{
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 100px;
            margin-bottom: 10px;
            height: 40px;
        }
    }
}