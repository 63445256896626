.buyerOrderTracking{ 
    .confirm-delivery-btn{
        background-color: #4379EB;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        padding: 8px 20px;
        font-size: .9rem;
        line-height: 18px;
        color:white;
        font-weight: 500; 
    }

    .dispute-btn{  
        border-radius: 100px;
        padding: 8px 20px;
        font-size: .9rem;
        line-height: 18px; 
        font-weight: 500; 
    }
    .btn-row{
        @media (max-width:400px) {
            display:flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}