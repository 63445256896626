.paymentOption{
    .wallet{
        .card{
            @media (max-width:600px) {
                width:100%
            }
        }
    }

    .btn{
        &.active{ 
            background: #4379EB;
            color:rgb(255, 255, 255);
            border: 1px solid #4379EB;
        }
    }

    .payment-unauth{ 
        font-style: normal;
        font-weight: 400;
        font-size: .8rem;
        line-height: 20px;  
        color: #333333;
        margin-top: 17px;
    }
}

.regModal{ 
    background: #FFFFFF;
    border-radius: 28px;
    .modal-title{
        font-size: 1.1rem;
        font-weight: 700; 
        line-height: 32px;  
        color: #333333;

    }
    .btn-close{
        font-size: .6rem;
        border: 1px solid rgb(66, 66, 66);
        border-radius: 28px;
        padding: 5px;
        margin-right: 15px !important;
    }

 
}
 