.enterprisePLPMainPage {
    .plp-apply-btn {
        background: #F5A623;
        border: 1px solid #DFE4EA;
        border-radius: 100px;  
        height: 36px; 
        font-size: .9rem; 
        font-style: normal;
        font-weight: 400; 
        line-height: 18px;  
        text-align: center; 
        color: #FFFFFF;

        &:active{
            background: #F5A623;
            border: 1px solid #DFE4EA;
            color: #FFFFFF;

        }
    }   

    .history {
        span{
            color: #F5A623 !important;
        }
    }
 
           

    .noPLP{
        height: 250px;
        width: 100%;
        background: #EFF2F6; 
        border: 1px solid #DFE4EA;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: .9rem;
        line-height: 19px; 
        color: #000000;

    }
}