.productFilters {
    margin-top: 200px; 

    @media (max-width: 600px) { 
        width: 100%; 
      } 

      @media (min-width: 600px) { 
        width: 436px; 
      } 

    // .modal-content {
    background: #FFFFFF;
    border: 1px solid #DFE4EA;
    border-radius: 28px;
    //   }

    .modal-content{
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 28px;
    }
    .form-control{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        height: 50px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px; 
        font-feature-settings: 'liga' off; 
        color: #828282;

      

        &:focus{
            outline: none !important;
            box-shadow: none !important;
        }
    }

    select{
        font-size: .8rem !important;
        option{ 
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: .8rem !important;
            line-height: 18px; 
            font-feature-settings: 'liga' off; 
            color: #828282;
        }

    }

    .apply{  
        width: 100%;
        height: 56px; 
        background: #3378F3; 
        color: #DFE4EA;
        font-weight: 800;
        box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
        border-radius: 100px;
    }

    .apply-outline{ 
        width: 100%;
        height: 56px; 
        font-weight: 800;
        color: #3378F3;
        background: #3379f300; 
        box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
        border-radius: 100px;

    }
   
   .btn-close {
        height: 15px;
        width: 15px; 
        border: 1px solid grey;
        border-radius: 50px; 
        font-size: .7rem !important;
    } 
 }