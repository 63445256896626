.avatar {
    background: #FFFFFF;
    border: none; 

    @media (min-width: 700px) { 
        width: 104px;
        height: 104px;
    }

     @media (max-width: 700px) { 
        width: 74px;
        height: 74px;
    }

    img {
        width: 100%;
        height: 100%;
    }
}