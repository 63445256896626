.heroSection{
    @media (max-width:800px) {
        text-align: center;
    }

    .title{
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 58px; 
        letter-spacing: -0.01em; 
        color: #000000;

        @media (max-width:800px) {
            text-align: center;
        }
    }
    .content{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%; 
        color: #828282;
        margin-top: 35px;
        @media (max-width:800px) {
            text-align: center;
        }
    }
    .btn-primary{
        background: #3378F3;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        width: 204px;
        height: 44px;
        margin-top: 35px;
    }

    .b360image{
      width: 90%;
      @media (max-width:800px) {
        margin-top: 20px;
    }
    }
}