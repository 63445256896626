@import "../../../../../../../Assets/Scss/primaryColor";
.enterpriseSettings{
    .security-btn-primary{ 
        height: 44px; 
        background: #F5A623;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        color: #ffffff;
        font-size: 1rem;
    }

    .security-btn-primary-estate{
        height: 44px; 
        background: $primary-estate;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        color: #ffffff;
        font-size: 1rem;
    }

    .form-control{
        border-radius: 100px;
        border: 1px solid #DFE4EA;
        &:focus{
            border: 1px solid #DFE4EA; 
        }
    }

    label{ 
        font-style: normal;
        font-weight: 400;
        font-size: .8rem; 
        color: #333333;
        padding-bottom: 10px;
    }

    .card{
        border: 1px solid rgb(230, 230, 230);
        margin-top: 20px;
    }
}