.order-payment-method {
    border-top: 1px solid #E0E0E0 !important;
    padding:32px 0px;
    
    p {
        margin:0px;
        padding:0px
    }

    .list-item-title {
        color:#333333;
        font-size: 16px;
        line-height: 19px;
        font-weight: 700;
        margin-bottom: 20px;
    }
}