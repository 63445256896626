.business-basic-info{
    margin-top: 2rem;
  .form-group {
    background: #ffffff;
    height: 83px;
    width: 100%;
    border-radius: 100px;
    label {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
    }
    .form-control {
      height: 56px;
      padding: 14px 24px;
      border: 1px solid #dfe4ea;
      width: 100%;
      background-color: #dfe4ea;
      border-radius: 100px;
    }
    .submit{
        background-color: #ffffff;
        border: 1px solid #34BA96;
        color: #34BA96;
    }
  }
}
