.businessProfileUpdate{ 

   .form-control{
        background: #EFF2F6;
        border: 1px solid #DFE4EA;
        border-radius: 20px; 
    }

    textarea{
        font-size: .8rem;
        &:focus{
           box-shadow: 1px 1px 1px #DFE4EA;
        } 
        &::placeholder{
            font-size: .7rem;
        }
    }

    .btn{
        width: 100%;
        background: #FFFFFF;  
        border: 1px solid #34BA96;
        border-radius: 100px;
        color:#34BA96;
        margin-top: 20px; 
        font-style: normal;
        font-weight: 600;
        font-size: .8rem;
        line-height: 30px;
    }
 }
    