@import "../../../../Assets/Scss/primaryColor";
.emsc {
  .enterprise-logo{
    width: 160px;
  }

  .nav-item { 
    height: 38px;
    background-color: #fefeff;
    border-radius: 5px;

    .nav-link {
      font-weight: 400;
      font-size: 0.82rem;
      color: #606060;
      min-height: 38px;
      min-width:200px;
      white-space: nowrap;

      svg{
        width:17px;
        path{
          fill: rgb(253, 253, 253) !important; 
        } 
      }
      .bf-icon{ 
        path{
          fill: rgb(50, 50, 50) !important; 

          // fill:none !important
        } 
      }
      .w-22{
        width:17px; 
      }
      .icon {
        width: 20px;
        height: 20px;
        opacity: 0.8;
      }

      .d-arrowhead-right{
        padding-top: 2px;
        color: rgb(255, 255, 255);
      }

      &.logout-active{
        background: rgba(228, 90, 90, 0.09);
        border-radius: 10px;

        span{ 
          font-style: normal;
          font-weight: 700;  
          color: #E45A5A;
        }
      }

      .icons{
        width: 19px;
        margin-right: 15px;
      }
    }

    .active { 
      border-radius: 10px;
      color: $primary-estate !important;
      background-color: #ffffff !important;
        .icons{
          fill: #d50000 !important; 
        }
        svg{
          width:17px;
          path{
            fill: rgb(255, 255, 255) !important; 
          }
        }
        .bf-icon{ 
          path{
            fill: rgb(255, 255, 255) !important; 
          } 
        }
        .d-arrowhead-right{
          padding-top: 2px;
          color: rgb(255, 255, 255);
        }
    }
  }

  .message-container{
    height: 280px; 
    padding-bottom: 50px;
    .message{
      margin-top: 50px;
      background: linear-gradient(79.03deg, #000000 14.73%, rgba(0, 0, 0, 0.76) 98.42%);
      border: 1px solid #DFE4EA;
      border-radius: 8px;
      padding: 20px; 
      text-align: center; 
      position: relative; 
      
      .icon{
        width: 100%; 
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:-50px;

        .icon-wrapper{
          width:60px;
          height:60px;
          background: #EDEDFD;
          border-radius: 50px;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;

          .icons{
            width: 20px;
          }
        }
      }

      .heading{
        font-style: normal;
        font-weight: 600;
        font-size: 1.1rem;
        line-height: 150%; 
        color: #FFFFFF;
        margin-top: 20px;
      }

      .desc{  
        font-weight: 400;
        font-size: 14px;
        line-height: 150%; 
        text-align: center; 
        color: #DFE4EA;
      }

      .btn{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;  
        color: $white;  
        border-radius: 50px;
        margin-top: 30px;
        border: 1px solid $white;
      }
    }
  }
}
 