  .container-variant {
    width: 1030px;
    border: 1px solid lightgray;
    padding: 20px;
    .sub-section {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .sec-b{
        width: 44%;
      }
      .trash{
        width: 4%;
        margin-right: 2rem;
      }
      .form-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-top: 15px;

        .deleteSpan{
          cursor: pointer;
        }
        
       .var{
        background-color: #dfe4ea;
        width:50px;
        height: 30px;
        
       }
        label {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          text-align: left;
          margin-bottom: 6px;
             span{
            color: green ;
           .fa-plus{
            color: green ;
          }}
        }
     
        
        }
        .input {
          width: 98%;
          height: 56px;
          border-radius: 100px;
          border: 1px solid lightgray;
          background-color: white;
          padding-left: 12px;
          
        }
    }
     .uploadedImgCont{
    position: relative;
    display: flex;
    width: 200px;
    height: 163px;
    justify-content: center;
    align-items: flex-start;
    margin-left: 50px;
    margin-top: 16px;
    padding-left: 26px;
    background-color: #dfe4ea;
    img{
          margin-right: 9px;
          

   width: 133px;
    height: 120px;
    margin-top: 20px;
    span{
      position: absolute;
      top: 2px;
      right: 0;
    }

}
  }

  .add-img-btn{
    width: 219px;
    height: 163px;
    background-color:  #f9f9f9;
    border:  1px solid  #dfe4ea;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
.submit-btn{
background-color: #34BA96;
height: 56px;
width: 230px;
border-radius: 100px;
padding: 12px 24px 12px 24px;
color: #ffffff;
margin-bottom: 5rem;
padding-bottom: 20px;
  }
  .variant{
    width: 90%;
  }
  }