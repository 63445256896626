@import "./../../../../../../../Assets/Scss/primaryColor";

.estateManagersSetupOtp{
    height: 100vh; 
    overflow-x: hidden;
    .login-right{
        background-color: $primary;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .login-header{ 
            position: absolute;
            top:40px; 
            width: 100%; 

            .login-back{ 
                display: flex;
                justify-content: center;
                align-items: center;

                a{ 
                    text-decoration: none;
                    span{ 
                        font-size: 1.4rem; 
                        padding: 0px;
                        width: 25px;
                        height: 25px; 
                        background: #EFF2F7; 
                        border-radius: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }  
            }

            .login-logo{ 
                img{ 
                    width:150px;
                }
            }
        }

        .login-text{
            position: absolute;
            top:170px; 

            h2{ 
                font-style: normal;
                font-weight: 800;
                font-size: 40px;
                line-height: 48px;
                letter-spacing: -0.01em; 
                color: #FFFFFF;
            }

            .desc{
                font-size: 1rem;
                color: #FFFFFF;
            }
        }

        img{
            position: absolute;
            bottom:0;
            width:60%;
            @media screen and ( max-height: 744px ) { 
                display: none;
              }
        }

    }

    .login-header{ 
        @media (max-width:768px) {
            padding: 0 30px;
        }
        position: absolute !important;
        top:30px !important; 
        width: 100%; 

        .login-back{ 
            display: flex;
            justify-content: center;
            align-items: center;

            a{ 
                text-decoration: none;
                span{ 
                    font-size: 1.4rem; 
                    padding: 0px;
                    width: 37px;
                    height: 37px; 
                    background: #EFF2F6; 
                    border-radius: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #333333 !important;
                }
            }  
        }

        .login-logo{ 
            img{ 
                width:150px;
            }
        }
    }

    .login-form{
        @media (max-width:768px) {
            padding: 0 30px;
        }
        
       
        
      h1{ 
            @media (min-width:768px) { 
                font-style: normal;
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 29px; 
                color: #000000;
                margin-bottom: 10px;
            }
            @media (max-width:768px) { 
                font-style: normal;
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 29px; 
                color: #1b1b1b;
                margin-bottom: 10px;
            }
        }       

        small{
            font-size: .8rem;
            display: flex;
            justify-content: center;
            color: #898787;
        }
        .form-group{
            label{
                font-style: normal;
                font-weight: 400;
                font-size: .9rem;
                line-height: 19px; 
                color: #333333;
                margin-bottom: 10px;
            }
            .form-control{
                background: #FFFFFF;
                border: 1px solid #DFE4EA;
                border-radius: 100px;
            }

            .btn-login{
                background: $primary-estate;
                border: 1px solid #DFE4EA;
                border-radius: 100px;
                width: 100%;
                height: 46px;
                font-weight: 400;
                font-size: .8rem;
                line-height: 18px;  
                color: #FFFFFF; 
            }
        }

        .forgot-password{
            font-style: normal;
            font-weight: 400;
            font-size: .9rem;
            line-height: 19px;
            display: flex;
            align-items: center; 
            color: $primary;
            margin-top: 10px;
            text-decoration: none;
        }

        .signup{
            font-size: .9rem;
            text-decoration: none; 
            color: #000000;
            a{
                text-decoration: none;
            }
        }

        .alert{
            font-size: .8rem;
            .btn-close{
                font-size: .6rem;
                &:focus{
                    box-shadow: none!important;
                    outline: none !important;
                }
            }
        }
    }


    .password-container {
        position: relative;
        z-index: 1;
        input{
            z-index: 2;

        }
        .field-icon {
          float: right;
          margin-right: 20px;
          margin-top: -30px;
          position: relative;
          z-index: 5;
          cursor: pointer;
          opacity: 0.6;
        }
      }

      .err{
        font-size: .7rem;
        text-align: end;
      }

      .container-token{
        display: flex;
        justify-content: center;
    }

    .resendOtp{
        text-decoration: none !important;
        font-size: .8rem; 
        color: $primary-estate;
    }
}