.buyer-saved-list-card {
   
    .brand-logo {
        width: 46px;
        height: 25px;
    }
    
    .custom-card-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .product-image {
        width: 125px;
        height: 103px;
        align-self: center;
        margin-bottom: 30px;
    } 

    .product-name {
        font-style: normal;
        font-weight: 500;
        font-size: .8rem;
        line-height: 150%;
        color: #333333; 
        height: 40px;
        overflow: hidden; 

    }

    .amount {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #F2994A;
    }

    .product-brand {
        font-style: normal;
        font-weight: 500;
        font-size: .8rem;
        line-height: 17px;
        color: #828282;
        text-transform: capitalize !important;
        height: 38px; 
    }

    .location {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
    }

    .close-btn {
        cursor: pointer;
        font-size: .6rem !important;
    }
}