.shopByBrand{
    .section-header{
        h2{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700; 
            @media (max-width:600px) { 
                font-size: 1.5rem;
             }
             @media (min-width:600px) {
                font-size: 2rem; 
             }

            line-height: 38px;
            display: flex;
            align-items: center; 
            color: #333333;
        }

        a{
            font-size: .8rem;
            font-weight: 600;
        }

        span{
            font-size: .8rem;
            font-weight: 600;
        } 
    }

    // .card{ 
    //      background-color: #f8f8f8; 
    //     .card-body{
    //         background-color: #f8f8f8;

    //         height: 130px;
    //         width: auto;
    //         display: flex;
    //         justify-content: center;

    //         img{
    //             width: 70%;
    //             height: 90%;
    //         }
    //     }
    // }
 

    .cat-btn-cont{
        margin-top:50px;
        margin-bottom:130px;
        a{
            font-size: 1.2rem;
            text-decoration: none;
            font-weight:500;
            transition: all .5 ease-in-out;
            &:hover{ 
            font-weight:800;
            }
        }
    }
    a{ 
        text-decoration: none; 
        transition: all .5 ease-in-out;
    }
    
    .shop-brand-title{  
        font-weight: 800;
        font-size: 40px;
        line-height: 48px; 
        letter-spacing: -0.01em; 
        color: #303030;

    }
    .card{
        width: 100%;

        @media (max-width:600px) { 
            height: 90px;  
         }
         @media (min-width:600px) {
            height: 137px;  
         }

        background-color: #f8f8f8; 
        border-radius: 8px; 
        overflow:hidden;
        border:none;
            &:hover{
                box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
                overflow:hidden;
            }
         .card-body{ 
        //    padding:40px 29px;
            margin: 0;
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            
           cursor:pointer;
           transition: all .5s ease-in-out;
           overflow: hidden; 
           &:hover{
               transform:scale(1.1);
               overflow:hidden;
               margin: 0;
               position: absolute;
               top: 50%;
               -ms-transform: translateY(-50%);
               transform: translateY(-50%);
           }  
           
         }

         .sb-cb{
             @media (max-width:600px) {  
                overflow: hidden;
                padding:10px;
             }
             @media (min-width:600px) { 
                overflow: hidden;
                padding:30px;
             }
         }
    } 
           
}

 @media(max-width:500px){
    .shop-brand .card{ 
        height: 97px !important;   
     }
     .shop-brand .card .card-body {
        padding: 20px 19px;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
    }
 }