.revenue {
  position: relative;
  .card{
    height: 351px; 
    box-shadow: none !important;
  }
  .card-header{
    height: 20px;
    .sales{
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;

    }
  }
   .card-body{
    height: 325px;
    padding-top: 3rem; 
     canvas{
      width: 100% !important;
      font-size: .6rem;
     }
  }
   
  .select-control {
    @media (min-width: 1200px) {
      height: 37px;
      width: 134px;
      border-radius: 100px;
    option{
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;

    }

    }
    @media (max-width: 1200px) {
      width: 100%;
    }

    height: 20px;
    background: #ffffff;
    border: 1px solid #dedede;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 0.9rem;

    &:focus {
      border: 1px solid rgb(54, 54, 54);
      box-shadow: 1px 1px 1px rgb(54, 54, 54);
    }
    &:active {
      border: 1px solid rgb(54, 54, 54);
      box-shadow: 1px 1px 1px rgb(54, 54, 54);
    }
  }
}
