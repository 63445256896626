@import "../src/Assets/Scss/primaryColor";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/@denali-design/icons/dist/font/denali-icons-font.css");

body{  
    margin: 0;
    font-family: "Lato", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $light;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

input{
    font-size: .7rem !important;  
    height: 46px;
    &:focus{
        outline: none !important;
        box-shadow: none !important;

    }
    &::placeholder{
        font-size: .7rem;  
    }
 }

 select{
     font-size: .7rem !important;  
     height: 46px;
     &:focus{
         outline: none !important;
         box-shadow: none !important;
 
     }
     &::placeholder{
         font-size: .7rem;  
     }
  }


button{ 
    &:focus{
        outline: none !important;
        box-shadow: none !important;

    } 
 }

 .breadcrumb-item {
    font-size: .7rem;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: ">"; 
}

.w-90{
    width: 90%;
}
.w-95{
    width: 94%;
}

a{
    text-decoration: none;
}

@media (min-width: 992px){
    .productItem.col-lg-2 { 
        width: 20% !important;
    }
    .productItemSkeleton.col-lg-2 { 
        width: 20% !important;
    }
}

.swiper-slide{
    margin-right: 8px !important;
}

.customModal{ 
        margin-top: 200px; 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 28px; 

        @media (min-width:1200px) { 
            width: 436px;   
          }
          @media (max-width:1200px) { 
            width: 100%;   
          }
    
        .modal-content{
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 28px;
        }
        .modal-title{
            font-size: 1rem;
        }

        .btn-close {
            height: 8px;
            width: 8px; 
            border: 1px solid grey;
            border-radius: 50px; 
            font-size: .7rem !important;
        }
}

.custom-alert{
    width: 451px !important;
    min-height: 56px !important;
    background: #EFF2F6 !important;
    border: 1px solid #b5c9f4 !important;
    border-radius: 8px !important;
    box-shadow: none !important;

    font-weight: 400;
    font-size: .8rem;
    line-height: 28px; 
    color: #333333;
}

.dropdown-item{
    &:active{
        background-color: #DFE4EA !important;
    }
    &:focus{
        background-color: #DFE4EA !important;
    }
}

.errors{
    font-size: .6rem;
    color: #ff0000;
}

