.upload{
    .card{ 
        background: #F9F9F9;
        border: 1px solid #DFE4EA;
        border-radius: 8px;

        .card-body{
            .label{
                text-align: center;
                font-style: normal;
                font-weight: 400;
                font-size: .8rem;
                line-height: 19px; 
                color: #4a4949;
                margin-top: 10px;
                cursor: pointer;
            }

            .upload-icon{
                cursor: pointer; 
            }
        }
    } 
    .upload-heading{
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 150%; 
        color: #343333;
        text-align: center;
        margin-top: 30px;
    }

    .upload-desc{
        font-style: normal;
        font-weight: 400;
        font-size: .8rem;
        line-height: 150%; 
        text-align: center; 
        color: #868484;
        margin-bottom: 30px; 
        margin-left: 10px; 
        margin-right: 10px; 
        margin-top: 7px; 
    }

    .upload-success-screen{
        margin-bottom: 20px;
        margin-top: 20px;

        .success-icon{
          text-align: center;
        }
        .success-text{
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 24px; 
            color: #010F2C;
            text-align: center;
            flex: none;
            order: 1;
            flex-grow: 0;
        }
        .success-description{
            font-style: normal;
            font-weight: 400;
            font-size: .8rem;
            line-height: 150%; 
            text-align: center; 
            color: #868484; 
            flex: none;
            order: 1;
            flex-grow: 0;
            margin-top: 5px;

        }
    }
}