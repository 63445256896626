.chekoutSigup{

.desc{
    font-weight: 400;
    font-size: .9rem;
    line-height: 20px; 
    color: #333333;
}

.card{ 
    background: #F2F2F2;
    border-radius: 8px;
    border: none !important;
    margin-top: 25px;

    .instruction{
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;  
        color: #828282;
        margin-bottom: 20px;
    }

    .title{
        font-weight: 400;
        font-size: .82rem;
        line-height: 17px; 
        display: flex;
        align-items: center; 
        color: #333333;
    }

    .title-value{
        font-weight: 700;
        font-size: .9rem;
        line-height: 35px; 
        color: #333333;
    }


    
}


.section-title{ 
    margin-top: 30px;
    font-weight: 700;
    font-size: .9rem;
    line-height: 34px; 
    color: #333333; 
}
.section-label{
    font-weight: 400;
    font-size: .8rem;
    line-height: 29px; 
    color: #333333; 
    margin: 8px 0;
}
.password-container {
    position: relative;  
    .field-icon {
      float: right; 
      right: 15px;
      top:9px;
      position: absolute;
      z-index: 5;
      cursor: pointer;
      opacity: 0.6;

    }
    .form-control{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
    }
  }

  .btn-primary {
    width: 100%;
    height: 46px;  
    background: #4379EB;
    border: 1px solid #DFE4EA;
    border-radius: 100px;
    margin-top: 20px;
  }

  .terms{
    font-weight: 400;
    font-size: .8rem;
    line-height: 19px; 
    color: #333333;
  }
.edit{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px; 
    text-decoration-line: underline; 
    color: #4379EB; 
    }



    .form-input{   
        margin-top: 20px;

        label{
            font-weight: 400;
            font-size: .8rem;
            line-height: 19px; 
            color: #333333;
            margin-bottom: 10px;
        }

        .form-control{ 
            width: 100%;
            height: 46px; 
            background: #FFFFFF;
            border: 1px solid #DFE4EA !important;
            border-radius: 100px;
            &:focus{ 
              border: 1px solid #DFE4EA !important;
            }
        }

        textarea{
            width: 100%;
            height: 211px;  
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 20px;
            &:focus{ 
                border: 1px solid #DFE4EA !important; 
              }
            &:active{ 
                border: 1px solid #DFE4EA !important; 
            }
        }

        .form-control{
            &:focus{ 
                border: 1px solid #DFE4EA !important; 
                box-shadow: none !important;
              }
            &:active{ 
                border: 1px solid #DFE4EA !important; 
            }
        }
    }


    .countryCode{
        height:44px;
        width:50px; 
        background: #EFF2F6;
        border: 1px solid #DFE4EA;
        border-radius: 100px 0px 0px 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .8rem;

        font-weight: 400; 
        line-height: 18px;  
        font-feature-settings: 'liga' off; 
        color: #333333;
        position: absolute;
        bottom:2px;

    }

    .phoneContact{
        padding-left: 60px;
    }
}