.walletWithdrawal{
    .card{

        border: 1px solid #DFE4EA;
        border-radius: 8px;
        background-color: #FFFFFF;
        padding: 20px 0;

        .card-header{
            background-color: #FFFFFF;
            margin-bottom: 20px;

            .inst{
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 22px;  
                color: #333333; 
            }
        }
        .card-body{
            border-radius: 8px;

            label{
                font-weight: 400;
                font-size: .8rem;
                line-height: 19px;  
                color: #333333;
                margin-bottom: 10px;
            }
            .form-control{
                width: 100%;
                height: 46px; 
                background: #FFFFFF;
                border: 1px solid #DFE4EA;
                border-radius: 100px;
            }
        }
        .card-footer{
            background-color: #FFFFFF;
        }
        
        .btn{  
            width: 100%;
            height: 46px; 
            background: #FFFFFF; 
            border: 1px solid #3378F3;
            border-radius: 100px;
            font-style: normal;
            font-weight: 700;
            font-size: .84rem;
            line-height: 18px;  
            color: #3378F3; 
            flex: none;
            order: 0;
            flex-grow: 0;
        }
    }

   
}

.fw{
   
    label{
        font-weight: 400;
        font-size: .87rem;
        line-height: 19px; 
        color: #333333;
        margin-bottom: 12px;
    }

    .form-control{
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
    }

  .btn{
    width: 100%;
    height: 40px;
    background: #3378F3;
    border: 1px solid #DFE4EA;
    border-radius: 100px;
    border: 1px solid #3378F3;
    border-radius: 100px; 
    font-weight: 700;
    font-size: .9rem;
    line-height: 18px;   
    color: #fdfdfd;

  }

  .details{
    font-weight: 400;
    font-size: .8rem;
    line-height: 19px;
    text-align: center; 
    color: #333333;

  } 
}