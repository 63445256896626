.cartItemOrderCompleted{
    
   .cart-item-list{
        margin-top: 30px;
        position: relative; 
        border: 1px solid rgb(9, 9, 9)
    }
    .list-group{
        background-color: #E0E0E0 !important;
        margin: 0px!important;
        .list-group-item{ 
            background-color: #E0E0E0 !important; 
        }

    }
}