.rating-component {
    column-gap: 6px;
    align-items: left;
    font-size: .7rem;
    .rating-component-icon {
        width: 15px;
        height: 15px;
    }

    .rating-component-values {
        font-style: normal;
        font-weight: 500;
        font-size: .7rem;
        line-height: 150%;
        color: #333333;
    }
}