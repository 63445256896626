.enterprisePageTitle{
    h2{ 
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 38px; 
        color: #333333;
    }

    a{ 
      color: #F5A623 !important; 
    }
}