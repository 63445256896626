.saved-item-add-to-cart-form {
    .add-to-cart-btn {
        border-radius: 100px;
        height: 46px;
        background-color: #4379EB;
        color: white;
        font-weight: 400;
        font-size: 16px; 
        border: 1px solid #DFE4EA;

        img{
            width:15px;
            margin-right: 5px;
        }
    }

    .add-to-cart-btn-disable {
        height: 46px;
        background: #BDBDBD;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        color: #828282;;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;   
        
        img{
            width:15px;
            margin-right: 5px;
        }
   
    }

    .add-to-cart-quantity-input {
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
    }

    .custom-label {
        font-weight: 400;
        font-size: .8rem;
        line-height: 19px;
        color: #333333;
    }
}