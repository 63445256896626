.enterpriseSettings{ 
    .buyerPageTitle{
        a{ 
          color: #F5A623 !important;
        }
    }
    
    .my-account-outlet {
        margin-top: 17px;
    }

}