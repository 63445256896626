.enterpriseCart{
position: relative;
.section-header{
    h2{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        display: flex;
        align-items: center; 
        color: #333333;
    }
}

h1{
    font-weight: 700;
    font-size: 2rem;
    line-height: 48px;  
    color: #333333;

}

.location-selected{
    width:15px;
}

.location-address{
    font-size: .7rem;
}

.ordertype{
    .btn{ 
        @media (max-width:600px) {  
            width: 100%;
            height: 51px;  
            margin-top:10px
        }

        @media (min-width:600px) {
            width: 339px;
            height: 51px;  
        }

        border-radius: 8px;

        font-style: normal;
        font-weight: 400;
        font-size: .8rem;
        line-height: 19px; 
        color: #333333;
        border: 1px solid #232323;

        &.active{ 
          border-width: 2px;
          border: 2px solid #2a69f0;
        }

        img{
          width:15px;
          margin-right: 7px;
        }


    }
}



.continue-shoping{
.btn{ 
    @media (max-width:600px) { 
        width: 100%;
        height: 46px;
        margin-bottom: 20px;
    }
    @media (min-width:600px) { 
        width: 309px;
        height: 46px;
    }
    border: 1px solid #F5A623;
    border-radius: 100px;    
    margin-top: 20px;  
    font-weight: 400;

    font-size: .8rem;
    line-height: 18px;  
    color: #F5A623;
}
}

hr{
    border: 1px solid rgb(216, 216, 216);
    margin-top: 20px;
}

.cart-summary{ 
    background: #F2F2F2;
    border: 1px solid #DFE4EA;
    border-radius: 8px;

    .card-header{
        background: #F2F2F2;
        font-weight: 700;
        font-size: .98rem;
        line-height: 24px; 
        display: flex;
        align-items: center; 
        color: #333333;
        
    }

    .card-body{
        font-size: .9rem;
        background: #F2F2F2;
        .cart-total{
            font-weight: 900;
        }
        .cart-el{
            font-size: .9rem;
            margin-top: 6px;
        }
    }

    .checkout-btn{
        @media (max-width:600px) { 
            width: 100%;
            height: 46px;
            margin-bottom: 20px;
        }
        @media (min-width:600px) {  
            width: 379px;
            height: 46px; 
        }

        background: #F5A623;
        border-radius: 100px;
        border: 1px solid #DFE4EA; 
        font-weight: 400;
        font-size: .9rem;
        line-height: 18px;  
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    hr{ 
    border: 1px solid #E0E0E0;
    }

    .havecoupon{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: .8rem;
        line-height: 19px; 
        color: #333333;
        margin-bottom: 15px;
    }

    .form-control{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
    }
    .apply-btn{
        @media (max-width:600px) {  
            width: 100%;
            height: 56px; 
        }
        @media (min-width:600px) {   
            width: 379px;
            height: 56px; 
        }

        background: #333333;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        font-weight: 400;
        font-size: .9rem;
        line-height: 18px;  
        color: #FFFFFF;
    }

   
}

.payment-option{
    font-size: .8rem;
    margin-top: 20px;
    font-weight: 600; 
    line-height: 19px; 
    color: #000000; 

    img{
        width: 25px;
        margin-top: 6px;
    }
}





.bestdeals-row{
    display: flex;
    flex-direction: row;

    .col-5{
        flex: 20%;
        margin: 10px;
       

        .product-card{ 
            background: #FFFFFF; 
            border: 1px solid #E0E0E0 !important;
            border-radius: 8px; 
            position: relative;

            &:hover{
                box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
            }
          
            .brand-logo{
                width:40px;
            }

            .wish-icon{
                width:20px
            }

            .product-image{
                height:160px;
            }

            .col-12{
                position: relative;

                .promo{
                    position: absolute;
                    top:0 ;
                    width: 60px;
                    height: 25px;
                    left: 0; 
                    background: #FF0000;
                    border-radius: 0px 24px 24px 0px;
                    margin-top: 10px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: .8rem;
                    line-height: 120%; 
                    color: #FFFFFF;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
              }

              .product-title{ 
                font-style: normal;
                font-weight: 500;
                font-size: .9rem;
                line-height: 150%; 
                color: #333333;
              }

              .price{
                display: flex;
                flex-direction: row;

                .old-price{
                    flex: 60%; 
                    font-weight: 400;
                    font-size: .8rem;
                    line-height: 17px; 
                    text-decoration-line: line-through; 
                    color: #717171;   
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }
                .new-price{
                    flex: 60%;
                    font-weight: 700;
                    font-size: .9rem;
                    line-height: 19px; 
                    color: #F2994A; 
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
              }

              .company-name{ 
                font-style: normal;
                font-weight: 700;
                font-size:.8rem;
                line-height: 17px; 
                color: #828282;
            }

            .rating{
                span{
                    font-size: .7rem !important; 
                    color: #F2994A !important;
                }
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: .8rem;
                line-height: 17px; 
                color: #828282;
            }

            .location{
                span{
                    font-size: .7rem !important;
                }
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: .8rem;
                line-height: 17px; 
                color: #828282;
            }
        }
    }
}

.carousel-control-container{ 
    width:80px !important; 

    .carousel-control-next{ 
        height: 35px;
        width: 35px;
        background-color: #DFE4EA !important;
        opacity: 1;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center; 
        
        .islarge{
            font-size: 1.3rem;  
            color: #333333;
            font-weight: 600 !important;
        } 
    } 
    
    .carousel-control-prev{ 
        height: 35px;
        width: 35px;
        background-color: #DFE4EA !important;
        opacity: 1;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center; 
        
        .islarge{
            font-size: 1.3rem;  
            color: #333333;
            font-weight: 600 !important;
        } 
    } 
}
.carousel-indicators-container{
    position: relative;
    margin-top: 50px;

    .carousel-indicators{
        position: absolute;
        left: 0;
        display: flex;
        flex-direction: row;
        
        button{
            flex: 20%;
            width: 100%;
            height: 1px !important;
            margin: 0;
            background: #a4a4a4 ;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent; 
            &.active{
                background: #333333 !important;
                border-radius: 1px;
                height: 4px;
            }
        }
     }
  }

//   .sticky {
//     position: fixed;
//     top: 0;  
//     // width: 100%

//     width: 350px;
//     height: 200px;
//     // left: 30%;
//     left: 76%;
//     top: 0%;
//     margin-left: -20px;
//   }


    .wrapper {
        @media (min-width:800px) {
            width: 100%;
            margin: 0 auto;
            position: relative; /* Ensure absolute positioned child elements are relative to this*/
        }
    }
     

    /* Absolute positioned wrapper for the element you want to fix position */
    .fixed-wrapper {
        @media (min-width:800px) {
            width: 100%;
            position: absolute;
            top: 0;
            left: -10px; /* Move this out to the left of the site body, leaving a 20px gutter */
         }
      }
       
    /* The element you want to fix the position of */
    .sticky {
        @media (min-width:800px) { 
            width: 364px;
            position: fixed;
            /* Do not set top / left! */
        }
    }
}