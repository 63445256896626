.faq-container-buy{
    background: #3378F3;
    border-radius: 8px;
    margin-top: 20px;
    padding: 49px 35px;

    .user-first-name {
        font-style: normal;
        font-weight: 800;
        font-size: 33.291px;
        line-height: 40px;
        color: white;
        letter-spacing: -0.01em;
    }

    .cta-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #FFFFFF;
    }
   
} 

.faq--bg-image-buyer{
    opacity: 0.3;
}