.priceCalculate{
    a{
        font-size: .7rem;
    }
   
}

.priceCalculator{
    margin-top: 200px; 
    width: 436px;  
    // .modal-content {
    background: #FFFFFF;
    border: 1px solid #DFE4EA;
    border-radius: 28px;
    //   }

    .modal-content{
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 28px;
    }

    .modal-title{
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 32px;  

        color: #333333;
    }

    .btn-close {
        height: 9px;
        width: 9px; 
        border: 1px solid grey;
        border-radius: 50px; 
        font-size: .7rem !important;
    }

     .desc{
        font-size:.9rem;
        margin-bottom: 10px;
     }
      
    .form-control{ 
        height: 39px; 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 50px !important;
        margin-bottom: 20px;
    }

    label{
        font-weight: 400;
        font-size: .8rem;
        line-height: 19px; 
        color: #353535;
        margin-bottom: 5px;
    }

    .btn-primary{ 
        width: 100%;
        height: 45px; 
        background: #4379EB;
        border: 1px solid #DFE4EA;
        border-radius: 100px; 
        font-weight: 400;
        font-size: .9rem;
        line-height: 18px;  
        color: #FFFFFF;
    }

}