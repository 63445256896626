.store-banner {
  padding-top: 1rem;
  .banner-container {
    border-radius: 8px;
    .banner-container-up {
      height: 250px;
      background-color: #34ba96;
    }
    .banner-container-down {
      height: 113px;
      background-color: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .banner-label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
          font-size: 32px;
          font-weight: 600;
          line-height: 38px;
          text-align: left;
        }
      }
      .cust-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    .customize-btn {
      border-radius: 20px;
      padding: 6px 12px 6px 12px;
      font-size: 15px;
      outline: none;
      border: 1px solid #34ba96 ;
      background-color: transparent;
      margin-bottom: 6px;
    }
  }
}
