.enterpriseInvoiceDetails{
    margin-top: 17px;
    
    .make-offer{

        background: #FFFFFF; 
        border: 1px solid #F5A623;
        border-radius: 100px;  
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;  
        color: #F5A623;
        
        &:active{
            border: 1px solid #F5A623;
            color: #F5A623; 
        }
        &:focus{
            border: 1px solid #F5A623;
            color: #ffffff; 
            background-color: #F5A623;
        }
    }

    .invoice-number{
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 24px; 
        color: #303030;
    }

    .invoice-tagline{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;  
        color: #606060;
    }

    .card{ 
        border: 1px solid #DFE4EA;
        border-radius: 8px;

        .card-header{
            .label{
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 15px; 
                color: #303030;
            }

            .value{ 
                font-style: normal;
                font-weight: 400;
                font-size: .93rem;
                line-height: 24px; 
                color: #303030;
                
            }

           
            .approve{
                width: 86.6px;
                height: 28px;
                background: #FFFFFF;
                border: 1px solid #34BA96;
                color:  #34BA96;
                border-radius: 100px;
                font-size: .7rem;
            }

            .decline{
                width: 86.6px;
                height: 28px;
                background: #FFFFFF;
                border: 1px solid #E45A5A;
                color: #E45A5A;
                border-radius: 100px;
                font-size: .7rem;
                margin-left: 10px;
            }
        }

        .card-body{
            tr{ 
                padding: 820px !important;
            }
            thead{ 
                background: #F5F7FA;
                font-style: normal;
                font-weight: 700;
                font-size: .8rem;
                line-height: 37px; 
                color: #303030;
            }
            tbody {
            tr{
                td{
                    font-style: normal;
                    font-weight: 400;
                    font-size: .76rem;
                    line-height: 37px; 
                    color: #303030;
                }
            }

            .footer-data{
                background-color: #F5F7FA;
            }
          }
        }

        .card-footer{
            background-color: #FFFFFF;
            .download{ 
                background: #FFFFFF; 
                border: 1px solid #242424;
                border-radius: 100px;
                font-size: .77rem;
                height: 36px;
            }
            .share{ 
                background: #FFFFFF; 
                border: 1px solid #242424;
                border-radius: 100px;
                font-size: .77rem;
                margin-left: 10px;
                height: 36px;
            }
            .form-input{
                label{
                    font-size: .8rem;
                }

                .form-control{
                    height: 40px;
                    border-radius: 50px;
                    
                    &:focus{
                        border: 1px solid rgba(187, 187, 187, 0.909);
                    }

                }
            }
            .paynow{
                background: #F5A623;
                border-radius: 100px;
                font-style: normal;
                font-weight: 700;
                font-size: .77rem;
                line-height: 18px;  
                color: #ffFFFF;
                width: 136px;
                height: 36px;

                @media (max-width:500px) {
                    margin-top: 10px;
                }
            }
        }
    }

    .selectCheck{
        accent-color: #F5A623;
        background-color: #F5F7FA;
        width: 15px;
    }
}