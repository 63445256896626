.seller-navbar {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  border-bottom: #303030;
  z-index: 66;
  width: 90vw; 
  .nav-right-container {
    display: none;
  }

  .navbar-brand{
    font-size: 1rem;
    color: #303030;
  }

  .profile-container {
    margin-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
      background-color: #34ba96;
      padding-top: 8px;
      color: #ffffff;
      height: 38px;
      width: 38px;
      border-radius: 19px;
      text-align: center;
      font-size: 14px;
      text-justify: auto;
      font-weight: 400;
      outline: none;
    }
    .profile {
      margin-left: 8px;
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .store-name {
        margin-bottom: 0px;
        font-size: 16px;
        color: black;
        font-weight: 600;
      }
      .user-name {
        margin-top: 0px;
        font-size: 10px;
        color: #888888;
      }
    }
    .dropdown-icon {
      height: 28px;
      width: 28px;
      margin-left: 9px;
    }
  }

  .notif-icon {
    height: 24px;
    width: 24px;
  }
  .logo-text {
    display: none;
    font-size: 1rem;
  }
 
}


@media (min-width: 992px) {
  .seller-navbar {
    position: sticky;
    top: 0;
    display: flex; 
    width: 100%;
      .nav-right-container {
        display: flex;
        margin-left: 20rem;
        margin-right: 0;
        align-items: center;
        justify-content: space-between;
      }
    .logo-text {
       display: block;
       font-size: 20px;
       color: #303030;
  
    }
  }
}
