.reorderProduct-list{
    margin-top: 25px;
     
 
      .card-header{
        font-style: normal;
        font-weight: 500;
        font-size: .8rem;
        line-height: 29px; 
        color: #000000;

        .see-all{
            font-weight: 400;
            font-size: .8rem;
            line-height: 18px;  
            text-align: right; 
            color: #F5A623;
        } 
      }

      tbody, td, tfoot, th, thead, tr {
        border: 1px solid rgb(255, 255, 255);
      }
      thead, th, td, tr {
        border-bottom: 1px solid #DFE4EA;
      }

    .btn-new-reorder{ 
        background: #F5A623;
        border: 1px solid #DFE4EA;
        border-radius: 100px;font-style: normal;
        font-weight: 400;
        font-size: .8rem;
        line-height: 18px; 
        display: flex;
        align-items: center;
        text-align: center;
        height: 40px;
        width: 80%;
        display: flex;
        justify-content: center; 
        color: #FFFFFF;

        @media (max-width:800px) {
            margin-top: 20px;
        }

        svg{
            color: #ffffff;
            width: 16px;
            margin-right: 10px;
        }
    }



    .enterprise-table{
        .table{
            border: 1px solid #DFE4EA;
            border-radius: 20px !important;
            margin-top: 20px;

            thead{
                th{
                    font-style: normal;
                    font-weight: 500;
                    font-size: .77rem;
                    line-height: 18px; 
                    color: rgba(76, 76, 76, 0.81);
                }
            }

            tbody{
                td{
                    font-style: normal;
                    font-weight: 500;
                    font-size: .88rem;
                    line-height: 150%;  
                    color: #606060;

                    .product-image{ 
                        width: 30px;
                        height: 34px;
                        margin-right:5px;
                    }

                    .btn-reorder{ 
                        background: rgba(245, 166, 35, 0.15);
                        border-radius: 100px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: .8rem;
                        line-height: 18px; 
                        display: flex;
                        align-items: center;
                        text-align: center;  
                        color: #F5A623; 
                        flex: none;
                        order: 1;
                        flex-grow: 0;
                    }



                }

                @media (max-width:800px) {  
                    .profile-title{
                        
                    }

                    .profile-detail{
                        font-size: .74rem !important;
                        line-height: 13px;
                    }

                    .profile-btn{
                        height: 70px; 
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .btn{ 
                            border: 1px solid #DFE4EA;
                            border-radius: 100px;
                            font-style: normal;
                            font-weight: 500;
                            width: 60px;

                        }
                    }
                }
                @media (min-width:800px) {  

                    .profile-detail{
                        // font-size: .5rem !important;
                        // line-height: 14px;
                    }
                }

                .no-order{
                    height: 250px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .table-profile{
                @media (max-width:800px) { 
                    width:55px;
                    height:55px;
                    border-radius: 5px; 
                }
                @media (min-width:800px) { 
                    width:28px;
                    height:28px;
                    border-radius: 50px; 
                }
            }

            .dropdown-toggle{
                &::after{
                    display: none;
                }

                .d-icon{
                    color: #606060;
                    font-size: .9rem; 
                }
            }
        }
    }

    .dropdown-menu{
        li{
            font-style: normal;
            font-weight: 400;
            font-size: .9rem;
            line-height: 18px; 
            display: flex;
            align-items: center; 
            color: #333333;
        }
    }

}