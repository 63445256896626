.categories-main{
    position: relative;
    .section-header{
        h2{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            display: flex;
            align-items: center; 
            color: #333333;
        }
    }
   
 
   
    .catItems{  
        margin: 15px 0px; 
       

        .product-card{ 
            background: #FFFFFF; 
            border: 1px solid #E0E0E0 !important;
            border-radius: 10px; 
            position: relative;
            height: 230px;
            width:100%;
            overflow: hidden;

            &:hover{
                box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
            }
           
            .product-image{
                height:100%;
                width: 100%;
                border-radius: 10px; 
            }
          .card-body{
            position: relative;
             display: flex;
             justify-content: center;

             a{ 
                  

                  position: absolute;
                  bottom: 20px;
                  z-index: 99;
                  width: 80%;  

                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap; 

                  background: #FFFFFF;
                  border-radius: 40px;
                  font-family: 'Lato';
                  font-style: normal;
                  font-weight: 700;
                  font-size: .89rem;
                  line-height: 24px; 
                  color: #000000;

            }
          }

        } 
  }
}