.buyerOrders{
    .sub-nav{ 
        width: 489px;
        height: 46px; 
        background: #F3F3F3;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; 

        @media (max-width:500px) { 
          overflow: scroll;
        }

        .nav-link{
            font-weight: 400;
            font-size: .9rem;
            line-height: 18px; 
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center; 
            color: #333333; 
            flex: none;
            order: 0;
            flex-grow: 0;
            width: 153px;
        }

        .active{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center; 
            gap: 10px;  
            width: 153px;
            height: 35px; 
            font-size: .9rem;
            background: #FFFFFF;
            border-radius: 10px;
        }
    }
}