.category-desktop{
    position: relative;
    .section-header{
        h2{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700; 
            line-height: 38px;
            display: flex;
            align-items: center; 
            color: #333333;
            @media (max-width:600px) { 
                font-size: 1.5rem;
             }
             @media (min-width:600px) {
                font-size: 2rem; 
             }
        }
    }
    
            .product-card{ 
                background: #FFFFFF; 
                border: 1px solid #E0E0E0 !important;
                border-radius: 10px; 
                position: relative;
                height: 230px;
                width:100%;
                overflow: hidden;

                &:hover{
                    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
                }
               
                .product-image{ 
                    height: 230px; 
                    width: 100%;
                    border-radius: 10px; 
                }
              .card-body{
                position: relative;
                 display: flex;
                 justify-content: center;

                 a{  
 
                      position: absolute;
                      bottom: 20px;
                      z-index: 99;
                      width: 80%;  

                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap; 

                      background: #FFFFFF;
                      border-radius: 40px;
                      font-family: 'Lato';
                      font-style: normal;
                      font-weight: 700;
                      font-size: .89rem;
                      line-height: 24px; 
                      color: #000000;
 
                }
              }
 
            } 
 
    .carousel-control-container{ 
        width:80px !important; 
    
        .carousel-control-next{ 
            height: 35px;
            width: 35px;
            background-color: #DFE4EA !important;
            opacity: 1;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center; 
            
            .d-icon{
              font-size: 1.3rem !important;  
              color: #333333;
              font-weight: 300 !important; 
              display: flex;
              justify-content: center;
              align-items: center;
          }
          
            .islarge{
                font-size: 1.3rem;  
                color: #333333;
                font-weight: 600 !important;
            } 
        } 
        
        .carousel-control-prev{ 
            height: 35px;
            width: 35px;
            background-color: #DFE4EA !important;
            opacity: 1;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center; 
            
            .d-icon{
              font-size: 1.3rem !important;  
              color: #333333;
              font-weight: 300 !important; 
              display: flex;
              justify-content: center;
              align-items: center;
          }

            .islarge{
                font-size: 1.3rem;  
                color: #333333;
                font-weight: 600 !important;
            } 
        } 
    }
    .carousel-indicators-container{
        position: relative;
        margin-top: 50px;

        .carousel-indicators{
            position: absolute;
            left: 0;
            display: flex;
            flex-direction: row;
            
            button{
                flex: 20%;
                width: 100%;
                height: 1px !important;
                margin: 0;
                background: #a4a4a4 ;
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent; 
                &.active{
                    background: #333333 !important;
                    border-radius: 1px;
                    height: 4px;
                }
            }
        }
    }



.swiper {
    width: 100%;
    // height: 100%;

    @media (max-width:600px) { 
      padding-bottom: 30px; 
   }
   @media (min-width:600px) {
      padding-bottom: 60px; 
   }
  }
  
  .swiper-slide {    
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
//   .swiper-slide img {
//     display: block;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
  
  .swiper-slide {
    width: 246px;
  }
  
  .swiper-slide:nth-child(2n) {
    width: 246px;
  }
  
  .swiper-slide:nth-child(3n) {
    width: 246px;
  }
  .swiper-pagination{
    margin-bottom: -20px;
    padding-bottom: 10px;

    display: flex;
    flex-direction: row;
  }

  .swiper-pagination-bullet { 
    font-size: 0px;
    color: #a4a4a4; 
    flex: 20%;
    width: 100%; 

    height: 1px !important; 
    background: #a4a4a4 ;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent; 
  }
  
  .swiper-pagination-bullet-active {
    color: #fff; 
    background: #333333 !important;
    border-radius: 1px;
    height: 4px;
  }
}