@import "../../../../Assets/Scss/primaryColor";

.seller-wrapper {  
  background: white;
  @media (min-width:1310px) {
    .col-25{ 
      flex: 0 0 auto;
      width: 18%;
      height: 100%;
    } 
    .col-85{ 
        flex: 0 0 auto;
        width: 82%;
      } 
  }

  @media (max-width:1310px) and (min-width:990px) {
    .col-25{ 
      flex: 0 0 auto;
      width: 25%;
    } 
    .col-85{ 
        flex: 0 0 auto;
        width: 75%;
      } 
  }

  @media (max-width:990px) {
    .col-25{ 
      flex: 0 0 auto;
      width: 100%;
    } 
    .col-85{ 
        flex: 0 0 auto;
        width: 100%;
      } 
  }
 
}
