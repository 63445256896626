.EstateManagersDashboard{
    .header-title{
        margin-bottom: 30px;
        margin-top: 20px;

        h2{
            font-size: 1.3rem;  
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left; 
        }

        .subtext{
            font-size: .8rem;

        }
    }
}