.profileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #DFE4EA;
    border-radius: 8px;
    padding:32px 31px;
    height: 168px;

    .pictureAndName {
        display: flex;
        flex-direction: row;
        row-gap: 18px;

        .profileName {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 1.3rem;
            line-height: 18px;
            color: #333333;
            display: flex;
            align-items: center;
            margin-left: 10px;

        }
    }

    .updateProfileBtn {
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #3378F3;
        padding: 14px 24px;

        img {
            margin-right: 5px;
        }
    }
}