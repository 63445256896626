@import "../../../Assets/Scss/primaryColor";

.seller-sidebar {
  positiin: sticky;
  height: 100vh;
  padding-bottom: 6rem;
  
width: 231px;
 box-shadow:  1px 0px 0px 0 rgba(0, 0, 0, 0.1);
border-right: #303030;
border-radius: 0px;

  z-index: 999;
}
