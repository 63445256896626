.Statistics{
    .card{

        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 8px;
        margin-top: 20px;

        .icon-wraper{
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 8px;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .title{
            font-style: normal;
            font-weight: 500;
            font-size: .8rem;
            line-height: 150%; 
            color: #606060; 
            flex: none;
            order: 0;
            flex-grow: 0;

            @media (max-width:600px) {
                height: 40px !important; 
            }
        }

        .value{
            font-style: normal;
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 24px; 
            color: #333333; 
            flex: none;
            order: 1;
            flex-grow: 0;
        }
    }
}