@import "../../../../../../../Assets/Scss/primaryColor";

.enterpriseWalletFunding-modal{
  margin-top: 100px;  
  background: #FFFFFF;
  border: 1px solid #DFE4EA;
  border-radius: 28px; 

  .modal-title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 32px; 
    display: flex;
    align-items: center; 
    color: #333333;
  }

  .modal-content{
      background: #FFFFFF;
      border: 1px solid #DFE4EA;
      border-radius: 28px; 
  }
  .form-control{ 
      background: #FFFFFF;
      border: 1px solid #DFE4EA;
      border-radius: 100px;
      height: 50px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px; 
      font-feature-settings: 'liga' off; 
      color: #828282;

    

      &:focus{
          outline: none !important;
          box-shadow: none !important;
      }
  }

  select{
      font-size: .8rem !important;
      option{ 
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: .8rem !important;
          line-height: 18px; 
          font-feature-settings: 'liga' off; 
          color: #828282;
      }

  }

  .apply{  
      width: 100%;
      height: 56px; 
      background: #3378F3; 
      color: #DFE4EA;
      font-weight: 800;
      box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
      border-radius: 100px;
  }


  .apply-estate{  
    width: 100%;
    height: 56px; 
    background: $primary-estate; 
    color: #DFE4EA;
    font-weight: 800;
    box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
    border-radius: 100px;
}

  .apply-outline{ 
      width: 100%;
      height: 56px; 
      font-weight: 800;
      color: #3378F3;
      background: #3379f300; 
      box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
      border-radius: 100px;

  }
 
 .btn-close {
      height: 15px;
      width: 15px; 
      border: 1px solid grey;
      border-radius: 50px; 
      font-size: .7rem !important;
  } 


    .add-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      border: 1px solid #F5A623;
      box-sizing: border-box;
      border-radius: 100px; 
      font-weight: 600;
      font-size: 0.9rem;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 0.03em;
      color: $primary;
      background-color: #F5A623;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 20px 0;

      &:hover {
        color: rgb(243, 242, 242);
      }
  }

  .add-btn-estate{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      border: 1px solid $primary-estate;
      box-sizing: border-box;
      border-radius: 100px; 
      font-weight: 600;
      font-size: 0.9rem;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 0.03em;
      color: $primary;
      background-color: $primary-estate;;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 20px 0;

      &:hover {
        color: rgb(243, 242, 242);
      }
  }

  label{
    font-style: normal;
    font-weight: 400;
    font-size: .8rem;
    line-height: 19px; 
    color: #333333;

  }

  .form-radio{
    border: 4px solid #F5A623 !important;
    width: 17px;
    height: 17px;
    margin-right: 10px;
  }

  .form-radio-estate{
    border: 4px solid $primary-estate !important;
    width: 17px;
    height: 17px;
    margin-right: 10px;
  }

  .card{ 
    background: #FFFFFF; 
    border: 1px solid #DFE4EA;
    border-radius: 8px;
    margin: 7px 0;
    height: 70px;

    .card-body{
      display: flex; 
      align-items: center;

      label{ 
        font-weight: 400;
        font-size: .8rem !important;
        line-height: 19px; 
        color: #303030; 
      }

    }
  }


  .section-title{
    margin-top: 50px;
    font-size: .8rem;
    font-weight: 400; 
    line-height: 19px;  
    color: #333333;
  }

 

   /* The container */
    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 15px;
      width: 15px;
      background-color: #eee;
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: #F5A623;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 3.6px;
      left: 3.6px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
 
}



 
.fts{
  margin-top: 100px;  
  background: #FFFFFF;
  border: 1px solid #DFE4EA;
  border-radius: 28px; 

  .well{
      width: 100%;
      height: 209px; 
      background: #EFF2F6;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      .bankName{
          font-weight: 400;
          font-size: 1rem;
          line-height: 29px; 
          color: #333333;
      }
      .accountNumber{
          font-weight: 600;
          font-size: 1.5rem;
          line-height: 38px; 
          color: #333333;
      }

      .accountName{
          font-weight: 400;
          font-size: 1rem;
          line-height: 29px; 
          color: #333333;
      }

      .mb2{
          font-size: .9rem;
          margin-bottom: 20px;
          font-weight: 400; 
          line-height: 19px;  
          color: #333333;
      }


  }

  .btn{
    width: 100%;
    height: 46px; 
    background: #FFFFFF; 
    border: 1px solid #F5A623;
    border-radius: 100px; 
    font-weight: 700;
    font-size: 1rem;
    line-height: 18px;   
    color: #F5A623;

  }
}