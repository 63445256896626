.OffcanvasDrawer{
    .offcanvas-header{
        padding-top: 30px;
        padding-bottom: 7px; 
        .offcanvas-title{ 
            &.h5{
                font-weight: 700;
                font-size: 1.2rem;
                line-height: 32px;  
                display: flex;
                align-items: center; 
                color: #333333;
            }
        }
        .btn-close{ 
            border: 1.5px solid #333333 !important;
            border-radius: 50px;
            font-size: .5rem !important;
            width: 5px;
            height: 5px;
        }
    } 

    .description{
        font-size: .8rem;
        margin-bottom: 20px;
        color: #919191;
    }
}