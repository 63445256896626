.search{

        width: 435px;
        position: relative; 
        
    .search-overlay{
        position: fixed;
        top:0;
        right:0;
        width: 100vw;
        height: 100vh;
        background-color: #0000005b;
        z-index: 100; 
    }
    
    .search-result{
        position: absolute; 
        background-color: #ffffff;
        z-index: 989; 
        top:0; 
        width: 100%;
        border-radius: 30px;
        padding-top: 60px;
        padding-bottom: 30px;

        .list-group{
            .list-group-item{
                padding: 10px 25px 10px 25px;
                &:hover{ 
                    background: #EFF2F6;
                    cursor: pointer;
                    border-radius: 1px;
                }
                a{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18px;  
                    color: #727272 !important;
                }
            }
        }
    }
    .search-result-frame{
        // margin-top: 60px;
    }

    .header-search-box{ 
        width: 435px;
        position: relative;
        z-index: 999; 
 
        .search-field{ 
            width:100%;
            height: 46px; 
            background: #ffffff;
            border: 1px solid #DFE4EA;
            border-radius: 100px; 
            padding-left: 35px;
            padding-right: 35px;

            &::placeholder{
                font-size: .8rem;
            }

        }
        .search-icon{
            position: absolute;
            left:12px;
            top:11px; 
            .search-nav-icon{ 
                width: 17px;
                height: 17px;
            }
        }

        .filter-icon{
            position: absolute;
            top:9px; 
            right:10px;
            background: #EFF2F6;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center !important;
            align-items: center !important;
            border-radius: 50px;
            cursor: pointer;

            .search-nav-icon{
                width: 17px;
                height: 17px;
            }
        } 
    }
}

.header-search-box-dialog {
    margin-top: 200px; 
    width: 436px;  


    .modal-content{
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 28px;
    }
    .form-control{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        height: 50px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px; 
        font-feature-settings: 'liga' off; 
        color: #828282;

      

        &:focus{
            outline: none !important;
            box-shadow: none !important;
        }
    }

    select{
        font-size: .8rem !important;
        option{ 
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: .8rem !important;
            line-height: 18px; 
            font-feature-settings: 'liga' off; 
            color: #828282;
        }

    }

    .apply{  
        width: 100%;
        height: 56px; 
        background: #3378F3; 
        color: #DFE4EA;
        font-weight: 800;
        box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
        border-radius: 100px;
    }

    .apply-outline{ 
        width: 100%;
        height: 56px; 
        font-weight: 800;
        color: #3378F3;
        background: #3379f300; 
        box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
        border-radius: 100px;

    }

   .btn-close {
        height: 15px;
        width: 15px; 
        border: 1px solid grey;
        border-radius: 50px; 
        font-size: .7rem !important;
    }



 }