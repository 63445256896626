.PageAds{
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgb(28, 2, 88) 0%, rgb(85, 30, 214) 70%, rgb(176, 147, 243) 100%);
    color: #fff;
    border-radius: 20px;

    .title{
        font-size: 1.5rem;
        padding: 1rem 0;
        font-weight: 600;
    }

    .button{
        margin-bottom: 30px;
        .btn{
            color: #fff;
            border-radius: 50px;
            border: 1px solid #fff;
            font-size: .9rem;
        }
    }
}