.buyer-profile-contact-details {
    .title {
        font-style: normal;
        font-weight: 400;
        font-size: .9rem;
        line-height: 19px;
        text-transform: uppercase;
        color: #767676;
    }

    .item {
        .details {
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 8px;
            padding: 14px 24px;
            .details-title {
                font-style: normal;
                font-weight: 400;
                font-size: .9rem;
                line-height: 19px;
                color: #333333;
            }

            .details-value {
                font-style: normal;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 18px;
                color: #333333;
            }

        }
    }

}