.order-summary-item {
    border-top: 1px solid #E0E0E0 !important;
    padding:32px 0px;
    .product-image {
        width:90px;
        height:95px;
        border-radius: 8px;
        margin-right: 16px;
    }
    .amount {
        color:#F2994A;
        font-weight: bold;
        font-size:14px;
    }
    .item-description {
        color:#333333;
        font-size: 14px;
        line-height: 17px;
        font-weight: normal;
    }
    p{
        margin:0px;
        padding:0px
    }
}