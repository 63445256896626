.best-selling {
  .card {
height:100%;    
.card-body{
      margin-top: 20px;
    }
  }

  .select-control {
    @media (min-width: 1200px) {
      width: 60%;
      height: 37px;
      width: 113px;
      border-radius: 100px;
    .option{
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
    }
    
    }
    @media (max-width: 1200px) {
      width: 100%;
    }
    height: 30px;
    background: #ffffff;
    border: 1px solid #dedede;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 0.9rem;
    &:focus {
      border: 1px solid rgb(54, 54, 54);
      box-shadow: 1px 1px 1px rgb(54, 54, 54);
    }
  }

  position: relative;
  .see-link {
    font-size: 0.8rem;
  }
  .spinner-border {
    width: 5rem;
    height: 5rem;
    color: rgb(10, 10, 10);
  }
  .w--75 {
    width: 40px;
  }
  .recent-prod-title {
    font-weight: 500;
    font-size: 0.87rem;
    color: #606060;
    line-height: 150%;
  }
  .recent-prod-price {
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 150%;
    color: #ff0000;
  }

  .line {
    background-color: rgba(0, 0, 0, 0.377);
  }

  .title{
    font-size: .9rem;
    font-weight: 500;
  }
}
