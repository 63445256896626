.cbcast{
    .page-title{
        font-size: 2rem;
        font-weight: 900;
    }
    .featured-cast{
        height: 570px;
        width: 100%;
        background-color: rgb(252, 252, 252) !important;
        background-size: cover;
        margin-top: 70px;
        margin-bottom: -240px;

        .card-body{
            @media (min-width:1000px) {
                padding: 80px;
            }

            .featured-type{
                position: absolute;
                top: 28px; 
                color:rgb(255, 145, 0);
                font-size: 1rem;
                font-weight: 700;
            }
            h2{
                position: absolute;
                bottom: 80px;
                color:rgb(252, 252, 252);
                font-weight: 700;
                font-size: 2rem;
                cursor: pointer;
                
                @media (min-width:700px) {
                    width: 70%;
                }
            }

            .post-by{ 
                position: absolute;
                bottom: 58px; 
                color:rgb(255, 145, 0);
                font-size: .8rem;
                font-weight: 700;
            }

            
        }

       
    }

    .cb-cast-list{
        background-color: black;
        padding-top: 300px;
        
  
        .cast-item{
            height: 340px;
            background-size: cover;
            background-color: rgb(0, 0, 0);
            transition: all .5s ease-in-out;

            &:hover{
                transform: scale(1.02);
            }

            .card-body{
                h2{
                    position: absolute;
                    bottom: 39px;
                    color:rgb(252, 252, 252);
                    font-weight: 700;
                    font-size: 1.5rem;
                    cursor: pointer;
                    width: 70%;
                }

                .post-by{ 
                    position: absolute;
                    bottom: 18px; 
                    color:rgb(255, 145, 0);
                    font-size: .8rem;
                    font-weight: 700;
                }
                .post-type{ 
                    position: absolute;
                    top: 18px; 
                    color:rgb(255, 255, 255);
                    font-size: 1rem;
                    font-weight: 900; 
                    text-shadow: 1px 1px 1px rgb(137, 136, 136);
                }


            }
        }
    }
}