.btn-add-cart{ 
    width: 100%;
    height: 46px; 
    background: #4379EB;
    border: 1px solid #DFE4EA;
    border-radius: 100px; 
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;  
    color: #FFFFFF;
    fill: #DFE4EA !important;
   img{ 
    width:15px
   }
   &:hover{ 
     background: #5a8af0 !important;
     color: #FFFFFF; 
   }
   &:active{ 
     background: #5a8af0 !important;
     color: #FFFFFF; 
   }
   &:focus{ 
     background: #5a8af0 !important;
     color: #FFFFFF; 
   }
}

.request-quote-desc-text{ 
  font-style: normal;
  font-weight: 400;
  font-size: .8rem;
  line-height: 16px; 
  color: #1e1e1e;
  margin-bottom: 20px;
}