.TopLocation{
    .card-image{
        width: 100%;

        height: 350px;
        overflow: hidden;
        z-index: 0;

        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: all .5s ease-in;

            &:hover{
                transform: scale(1.1);
                cursor: pointer;
            }
        }
    }

    .card-image-skeleton{ 
        height: 300px;
    }

    .details{
        width: 100%;
        display: flex;
        justify-content: center; 
        z-index: 1;

        .details-inner{
            width: 70%;
            width: 240px;
            height: 70px;  
            display: flex;
            align-items: center; 
            margin-top: -50px;
            background-color: rgb(255, 255, 255);
            padding: 20px;
            z-index: 1;

            .title{ 
                font-size: .8rem;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: left;

            }

            .desc{  
                font-size: .7rem;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0px;
                text-align: left; 

                svg{
                    margin-right: 3px;
                }
            }
        }
    }






.swiper {
    width: 100%;
    // height: 100%;

    @media (max-width:600px) { 
      padding-bottom: 30px; 
   }
   @media (min-width:600px) {
      padding-bottom: 60px; 
   }
  }
  
  .swiper-slide {    
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    @media (min-width:600px) { 
        padding-bottom: 50px;
        width: 319px !important;
     }  
      
    @media (max-width:600px) {  
        width: 302px !important;
    }  
  }
   
  
//   .swiper-slide {
//     width: 246px;
//   }
  
//   .swiper-slide:nth-child(2n) {
//     width: 246px;
//   }
  
//   .swiper-slide:nth-child(3n) {
//     width: 246px;
//   }
 
 
}