
.ads-big { 
    .ads-element{
        height: 50px;
        width: 80%;
        position: absolute;
        
        @media (max-width:600px) { 
            bottom: 0;
            left: 30px;
        }
        @media (min-width:600px) { 
            bottom: 20px;
            left: 30px;
        }
    }

}

.ads-small{
    .ads-element{
    height: 50px;
    width: 80%;
    position: absolute;
    
    @media (max-width:600px) { 
        bottom: 0;
        left: 30px;
    }
    @media (min-width:600px) { 
        bottom: 20px;
        left: 30px;
    }
}
}