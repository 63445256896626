.deliveryAddressItems{

    /* COLOR/White */

            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 8px;
            
    &.active{
        border: 1px solid #3378F3;
    }
    .street-name{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: .8rem;
        line-height: 19px; 
        color: #333333; 
    }

    .user-info{ 
        font-size: .77rem;
        margin-top: 10px;
        img{
            width:1rem
        }
    }

    .button-container{
        margin-top: 20px;
        border-top: 1px solid #DFE4EA;

            .btn{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 500;
                font-size: .9rem;
                line-height: 18px;  
                color: #3378F3;
            }
        }

 .label-index{
        width: 20px;
        height: 20px; 
        background: #3378F3;
        border-radius: 50px;
        text-align: center;
        color: #DFE4EA;
        font-size:.8rem
    }
    
 
    .addressType{
       button{
        width: 100%;
        height: 28px;  
        background: rgba(242, 153, 74, 0.09);
        border-radius: 100px;
        font-family: 'Lato'; 
        font-style: normal;
        font-weight: 400;
        font-size: .7rem;
        line-height: 18px; 
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(242, 153, 74, 0.09); 
        color: #F2994A;

       }
    }

   
}