@import "./../../../../../Assets//Scss/primaryColor";

.updates{
    .updates-inner{
        background: linear-gradient(180deg, #3378F3 0%, #48E2E4 100%);
        box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        // height: 50vh; 

        .subform{
            width: 523px;
            height: 48px; 
            background: #FFFFFF;
            border-radius: 5px;
        }
        h2{  
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            letter-spacing: -0.01em; 

            color: #F9F9F9;
        }
        button{ 
            padding: 12px 24px;

            position: absolute;
            width: 245px;
            height: 48px; 

            /* Blue for Buy & Home */

            background: #3378F3;
            box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.4);
            border-radius: 8px;
        }
    }
}


 