.orderCompletedDetails{
        .title{ 
            font-style: normal;
            font-weight: 700;
            font-size: .9rem;
            line-height: 19px; 
            color: #626262;
            img{
                width: 15px;
            }
        }

        .value{ 
            font-style: normal;
            font-weight: 400;
            font-size: .8rem;
            line-height: 19px; 
            color: #626262;
        }

 
}