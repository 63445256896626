.seller-profile-page{
    .security-btn-default{ 
        height: 44px; 
        background: #34BA96;;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        color: #f2f5f7;
        font-size: 1rem;
        font-weight: 700;
    }

    .form-control{
        border-radius: 100px;
    }

    label{ 
        font-style: normal;
        font-weight: 400;
        font-size: .8rem; 
        color: #333333;
        padding-bottom: 10px;
    }
}