.seller-store{
  margin: 0;
  .container{
    height: 100%;
    padding: 0px 20px 0px 20px ;
  }
  
  background-color: #E6EEFF;
    @media (max-width: 600px) {
     
    }
    @media (min-width: 600px) {
     
    }
  
}
