.ProjectStatusUpdate{

    .title{  
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0px;
        text-align: center; 
    }
    .description{    
        font-size: .7rem;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center; 
    }
    label{  
        font-size: .7rem;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;

    }

    input::placeholder{  
        font-size: .7rem;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;

    }

    input{ 
        padding: 10px;
        border-radius: 40px; 
        border: 1px solid #e8e8ea;
        
        &:focus{ 
            border: 1px solid #b4b4b4
        }
    }
 

    .btn-cancel{
        width: 156px;
        height: 38px; 
        display: flex;
        padding: 10px;
        border-radius: 100px;
        border: 1px solid #3A0CA3;
        font-size: .7rem;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
        align-items: center; 
        justify-content: center;

    }
    
    .btn-proceed{
        background: #E73F3F;
        color: white;
        display: flex;
        width: 156px;
        height: 38px;
        padding: 10px;
        border-radius: 100px;   
        font-size: .7rem;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}
