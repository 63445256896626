
.home-seller-inner {

  padding: 2rem 2rem;
  background: #ffffff;
  width: 100%;
  

  .page-title {
    line-height: 24px; 
    font-size: 1rem;
    font-weight: 600;
    color: #303030;
    margin-bottom: 16px;
  }

  .bottom-row{
    min-height: 200px;;
  }

  .col-home {
    margin: 10px 0px;
    .card {
      background: #ffffff; 
      border-radius: 10px;
      border: 1px solid #f3efef;

      .card-header {
        background: #ffffff;
        font-weight: 600;
        font-size: 1rem;
        line-height: 24px;
        color: #303030;
        border: none;
      }
    }
  }
}
