.enterpriseWareHouseShipToSiteDetail{
   margin-top: 17px;

   .enterprisePageTitle{
    h2{
            font-style: normal;
            font-weight: 400;
            font-size: 1.25rem;
            line-height: 24px; 
            color: #303030;
        }

    }

    .card{
        border: 1px solid #DFE4EA !important
    }
    .site-title {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 24px; 
        color: #303030;
        margin-bottom: 10px;
    }  
    .site-address{ 
        font-style: normal;
        font-weight: 400;
        font-size: .8rem;
        line-height: 20px; 
        color: #333333; 
        flex: none;
        order: 1;
        flex-grow: 0;
    }  
    .site-contact{
        font-style: normal;
        font-weight: 400;
        font-size: .8rem;
        line-height: 20px; 
        color: #333333; 
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    .site-product{
        .row{
            border-bottom: 1px solid #DFE4EA; 
            height: 50px;
            margin-bottom: 5px;
            margin-top: 5px;
            .col-10, .col-2{
                font-style: normal;
                font-weight: 500;
                font-size: .8rem;
                line-height: 150%; 
                color: #606060;   

                .product-image-container{ 
                    background-color: #60606007; 
                    border-radius: 5px;
                    margin-right: 15px;
                    margin-bottom: 2px;
                }

                .product-title-container{   
                }
            }
        }
    } 

    .btn-remove{
        font-weight: 500;
        font-size: 14px;
        line-height: 150%; 
        color: #FF0000; 
        flex: none;
        order: 1;
        flex-grow: 0;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product-image{
        width:33px;
        margin-right: 6px;
    }

    .btn-continue{
        width: 288px;
        height: 40px;
        // background: #BDBDBD;
        // border-radius: 100px;
        // color: #828282;
        background: #F5A623;
        border: 1px solid #DFE4EA;
        color: #ffffff;

        border-radius: 100px;
        margin-top: 20px;
        font-style: normal;
        font-weight: 700;
        font-size: .9rem;
        line-height: 18px; 
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center; 
       
        &:hover{ 
            // background: #BDBDBD;
            // border-radius: 100px;
            // border: 1px solid #959595;

            background: #F5A623;
            border: 1px solid #DFE4EA;
            color: #ffffff;
        }
        &:active{ 
            // background: #BDBDBD;
            // border-radius: 100px;
            // border: 1px solid #959595;
            background: #F5A623;
            border: 1px solid #DFE4EA;
            color: #ffffff;
        }
    }

    .delivery-btn{
        width: 100%;
        height: 46px; 
        border: 1px solid #008e07;
        border-radius: 100px;
        font-style: normal;
        font-weight: 700;
        font-size: .9rem;
        line-height: 18px;  
        color: #008e07;
    }

    .go-btn{
        width: 100%;
        height: 46px; 
        background: #F5A623;
        border-radius: 100px;
        font-style: normal;
        font-weight: 700;
        font-size: .9rem;
        line-height: 18px; 
        color: #FFFFFF;
    }
}