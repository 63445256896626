@import "../../Assets/Scss/primaryColor";
.modal-prompt-inner-main {
  margin-top: 200px;

  .modal-body { 
    height: 150px;
  }

  @media (max-width: 600px) { 
      width: 100%; 
    } 

    @media (min-width: 600px) { 
      width: 436px; 
    } 

  // .modal-content {
  background: #FFFFFF;
  border: 1px solid #DFE4EA;
  border-radius: 28px;
  //   }

  .modal-content{
      background: #FFFFFF;
      border: 1px solid #DFE4EA;
      border-radius: 28px;
  }
   

  .icon-container{
    height: 78px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:  #DFE4EA;
    box-shadow: 2px 2.5px 3px rgba(14, 14, 44, 0.15);
    border: 2px solid #DFE4EA;

    i{
      font-size: 3rem;
      color: #070707; 
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .contText{
    font-size: 1rem;
  }

   .btn{
    width: 100px;
   }

  
 
  .btn-close {
        height: 15px;
        width: 15px; 
        border: 1px solid grey;
        border-radius: 50px; 
        font-size: .7rem !important;
    } 
  }
 