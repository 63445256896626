.enterprisePLP{
    padding: 20px  20px  50px  20px; 

    .plp-form-btn{
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        padding: 8px 50px;
        font-size: 0.9rem;
        background: #F5A623 !important;
    } 

    .form-check-input:checked {
        background-color: #F5A623;
        border-color: #F5A623;
      }

       .file-upload { 
        border: 1px solid #F5A623;
        .form-label{ 
            color:  #F5A623; 
        } 
      }
}