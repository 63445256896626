@import "../../../../../Assets/Scss/primaryColor";
.home-slider{
    margin: 20px 0;
   
    @media (min-width:972px) { 
        // height: 528px;
    }
   
   @media (max-width:972px) {  
       margin-bottom: 30px;
    } 
    .card-body{ 
        background: linear-gradient(180deg, #3378F3 0%, #023da3 100%);
        border-radius: 8px;
       
        .text-col{
            @media (max-width:972px) { 
              text-align: center;
            }
            @media (min-width:972px) { 
              text-align: left;
            }
        }

        h2{
            @media (min-width:972px) { 
                font-size: 3rem;
                font-weight: 700; 
                line-height: 58px; 
                color: #FFFFFF;
            }
            @media (max-width:972px) { 
                font-size: 1.5rem;
                font-weight: 700; 
                line-height: 30px; 
                color: #FFFFFF;
                text-align: center !important;
            }
        }

        .slider-desc{ 
            @media (min-width:972px) { 
                font-size: .9rem;
                color: #FFFFFF;
            }

            @media (max-width:972px) { 
                font-size: .9rem;
                color: #FFFFFF;
                text-align: center;
            }
        }

        .btn-getstarted{  
            width: 164px;
            height: 44px; 
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 100px;

            font-weight: 600;
            font-size: .9rem;
            line-height: 18px;   
            color: #3378F3;  

            @media (max-width:972px) { 
                text-align: center;
            }
            @media (max-width:972px) { 
                
            }
        }
    }
    .carousel{ 
        .carousel-control-next{  
            background-color: #eef0f2 !important; 
            border-radius: 50px; 
            height: 48px !important;
            width: 48px !important; 
            display: flex;
            justify-content: center;
            align-items: center;
            .d-icon{
                font-size: 2.6rem !important;  
                color: #333333;
                font-weight: 300 !important; 
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .islarge{
                font-size: 2.6rem !important;  
                color: #333333; 
            } 
        }

        .carousel-nav-right{
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center; 
            padding: 0px !important;

            
            .islarge-s{
                font-size: 1.3rem; 
                font-weight: 600;
                margin: 0!important;
                padding: 0px !important;
            } 
        }

        .carousel-indicators{
            button{
                width:5px;
                height:5px;
                border-radius: 10px !important;
                
            }


            @media (max-width:972px) {  
               bottom: -18px !important; 
            }
        }
    }

    img{   
        @media (max-width:972px) { 
             height: 300px;
             width: 100%;
        }
    }

    .sliderImage{
        @media (max-width:972px) { 
            padding-bottom: 18px; 
            width: 100%; 

       }  
       
    }

    .b360-img{
        width: 100%;
        padding: 50px;
    }



@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .b360-img {
    -webkit-animation: rotating 15s linear infinite;
    -moz-animation: rotating 15s linear infinite;
    -ms-animation: rotating 15s linear infinite;
    -o-animation: rotating 15s linear infinite;
    animation: rotating 15s linear infinite;

    &:hover{
        animation:none;

    }
  }
}