@import "./../../Assets/Scss/primaryColor";
  
.footer { 
  background-color: $white;
  padding: 50px 0 20px 0;
  border-bottom: 1px solid rgba(235, 235, 235, 0.747);
    
    &.footer-estate{ 
      background-color: #f4f4f4!important;
    }

   .footer-widget-title{
    font-weight: 500;
    font-size: 1.1rem;  
    line-height: 140.62%;
   }
   .footer-logo-item{
    width: 30%;
   }

   .footer-widget-content{
     .nav-link{ 
      padding-left: 0px !important; 
      font-weight: 400;
      font-size: .78rem;
      line-height: 140.62%;
      color: $npf_dark_1;
      transition: ease-in-out .5s  all; 

      &:hover{ 
       color: $primary;
       font-weight: 700;
      }
     }

     span{
       border:1px solid rgba(0, 0, 0, 0.596);
       padding:10px;
       border-radius: 50px;
     }

     .location-address{
      font-size: .78rem;
     }
   }

   .widget h4 {
      cursor:pointer;  
      font-size:18px;
      font-weight:bold;
      padding-bottom:5px;
   }

   .footer-bottom {
      background:#d8d8d8;
      padding:15px 0;
      border-top:1px solid #d9d9d9;
      font-size:11px;
      color:#777;
   }
    .footer-bottom-estate {
      background-color: #f4f4f4!important;
      padding:15px 0;
      border-top:1px solid #d9d9d9;
      font-size:11px;
      color:#777;
  }
   
  }

 
   .footer-widget-content{
      padding-bottom: 30px;
      .nav-link{ 
      font-size:.75rem;
      }
      .about-info{ 
      font-size:.75rem;
      }
  }
   
   @media only screen and (max-width: 479px) { 
    .footer { 
      padding-bottom: 5px !important; 
      padding-bottom: 10px !important;   
        .widget_content{
           display:none;
       }
       .active .widget_content{
         display:inline;  
       }
      
       .widget h4 { 
           border-bottom:1px solid #ddd;
       }   
       .widget h4::after {
        content:"\203A";
        color: #5b5b5b;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        background: #dfdfdf;
        display: inline-block;
        text-align: center;
        float: right;
      }

       .active.widget h4:after {
           content:"\2039";    
           background:#ccc;
       }
   }

  
  }

  .list-group{
    background-color: rgba(255, 255, 255, 0) !important;
    .list-group-item{
      background-color: rgba(255, 255, 255, 0) !important;

    }
   }

  .footer-credit{ 
    padding: 40px 0 30px 0;
    background-color: $white;
    font-size: .8rem; 
    font-weight: 400; 
    line-height: 150%; 
    color: #6B7589;  
  }
 
 
    