$dark: rgb(17, 16, 16);
$npf_dark: rgb(17, 16, 16);
$npf_dark_1: rgb(22, 22, 22);
$npf_dark_2: #303030;
$grey: rgb(180, 178, 178);
$white: rgb(255, 255, 255);
$white-6: #fbfcfd;
$light: #f8f8f8;
$light1: #d8d8d8;
$light2: #fafafa; 
$light3: #f9f9f9;
$primary: #3378f3;
$primary-seller: #34ba96;
$primary-estate: #3A0CA3; 

$primary-opaque: rgb(0, 0, 101, 0.726);

$primary-color: #2d2045;
$secondary-color: #dd5789;
$tertiary-color: #2d2045;

$secondary: #022444;
$tertiary: rgb(251, 254, 0);
$default: rgb(28, 128, 22);
$main-shadow: 0px 3px 3px rgba(0, 0, 0, 0.161);

$white-color: #ffffff;
$white-color-1: #cecece;
$white-color-2: #f7f9fc;
$white-color-3: #f7f9fc;
$white-color-4: #f0f0f0;
$white-color-5: #eeeeee;
$white-color-6: #eeeeee;
$white-color-7: #EFF2F6;

$dark-color: #000000;
$dark-color-1: #212121;
$dark-color-2: #bbb8b8;
$dark-color-3: #767676;
$dark-color-4: #00000029;
$dark-color-5: #767676;
$dark-color-6: #333333;

$button-font-size: 12px;

$scoreRed: #f44336;
$scoreYellow: #ffeb3b;
$scoreYellow-1: #ffaf25;
$scoreGreen: #058100;
$scoreGreen-1: #14934f;

$sellerBg: #303030;
$sellerPrimary: #34ba96;
$seller-light: #f7f5f5;

$navLinkBlue:#4379EB