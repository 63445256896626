.profile-header {
    display: flex;
    background: #FFFFFF;
    border: 1px solid #DFE4EA;
    border-radius: 8px;
    padding:32px 31px;
    height: 168px;
    width: 100%;
    margin-top: 2rem;
    border-radius: 8px;


    .avatar-name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        
        .profile-name {
            margin-left: 1rem;
            margin-top: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            color: #333333;
            height: 18px;
            font-size: 24px;

        }
    }
}