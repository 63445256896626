@import "../../../../../../../Assets/Scss/primaryColor";

.enterpriseWareHouseShipToSite{
   margin-top: 17px;

   .enterprisePageTitle{
    h2{
            font-style: normal;
            font-weight: 400;
            font-size: 1.25rem;
            line-height: 24px; 
            color: #303030;
        }

    }
    .select-site{
        margin-top: 30px;

        label{
            font-style: normal;
            font-weight: 400;
            font-size: .88rem;
            line-height: 19px; 
            color: #333333; 
            margin-bottom: 10px;
        }

        .form-control{
            width: 100%;
            height: 46px; 
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 100px;
            font-size: .88rem; 
            
            option{
                font-size: .88rem; 
            }
        }
    }

    .site-moveable{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px; 
        color: #333333; 
    }
    
    table{
        td{
            font-style: normal;
            font-weight: 500;
            font-size: .8rem;
            line-height: 150%; 
            color: #606060; 
            flex: none;
            order: 1;
            flex-grow: 0;   
        }
    }

    .btn-remove{
        font-weight: 500;
        font-size: 14px;
        line-height: 150%; 
        color: #FF0000; 
        flex: none;
        order: 1;
        flex-grow: 0;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product-image{
        width:33px;
        margin-right: 6px;
    }

    .btn-continue{
        width: 288px;
        height: 40px;
        // background: #BDBDBD;
        // border-radius: 100px;
        // color: #828282;
        background: #F5A623;
        border: 1px solid #DFE4EA;
        color: #ffffff;

        border-radius: 100px;
        margin-top: 20px;
        font-style: normal;
        font-weight: 700;
        font-size: .9rem;
        line-height: 18px; 
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center; 
       
        &:hover{ 
            // background: #BDBDBD;
            // border-radius: 100px;
            // border: 1px solid #959595;

            background: #F5A623;
            border: 1px solid #DFE4EA;
            color: #ffffff;
        }
        &:active{ 
            // background: #BDBDBD;
            // border-radius: 100px;
            // border: 1px solid #959595;
            background: #F5A623;
            border: 1px solid #DFE4EA;
            color: #ffffff;
        }
    }


    .btn-continue-estate{ 
        background: $primary-estate !important;  
       
        &:hover{  
            background: $primary-estate !important;  
        }
        &:active{  
            background: $primary-estate !important;  
        }
    }
    .ship-quantity{
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;

        @media (min-width: 600px) {
            width: 150px;
            height: 40px;
        }
    }
}