.avatar {
    background: #FFFFFF;
    border: 2px solid #4379EB;
    width: 104px;
    height: 104px;
    padding: 7px;
    border-radius: 100%;

    img {
        width: 100%;
        height: 100%;
    }
}