

    .navbar-level2{
        padding: 2px 0 !important;

        .nav-item{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: .9rem;
            line-height: 18px; 
            display: flex;
            align-items: center;  
            color: #333333; 
            flex: none;
            order: 4;
            flex-grow: 0;
        }

         

        .wallet-bal{ 
            color: #00C12B;
            font-weight:bolder ; 
            margin-left: 7px; 
        }
    }