@import "../../../../Assets/Scss/primaryColor";
.buyerwraper {  
  @media (min-width:1092px) {
    .col-25{ 
      flex: 0 0 auto;
      width: 20%;
    } 
    .col-85{ 
        flex: 0 0 auto;
        width: 80%;
      } 
    .col-100 {
      flex: 0 0 auto;
      width: 100%; 
    }
  }


  @media (max-width:1092px) {
    .col-25{ 
      flex: 0 0 auto;
      width: 100%;
    } 
    .col-85{ 
        flex: 0 0 auto;
        width: 100%;
      } 
  }
 
}
