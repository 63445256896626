@import "../../../../../../../Assets/Scss/primaryColor";
.addproduct {
}
.addproduct-modal {
  .form-group {
    margin-top: 20px;
    .form-control {
      height: 45px;
      border: none;
      background: #ecf1f4;
      box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
      border-radius: 8px;
      font-weight: 500;
      font-size: 0.7rem;
      line-height: 150%;
      color: #868484;
    }

    textarea {
      width: 100% !important;
      height: 122px !important;
      background: #ecf1f4;
      box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
      border-radius: 8px;
    }
  }

  .uploadImg {
    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #000000;
    }
    .desc {
      font-weight: 500;
      font-size: 10px;
      line-height: 140%;
      color: #868484;
    }

    .addbtn {
      width: 46px;
      height: 63px;
      left: 561px;
      top: 683px;
      background: rgba(52, 186, 150, 0.1);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      i {
        cursor: pointer;
      }
    }
    .uploadedImgCont {
      position: relative;
      width: 63px;
      height: 63px;
      border: 1px solid #dedede;
      border-radius: 10px;
      .btn {
        position: absolute;
        top: 0;
        right: 0;
      }
      img {
        width: 63px;
        height: 53px;
      }
    }
    .inst {
      font-weight: 500;
      font-size: 10px;
      line-height: 140%;
      color: #868484;
    }
  }

  .btn-container {
    .btn-preview {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 0.9rem;
      width: 217px;
      height: 49px;
      background: #ffffff;
      border: 1px solid $sellerPrimary;
      box-sizing: border-box;
      border-radius: 8px;
    }
    .btn-upload {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 0.9rem;
      width: 217px;
      height: 49px;
      background: $sellerPrimary;
      box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
        inset 0px -1px 0px rgba(14, 14, 44, 0.4);
      border-radius: 8px;
    }
  }

  .btn-upload-prev {
    width: 100%;
    height: 49px;
    background: #34ba96;
    box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
      inset 0px -1px 0px rgba(14, 14, 44, 0.4);
    border-radius: 8px;
    font-weight: bolder;
  }

  .commissionText {
    font-size: 0.6rem;
    font-weight: bold;
  }
  .btn-remove {
    padding: 1px !important;
    width: 20px;
    height: 20px;
    i {
      font-weight: bolder;
      font-size: 1rem;
    }
  }
  small {
    font-size: 0.7rem;
  }

  .toggle-form-row {
    text-align: center;
    overflow: hidden;
    padding: 2px;
    font-size: 0.7rem;
    padding-bottom: 0px !important;
  }

  .toggle-form-row span {
    display: inline-block;
    position: relative;
    padding: 1px 10px;
    padding-bottom: 0px;
    margin-top: 8px;
    font-size: 0.7rem;
  }

  .toggle-form-row span:before,
  .toggle-form-row span:after {
    content: "";
    display: block;
    position: absolute;
    width: 999px;
    border-top: 1px solid rgb(209, 209, 209);
    height: 3px;
    top: 50%;
    margin-top: -3px;
  }

  .toggle-form-row span:before {
    right: 100%;
  }
  .toggle-form-row span:after {
    left: 100%;
  }

  //  Add and select input field
  .dropdown-form-element {
    margin: 20px auto;
    position: relative;

    .dropdown-select {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      position: relative;
      overflow: hidden;
      background-color: #fff;
      box-sizing: content-box;

      .select-btn {
        position: absolute;
        width: 30px;
        top: 0;
        right: 0;
        height: 100%;
        border-left: 1px solid #ddd;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .select-btn:active {
        background-color: #f1f1f1;
      }
    }

    .dropdown-list {
      width: 100%;
      background-color: #fff;
      border: 1px solid #ddd;
      z-index: 10;
      list-style: none;
      margin: 0;
      padding: 0;
      height: 150px;
      overflow: auto;
      position: absolute;
      margin-top: 2px;

      .dropdown-item {
        padding: 5px 20px;
        margin: 0;
        cursor: pointer;
        .form-add-new {
          border-radius: 8px !important;
          font-size: 0.7rem;
          color: rgb(56, 56, 56);
          &:focus {
            box-shadow: none !important;
            border: 1px solid rgb(230, 230, 230) !important;
            outline: rgb(230, 230, 230);
            border-radius: 5px !important;
          }
        }
        .btn {
          border: 1px solid rgb(230, 230, 230) !important;
          color: rgb(170, 169, 169);
          &:hover {
            color: rgb(247, 247, 247);
          }
        }
        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }
}
.add-new-price {
  font-size: 0.9rem;
  // text-decoration: underline !important;
}
