@import "../../Assets/Scss/primaryColor";
.alert-modal-inner-main {

  .alert-modal-inner {
    width: 401px;
    height: 489px !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 24px;

    .icon-container {
      width: 82px;
      height: 82px;
      display: flex;
      justify-self: center;
      align-self: center;
      justify-content: center;
      align-items: center;
      margin: 0 auto;

      .d-icon {
        font-size: 3rem;
        font-weight: 900;
        color: $white;
      }
    }

    .btn-close {
        height: 15px;
        width: 15px; 
        border: 1px solid grey;
        border-radius: 50px; 
        font-size: .7rem !important;
    }
  }
  .alertType {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #010f2c;
    margin-top: 60px;
  }

  .gap-2 {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contText {
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 150%;
    text-align: center;
    color: #868484;
    width: 80%;
    margin: 0 auto;
    padding-top: 20px;
  }

  .btn0 {
    padding: 10px 20px; 
    height: 49px;
    font-size: 0.9rem;
    background: #3378f3; 
  }

  @media (max-width: 1300px) {
    .alert-modal-inner {
      width: 100% !important;
    }
    .btn0 {
      width: 190px !important;
    }
  }
}

 
  .noteText{
    font-size: .4rem !important;
  }
 