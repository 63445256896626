.AccountVerificationDrawer{
    .offcanvas-header{
        .offcanvas-title{ 
            &.h5{
                font-weight: 700;
                font-size: 1.4rem;
                line-height: 32px;  
                display: flex;
                align-items: center; 
                color: #333333;
            }
        }
        .btn-close{ 
            border: 1.5px solid #333333 !important;
            border-radius: 50px;
            font-size: .5rem !important;
        }
    }

    .view-cart-btn{
        width: 100%;
        height: 46px;   
        background: #4379EB;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;   
        color: #FFFFFF;
    }

    .view-cart-btn-ent{
        background: #F5A623;
        &:active{ 
           background: #cf8103;
           border: 1px solid #ffffff;
        }
    }
    .verify-btn{
        width: 100%;
        height: 46px; 
        font-weight: 400;
        border-radius: 100px;
        font-size: .8rem;
        line-height: 18px; 
        color: #ffffff; 
        margin-top: 20px;
        background-color: #3A0CA3;
        margin-bottom: 40px;

        &:hover{ 
            width: 100%;
            height: 46px; 
            font-weight: 600;
            border-radius: 100px;
            font-size: .8rem;
            line-height: 18px; 
            color: #ffffff; 
            margin-top: 20px;
            background-color: #481fa9;
        }
    }

 

    .title{
        font-weight: 700;
        font-size: 1rem;
        line-height: 24px;  
        display: flex;
        align-items: center; 
        color: #333333;
    }
    .total-name{
        font-weight: 400;
        font-size: .9rem;
        line-height: 19px; 
        color: #333333;
    }
    .total-value{ 
        font-weight: 400;
        font-size: .9rem;
        line-height: 19px; 
        color: #333333;
    }

    .cart-item-list-image{
        width: 90px;
        height: 95px;
        background: #D9D9D9;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .cart-item-name{
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center; 
        color: #333333;
    }
    .cart-item-variation{
        font-weight: 400;
        font-size: .8rem;
        line-height: 17px;  
        color: #333333; 
    }

    .cart-item-price{
        font-weight: 700;
        font-size: .8rem;
        line-height: 17px;  
        color: #F2994A;
    }

    .cart-item-quantiy{
        font-weight: 400;
        font-size: .8rem;
        line-height: 17px;  
        color: #333333;
        margin-top:10px;
    }
}