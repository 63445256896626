.bank {
  padding-right: 2rem;
  paddingg-top: 2rem;
  .heading {
    height: 24px;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #303030;
  }
}
