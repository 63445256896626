@import "../../../../../../../Assets/Scss/primaryColor";
.enterpriseSettingsBusinessProfile{
    .buyerPageTitle{
        a{ 
          color: #F5A623 !important;
        }
    }

       .form-control{
        background: #EFF2F6;
        border: 1px solid #DFE4EA;
        border-radius: 100px;

    }

    .btn{
        width: 100%;
        background: #FFFFFF; 
        border: 1px solid #F5A623;
        border-radius: 100px;
        color:#F5A623;
        margin-top: 20px; 
        font-style: normal;
        font-weight: 600;
        font-size: .8rem;
        line-height: 30px;
    }
    .btn-estate{
        width: 100%;
        background: $primary-estate; 
        border: 1px solid $primary-estate;
        border-radius: 100px;
        color: $white;
        margin-top: 20px; 
        font-style: normal;
        font-weight: 600;
        font-size: .8rem;
        line-height: 40px; 
    }
 }
    