.LastOrder{
    h2{
        font-size: 1.3rem !important;
        line-height: 22px;
    }

    h5{
        font-size: .9rem;
    }
    .quick-reorder{
        font-size: 2.5rem !important; 
    }

    .d-pin{
        font-size: 2rem;
    }
    .d-dot{
        font-size: 1rem;
        background-color: rgb(255, 255, 255);
        width: 10px;
        height: 10px;
        border-radius: 50px;
        display: inline-block;
    }

    .product-info{ 
        background-color: rgb(21, 0, 74);
        border: 1px solid rgb(236, 236, 236);
    }

    .lastOrderImg{
        height: 520px;
    }
    


    .image-container{
        @media (max-width:600px) { 
            height: 310px;
            width: auto; 

            .last-img{
                width: 100%;
                padding: 40px;
            }
        }
        @media (min-width:600px) { 
            height: 410px;
            width: auto;

            .last-img{
                width: auto;
                padding: 20px;
            }
        }
        @media (min-width:992px) { 
            height: 404px;
            width: 100%;

            .last-img{
                width: 80%;
                padding: 40px;
            }
        }

        @media (min-width:1424px) { 
            height: 515px;
            width: 100%;

            .last-img{
                width: 90%;
                height: auto;
                padding: 40px;
            }
        }
        
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn-getstarted-0{
        width: 164px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 18px;
        color: #3378F3; 
    }

    .cart-btn-wraper{
        @media (max-width:992px) { 
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}

.lastOrderModal{
    margin-top: 50px; 

    @media (max-width: 600px) { 
        width: 100%; 
      } 

      @media (min-width: 600px) { 
        width: 436px; 
      } 

    // .modal-content {
    background: #FFFFFF;
    border: 1px solid #DFE4EA;
    border-radius: 28px;
    //   }

    .modal-content{
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 28px;
    }
    .form-control{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        height: 50px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px; 
        font-feature-settings: 'liga' off; 
        color: #828282;

      

        &:focus{
            outline: none !important;
            box-shadow: none !important;
        }
    }

    select{
        font-size: .8rem !important;
        option{ 
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: .8rem !important;
            line-height: 18px; 
            font-feature-settings: 'liga' off; 
            color: #828282;
        }

    }

    .apply{  
        width: 100%;
        height: 56px; 
        background: #3378F3; 
        color: #DFE4EA;
        font-weight: 800;
        box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
        border-radius: 100px;
    }

    .apply-outline{ 
        width: 100%;
        height: 56px; 
        font-weight: 800;
        color: #3378F3;
        background: #3379f300; 
        box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
        border-radius: 100px;

    }
   
   .btn-close {
        height: 15px;
        width: 15px; 
        border: 1px solid grey;
        border-radius: 50px; 
        font-size: .7rem !important;
    } 
}