.enterprisePaymentSuccessful{
    text-align: center;
    .title{
        font-size: 1rem;
        font-weight: 600;
        margin-top: 20px;
    }
    .description{ 
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .btn{
        width: 150px;
        font-size: .9rem;
        border: 1px solid rgb(41, 41, 41);
    }
}