@import "../../../../../../../Assets/Scss/primaryColor";
.EstateProjectDetails{
    .page-title{
        display: flex;
        align-items: center; 
        flex-wrap: wrap;

        h2{ 
            font-style: normal;
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 38px; 
            color: #333333;
            display: flex;
        } 

      h2 {
        margin-right: 10px; 
    }


    
    .ids {
        margin-right: 10px;   
        font-size: .9rem;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: right;

    }

    .open{    
        display: flex;
        justify-content:center;
        padding: 10px;
        border-radius: 15px;
        gap: 10px;  
        font-size: .8rem;
        font-weight: 500;
        line-height: 10px;
        letter-spacing: 0px;
        text-align: right;
        color: #01A63E;
        background: #E8F6ED;
        text-align: center;




    }
     
    @media screen and (max-width: 767px) {
        
       flex-direction: column;
       align-items: flex-start;
      
    }
    
    
}
    .button-container{
        position: relative;

        svg{
            margin-right: 5px;
        }

        .btn-edit{ 
            border: 1px solid $primary-estate;  
            color: $primary-estate;  
            border-radius: 20px;  
            margin-right: 10px;
            font-size: .78rem; 
        }

        .btn-closen{
            border: 1px solid #E73F3F; 
            color: #E73F3F; 
            border-radius: 20px;  
            font-size: .78rem;
        }
    }

   

    .created-time{
        font-size: .9rem;  
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        color: #797A7C; 
        margin-top: 16px;

    }

    .price{ 
        font-size: 2rem;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;  
        color: #3A0CA3;

        .unit{
            font-size: .7rem;
            color: #333333;
            font-weight: 500;
        }
    }

    .label{  
        font-size: .8rem;
        font-weight: 600; 
        letter-spacing: 0px;
        text-align: left; 
        color: #303132;

    }

    .amount{  
        font-size: .8rem;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left; 
    }
   

    .subscribers-card{
        margin-top: 60px;

        .title{
           font-size: .8rem;
           font-weight: 600;
           color: #000000;
           line-height: 30px;
        }

        .see-all{ 
           font-size: .8rem;
           color: #080808;
           font-weight: 500; 
           line-height: 30px;
        }
    }

    thead{
        td{ 
           font-size: .8rem;
        }
    }
    tbody{
        td{ 
            .profile-avarter{
                  display: inline-flex;
                  height: 25px;
                  width: 25px;
                  justify-content: center;
                  align-items: center;
                  background: #E4E4E5;
                  border-radius: 50px;
                  margin-right: 4px;
                  font-size: .8rem; 
            }
            
            font-size: .8rem;
        }
    }

    .lg-image{
        border-radius: 10px;
    }
}