.brands{
    .card{ 
        margin: 5px;
        .card-body{
            text-align: center;
            width: 100%;
            height: 150px; 
            background: #FFFFFF;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
       

        &:hover{
            background: rgba(0, 0, 0, 0.5);
            border-radius: 8px;
            cursor: pointer;
        }
      }
      .card-footer{ 
        border: none !important;
        text-decoration: none !important;
        text-overflow: ellipsis; 
        overflow: hidden;
        white-space: nowrap;  
 
       
        a{ 
           text-decoration: none !important;
           font-style: normal;
           font-weight: 700;
           font-size: 16px;
           line-height: 19px; 
           text-align: center; 
           color: #171717;
        }
      }
    }

    .section-header{
        h2{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 800;
            font-size: 1.6rem;
            line-height: 38px;
            display: flex;
            align-items: center; 
            color: #333333;
        }
        .nav-icon{
            width:15px;
        }

        .col-1{
            font-size: .8rem;
            font-weight: 400; 
            line-height: 22px; 
            color: #3378F3; 
            flex: none;
            order: 0;
            flex-grow: 0;

            button{
                border: 1px solid rgba(255, 255, 255, 0);

                &:active{
                    border: 1px solid rgba(255, 255, 255, 0);
                }
                &:focus{
                    border: 1px solid rgba(255, 255, 255, 0);
                }
            }
        } 
    }

   .form-group{
      position: relative;
        .form-control{
            box-sizing: border-box;  
            background: #EFF2F6;
            border: 1px solid #ecedef;
            border-radius: 100px;
            padding-left: 50px;

            &::placeholder{ 
                font-style: normal;
                font-weight: 400;
                font-size: .8rem;
                line-height: 18px; 
                display: flex;
                align-items: center;
                font-feature-settings: 'liga' off; 
                color: #828282;
                padding-left: 50px;
            }
        }

        .search-icon{
            position: absolute;
            left:20px;
            top:10px;

            img{
                width: 15px;
            }
        }
    }

 
}