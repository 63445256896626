.EstateHome{
    background-color: #ffffff;
    height: 100%;

    @media (min-width: 1600px) {
        &.container-xxxxl {
         max-width: 1326px !important;
        }
      
       &.container-xxxxl {
          --bs-gutter-x: 1.5rem;
          --bs-gutter-y: 0;
          width: 100%;
          padding-right: calc(var(--bs-gutter-x) * .5);
          padding-left: calc(var(--bs-gutter-x) * .5);
          margin-right: auto;
          margin-left: auto;
        }
      }

    .toplocation{
        .title{  
            font-size: 32px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: 0px;
            text-align: left;
            color: #1E3240; 
        }
    
        .description{ 
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: left; 
            color: #555557;
    
        }
    }

    .title{  
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0px;
        text-align: center;
        color: #1E3240; 
    }

    .description{ 
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: center; 
        color: #555557;

    }

    
}