.navbar{
        padding: 20px 0;

        .navbar-brand{
            .logo{
                width: 100px;
            }
        }

        .nav-item{ 
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: .9rem;
            line-height: 18px; 
            display: flex;
            align-items: center; 
            color: #333333;
            padding: 0 10px;
           
            .nav-link{
                &.nav-responsive{ 
                    font-size: .82rem;
                    @media (min-width: 990px) and (max-width: 1398px) { 
                        font-size: .7rem;
                        text-align: center;
                        display: flex;
                        width:77px;
                        text-overflow: ellipsis;
                        white-space:nowrap; 
                        align-items: center; 
                            img{ 
                                display: block;
                            }
                    }  
                } 
            }

            .dropdown-toggle{
                font-style: normal;
                font-weight: 400;
                font-size: .9rem;
                text-decoration: none;
                line-height: 18px; 
                display: flex;
                align-items: center; 
                color: #333333; 

                @media (min-width: 990px) and (max-width: 1398px) { 
                    font-size: .7rem; 
                    text-overflow: ellipsis;
                    white-space:nowrap; 
                    align-items: center;  
                }

                .avarter{
                    width:20px;
                    border-radius: 50px; 
                } 
                
            }
            .dropdown-menu{
                background: #FFFFFF;
                border: 1px solid #DFE4EA;
                border-radius: 8px;

               .dropdown-item{ 
                    // border-top: 1px solid #DFE4EA;
                    padding-top: 10px;
                    padding-bottom: 10px;  
                    font-weight: 400;
                    font-size: .8rem;
                    line-height: 18px; 
                    display: flex;
                    align-items: center; 
                    color: #333333;  

                    &:focus{
                        background-color:   #DFE4EA                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     #333333;
                    }

                    img{
                        width: 15px;
                        margin-right: 10px;
                    }
               }
            } 
            .nav-icon{
                width:18px;
                margin-right: 8px;
            }
 
        }
    }
 