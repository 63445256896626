.PendingProjects{
    .requested-status {
        display: inline-block;
        box-sizing: border-box;  
        // height: 28px; 
        background: rgba(255, 0, 0, 0.15);
        border-radius: 100px;
        font-size: .8rem;
        padding: 5px 10px;
        font-style: normal;
        font-weight: 400; 
        line-height: 18px;  
        color: #FF0000;
    }   

    .delivery-fee-set-status{
        display: inline-block;
        box-sizing: border-box;  
        // height: 28px; 
        background: rgba(0, 193, 43, 0.15);
        border-radius: 100px;
        font-size: .8rem;
        padding: 5px 10px;
        font-style: normal;
        font-weight: 400; 
        line-height: 18px;  
        color: #00C12B;
        
    }

    td{
        font-style: normal;
        font-weight: 500;
        font-size: .8rem !important;
        line-height: 150%; 
        color: #606060;

    }

    .horizontaLoader{
        height: 20px;
    }
}