.PageNotice{
    margin-top: 20px;
    .alert-warning{
        border: 1px solid #F2994A;
        background-color: #FEF5ED !important;
        font-size: .8rem;

        .btn-notice-warning{
            background-color: #F2994A;
            border-radius: 50px;
            font-size: .9rem;
            color: #ffffff;

            svg{
                margin-right: 5px;
            }
        }
    }

    .alert-success{ 
        border: 1px solid #01A63E;
        background-color: #E8F6ED !important;
        font-size: .8rem;

        .btn-close-notice{
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;

            .d-close-circle{
                color: #01A63E;
            }
        }
    }
}