.bestSellingProducts{
    position: relative;
    .section-header{
        h2{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 38px;
            display: flex;
            align-items: center; 
            color: #333333;
        }
        .nav-icon{
            width:15px;
        }

        .col-1{
            font-size: .8rem;
            font-weight: 400; 
            line-height: 22px; 
            color: #3378F3; 
            flex: none;
            order: 0;
            flex-grow: 0;

            button{
                border: 1px solid rgba(255, 255, 255, 0);

                &:active{
                    border: 1px solid rgba(255, 255, 255, 0);
                }
                &:focus{
                    border: 1px solid rgba(255, 255, 255, 0);
                }
            }
        }

        .btn-popular{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 2px 8px;
            gap: 10px; 
            width: 99px;
            height: 27px; 
            background: #EFF2F6;
            border-radius: 13.5px; 
            flex: none;
            order: 2;
            flex-grow: 0;
            font-style: normal;
            font-weight: 400;
            font-size: .9rem;
            line-height: 23px; 
            color: #3378F3;
        }
    }
    .products-row{
        display: flex;
        flex-direction: row; 
        flex-wrap: wrap;  
        justify-content: space-around;
    }
 
    .carousel-control-container{ 
        width:80px !important; 
    
        .carousel-control-next{ 
            height: 35px;
            width: 35px;
            background-color: #DFE4EA !important;
            opacity: 1;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center; 
            
            .islarge{
                font-size: 1.3rem;  
                color: #333333;
                font-weight: 600 !important;
            } 
        } 
        
        .carousel-control-prev{ 
            height: 35px;
            width: 35px;
            background-color: #DFE4EA !important;
            opacity: 1;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center; 
            
            .islarge{
                font-size: 1.3rem;  
                color: #333333;
                font-weight: 600 !important;
            } 
        } 
    }
    .carousel-indicators-container{
        position: relative;
        margin-top: 50px;

        .carousel-indicators{
            position: absolute;
            left: 0;
            display: flex;
            flex-direction: row;
            
            button{
                flex: 20%;
                width: 100%;
                height: 1px !important;
                margin: 0;
                background: #a4a4a4 ;
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent; 
                &.active{
                    background: #333333 !important;
                    border-radius: 1px;
                    height: 4px;
                }
            }
        }
    }
    
}