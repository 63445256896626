.invoice{
    tr{   
        td{ 
            padding: 13px 0;
        }
        border-bottom: 1px solid #DFE4EA;
    }
    .id{
        font-style: normal;
        font-weight: 400;
        font-size: .73rem;
        line-height: 4px;  
        text-align: left; 
        color: #000000; 
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    .date{
        font-style: normal;
        font-weight: 400;
        font-size: .73rem;
        line-height: 4px; 
        color: #606060; 
        text-align: left; 
        flex: none;
        order: 1;
        flex-grow: 0;
    }
    .amount{ 
        font-style: normal;
        font-weight: 500;
        font-size: .73rem;
        line-height: 150%;  
        color: #606060;
    }
    .btn-approve{ 
        background: #FFFFFF;
        border: 1px solid #34BA96;
        border-radius: 100px;
        font-weight: 400;
        font-size: .70rem;
        line-height: 8px; 
        text-align: center; 
        color: #34BA96;
        width: 90%;
    }
    .btn-declined{  
        background: #FFFFFF;
        border: 1px solid #E45A5A;
        border-radius: 100px;
        font-style: normal;
        font-weight: 400;
        font-size: .70rem;
        line-height: 8px; 
        text-align: center; 
        color: #E45A5A;
        width: 90%;
    }

    .see-all{
        font-size: .78rem;
        font-style: normal;
        font-weight: 400;
        font-size: .78rem;
        line-height: 150%; 
        text-align: center; 
        color: #F5A623;
        cursor: pointer;
    }
}