@import "../../../../../../../Assets/Scss/primaryColor";
.seller-all-product {
  height:  100%;;

  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .pagination-bar {

    }
  }

  .sp-title {
    font-weight: 800;
    font-size: 1.6rem;
    line-height: 24px;
    color: #303030;
  }

  .btn-addnew {
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 19px;
    @media (min-width: 1200px) {
      width: 149px;
    }
    @media (max-width: 1200px) {
      width: 100%;
    }

    height: 37px;
    color: #ffffff;
    background: $sellerPrimary;
    box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
      inset 0px -1px 0px rgba(14, 14, 44, 0.4);
    border-radius: 8px;
  }
  .container-md{
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
  }

  .search-container {
    position: relative;
    .fas {
      position: absolute;
      top: 15px;
      left: 15px;
      opacity: 0.5;
      font-size: 0.9rem;
    }
    .search {
      background: #ffffff;
      border-radius: 10px;
      height: 44px;
      width: 476px;
      border: none;
      font-size: 0.9rem;

      &:focus {
        border-color: rgba(224, 224, 224, 0.651);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
          0 0 8px rgba(206, 206, 206, 0.6);
        outline: 0 none;
      }
    }
  }

  .modal-title {
    font-size: 0.8rem !important;
  }

  .emptySearch {
    margin-right: auto;
    margin-left: auto;
    background-color: #dbdbe0;
    color: #ffffff;
    width: 90px;
    height: 90px;
    padding: 10px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: bolder;
  }

  .btn-pag{
    width: 120px;
    height: 33px;
    border-radius: 6px;
    padding: auto;
    margin-top: 5rem;
    text-align: center;
  }
  .paginate-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
