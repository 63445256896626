.buyer-plp-application-form-page {
    
    .stepper-container {
        margin: 50px 0px;
   }

    .plp-application-form {
        display: flex;
        flex-direction: column;
        row-gap: 50px;

       .plp-form-btn {
            border: 1px solid #DFE4EA;
            border-radius: 100px;
            padding: 8px 50px;
            font-size: .9rem;
            background-color: #406aff;
            color:#ffffff;
            font-weight: 500;
       }

       .form-section-header {
         max-width: 598px;
         margin-bottom: 50px;
       }

       .form-section-title {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #333333;
       }

       .form-section-description { 
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #606060;
       }
       .form-label{
        font-size: .7rem;
       }
       .form-input {
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        height: 46px;
       }

       .form-input-error {
        border: 1px solid #FF9F9F;
        background: #FFFFFF;
        border-radius: 100px;
        height: 46px;
       }

       .custom-form-select {
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        height: 46px;
       }

       .custom-form-select-error {
        border: 1px solid #FF9F9F;
        border-radius: 100px;
        height: 46px;
       }

       .input-btn { 
        background: #EFF2F6;
        border: 1px solid #DFE4EA;
        border-radius: 100px 0px 0px 100px;
        height: 46px;
        font-size: .8rem;
       }
       .control-btns-container {
         display: flex;
         flex-direction: column;
         row-gap: 20px;
         justify-content: center;

         @media  (min-width:768px){
            flex-direction: row;
            column-gap: 20px;
            align-self: flex-end;
        }
       }
   }
}