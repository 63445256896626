.cart-item{  
        border-bottom: 1px solid rgb(231, 231, 231);
        padding-bottom: 15px;
        margin-bottom: 15px;
        position: relative;

            hr{
                border: 1px solid rgb(231, 231, 231);
                margin-top: 20px;
            }
      
        .cart-item-list-image{
            @media (max-width:600px) {
                width: 100%; 
            }

            @media (min-width:600px) {
                width: 136px; 
            }

            height: 137px; 
            background: #D9D9D9;
            border-radius: 8px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                width: 100%;
            }
        }

        .cart-item-name{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 1rem;
            line-height: 19px;
            display: flex;
            align-items: center; 
            color: #333333;

        }

        .cart-item-variation{ 
            font-weight: 400;
            font-size: .9rem;
            line-height: 17px; 
            display: flex;
            align-items: center; 
            color: #353434; 
            margin-top: 5px;
        }

        .cart-item-price{ 
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            @media (max-width:600px) { 
                height: 100%;
                display: flex; 
                justify-content: center;
                color: #F2994A; 
                position: absolute;
                top:10px; 
            }

            @media (min-width:600px) {
                
                height: 137px; 
                display: flex;
                align-items: center; 
                justify-content: center;
                color: #F2994A;  
            }

        }
        .product-attibutes{ 
            position: absolute;
            bottom:15px;
            width: 100%;
                .product-qty{
                    width: 80px;
                    height: 35px; 
                    background: #FFFFFF;
                    border: 1px solid #DFE4EA;
                    border-radius: 100px; 
                    padding: 0 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .form-control{ 
                        height: 30px; 
                        border: 1px solid #DFE4EA;
                        border-radius: 100px; 
                        text-align: center;
                        &.active{
                            border: 1px solid #d9d9d900;
                            outline: none !important;
                            box-shadow: none !important;
                        }

                        &.focus{
                            border: 1px solid #d9d9d900;
                            outline: none !important;
                            box-shadow: none !important;
                        }
                }
                } 
            }

        .remove-product{
            text-align: center; 
            height: inherit; 
            padding-top: 6px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px; 
            display: flex;
            align-items: center; 
            color: #333333;
        }

        .save-product{
            text-align: center;
            height: inherit; 
            padding-top: 6px; 
            font-weight: 400;
            font-size: 14px;
            line-height: 17px; 
            display: flex;
            align-items: center; 
            color: #333333;
        }
 
}