@import "./../../../../Assets/Scss/primaryColor";

.register{
    height: 100vh; 
    overflow-x: hidden;
    .register-right{
        background-color: $primary;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .register-header{ 
            position: absolute;
            top:40px; 
            width: 100%; 

            .register-back{ 
                display: flex;
                justify-content: center;
                align-items: center;

                a{ 
                    text-decoration: none;
                    span{ 
                        font-size: 1.4rem; 
                        padding: 0px;
                        width: 25px;
                        height: 25px; 
                        background: #EFF2F7; 
                        border-radius: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }  
            }

            .register-logo{ 
                img{ 
                    width:150px;
                }
            }
        }

        .register-text{
            position: absolute;
            top:170px; 
            width: 100%;

            h2{ 
                font-style: normal;
                font-weight: 800;
                line-height: 48px;
                letter-spacing: -0.01em; 
                color: #FFFFFF;

                @media (min-width:1428px) { 
                  font-size: 40px;
                }
                
                @media (max-width:1428px) { 
                  font-size: 35px;
                }  
            }

            .desc{
                font-size: 1rem;
                color: #FFFFFF;
            }
        }

        img{
            position: absolute;
            bottom:0;
            width:60%; 
        }

        .abstImg{
            @media screen and ( max-height: 704px ) { 
             width:220px 
           }

           @media screen and ( max-height: 614px ) { 
            display: none; 
          }
        }

    }


    .register-header{  
            padding: 60 30px !important;   
        
        .register-back{ 
            display: flex;
            justify-content: center;
            align-items: center;

            a{ 
                text-decoration: none;
                span{ 
                    font-size: 1.4rem; 
                    padding: 0px;
                    width: 37px;
                    height: 37px; 
                    background: #EFF2F6; 
                    border-radius: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #333333 !important;
                }
            }  
        }

        .register-logo{ 
            img{ 
                width:150px;
            }
        }
    }

    .register-form{
        @media (max-width:768px) {
            padding: 0 30px; 
        }

        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 100px 0;

        
        
        h1{ 
            @media (min-width:768px) { 
                font-style: normal;
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 29px; 
                color: #000000;
                margin-bottom: 20px;
            }
            @media (max-width:768px) { 
                font-style: normal;
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 29px; 
                color: #1b1b1b;
                margin-bottom: 20px;
            }
        } 

        .form-group{
            label{
                font-style: normal;
                font-weight: 400;
                font-size: .9rem;
                line-height: 19px; 
                color: #333333;
                margin-bottom: 10px;
            }
            .form-control{
                background: #FFFFFF;
                border: 1px solid #DFE4EA;
                border-radius: 100px;
            }

            select{
                background: #FFFFFF;
                border: 1px solid #DFE4EA;
                border-radius: 100px;
                outline: none !important;
                height: 46px; 
                font-size: .9rem;
                color: #333333;
                option{ 
                    font-size: .9rem;
                    color: #333333;
                }

                &:focus{
                    outline: none !important;
                    box-shadow: none !important;

                }

            }

            .btn-register{
                background: $primary;
                border: 1px solid #DFE4EA;
                border-radius: 100px;
                width: 100%;
                height: 46px;
                font-weight: 400;
                font-size: .8rem;
                line-height: 18px;  
                color: #FFFFFF; 
            }
        }

        .forgot-password{
            font-style: normal;
            font-weight: 400;
            font-size: .9rem;
            line-height: 19px;
            display: flex;
            align-items: center; 
            color: $primary;
            margin-top: 10px;
            text-decoration: none;
        }

        .signup{
            font-size: .9rem;
            text-decoration: none; 
            color: #000000;
        }

        .alert{
            font-size: .8rem;
            .btn-close{
                font-size: .6rem;
                &:focus{
                    box-shadow: none!important;
                    outline: none !important;
                }
            }
        }
    }

    .corporate-link{ 
        font-size: .85rem;
        margin-right: 10px;
    }

    .w--25 {
        width: 20px;
        height: 20px;
      }

      .dropdown{
        .dropdown-item{
            font-size: .7rem;
            cursor: pointer;
            &:hover{
                background-color: #e7e6e6;
                border-radius: 10px;
            }
            &:focus{
                background-color: #e7e6e6;
                border-radius: 10px;
            }
            &:active{
                background-color: #e7e6e6;
                border-radius: 10px;
            }
        }
        .form-control{
            outline: none !important;
            box-shadow: none !important;
            height: 46px; 
            font-size: .7rem;
          }
        .w--25{
            width: 15px !important;
            height: 15px !important;
        }
      }

        
    .password-container {
        position: relative;  
        .field-icon {
          float: right; 
          right: 15px;
          bottom:9px;
          position: absolute;
          z-index: 5;
          cursor: pointer;
          opacity: 0.6; 
        }
      }

      .ms-checkbox{
        height: 15px!important;
        margin-left: 5px;
      }
}