@import "../../../../../../Assets/Scss/primaryColor";

.creatingLoading{
    width:100vw;
    height:100%;
    position: fixed;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.622); 
   .card-body-loading{
        display: flex;
        flex-direction: row;

        .spiner-container { 
            flex: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
    
            .spinner-border{
                color: green !important;
            }
            .sp-container {
                height: 70px;
                width: 70px;
                display: flex;
                justify-content: center;
                align-items: center;

                .d-icon{
                    font-size: 3rem;
                    color: rgb(250, 250, 250) !important;
                } 
            }
        }
        .text{
            flex: 70%;
            .title{
                @media (max-width:500px) { 
                    font-size: .9rem;
                    font-weight: 700;
                    color: rgb(30, 30, 30);
                }
                @media (min-width:500px) { 
                    font-size: 1.3rem;
                    font-weight: 700;
                    color: rgb(103, 103, 103);
                }
            }
            .progress-bar{ 
                @media (max-width:500px) { 
                    font-size: .7rem;
                    font-weight: 500;
                }
                @media (min-width:500px) { 
                    font-size: .8rem;
                    font-weight: 500;
                }
            }
        }
   }

   .completed-card {
        width:45vh;
        height:50vh;
         
        .d-icon{
            background: $sellerPrimary;
            padding: 20px;
            margin-bottom: 40px; 
            border-radius: 100px;
            font-size: 2rem ;
            color: aliceblue; 
            font-weight: 600 !important;
        }
        .success-msg{
            font-size: 1.3rem;
            font-weight: 600;
            text-align: center; 
        }
        .desc{
            @media (max-width:500px) { 
                font-size: .7rem; 
                margin-top: 30px;
                padding: 0 10px;
            }
            @media (min-width:500px) { 
                font-size: .8rem; 
                margin-top: 30px;
                padding: 0 60px;
            }
        }
        .btn{
            width:100px
        }
   }

   .error-card{
    width:50vh;
    height:50vh;
     
    .d-icon{
        background: rgb(255, 100, 100);
        padding: 20px;
        margin-bottom: 40px; 
        border-radius: 100px;
        font-size: 2rem ;
        color: aliceblue; 
        font-weight: 800 !important;
    }
    .success-msg{
        font-size: 1.3rem;
        font-weight: 600;
        text-align: center; 
    }
    .desc{
        @media (max-width:500px) { 
            font-size: .7rem; 
            margin-top: 30px;
            padding: 0 10px;
        }
        @media (min-width:500px) { 
            font-size: .8rem; 
            margin-top: 30px;
            padding: 0 60px;
        }
    }
    .btn{
        width:100px
    }
   }
}