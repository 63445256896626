.enterpriseFaqItem-item-list {
    list-style: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #3f3f3f;
    cursor: pointer;
    margin-bottom: 24px;

    .icon-wrap{
      width: 30px;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center; 
      margin-right: 12px; 
      background-color: #F5A623;
      border-radius: 50px;
    }
 

    .faq-item-answer-modal {
        display: flex;
        align-items: center;
    }
    .custom-modal-close-btn {
        &:hover {
            cursor: pointer;
            opacity: 0.6;
        }
    }
}