@import "../../../../../../Assets/Scss/primaryColor";
.enterprise-quotes-details{
    .quote-order-summary{ 

        background: #FFFFFF;
        border: 1px solid #DFE4EA !important;
        border-radius: 8px;

        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 24px; 
        color: #333333;
    }

    .order-time{
        font-weight: 400;
        font-size: .89rem;
        line-height: 19px; 
        text-align: right; 
        color: #333333;
    }

    .card-custom-header {
        border-bottom: none !important;
        margin-top: 20px;
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
    }
    
    .orde-summary-total {
        border-top:  1px solid #E0E0E0;
        padding-top: 20px;
    }
    
    .price-breakdown {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
    }

    .price-total {
        font-weight: bold;
        font-size: 20px;
        line-height: 19px;
        color: #333333;
    }
    .buyer-quote-details-navlink {
        background-color: #4379EB;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        padding: 8px 26px;
        font-size: .9rem;
        line-height: 18px;
        color:white
    }

    .buyer-quote-details-navlink-estate{ 
        background-color: $primary-estate;
        border: 1px solid $primary-estate;
        border-radius: 100px;
        padding: 8px 26px;
        font-size: .9rem;
        line-height: 18px;
        color:white 
    }

    .custom-card-footer {
        padding-top: 30px;

    }
}