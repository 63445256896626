.seller-order-details {
  .order-summary {
    background: #ffffff;
    border: 1px solid #dfe4ea !important;
    border-radius: 8px;

    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    color: #333333;
  }
  .order-time {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #333333;
  }

  .card-custom-header {
    border-bottom: none !important;
    margin-top: 20px;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }

  .orde-summary-total {
    border-top: 1px solid #e0e0e0;
    padding-top: 20px;
  }

  .price-breakdown {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
  }

  .price-total {
    font-weight: bold;
    font-size: 20px;
    line-height: 19px;
    color: #333333;
  }
    .btn-track {
      background-color: #34ba96;
      border: 1px solid #dfe4ea;
      border-radius: 100px;
      padding: 8px 20px;
      font-size: 0.9rem;
      line-height: 18px;
      color: white;
      font-weight: 500;
    }

  .custom-card-footer {
    padding-top: 30px;
  }
}
