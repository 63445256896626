.EstateManagersMortgageCard{
    .header-title{
        margin-bottom: 10px;
        margin-top: 20px;

        h2{
            font-size: 1.3rem;  
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left; 
        }

        .subtext{
            font-size: .8rem;

        }
    }

    .first-column-bottom {
        align-self: flex-end;
    }
 
    .subscriber-list{
        .table-title{  
            font-size: .9rem;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: left; 
        }
     
        thead{
            td{
                font-size: .9rem;  
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0px;  
                color: #797A7C;

            }
        }

        tbody{
            td{
                font-size: .8rem;
                vertical-align: middle; 
            }

            .status{
                display: inline-block;
                width: Fixed (106px);
                height: Hug (34px); 
                padding: 5px 10px; 
                border-radius: 20px;
                gap: 10px; 
                background: #FCEAEA;
                font-size: .83rem;
                padding: 5px 10px;
            }

            .btn-resend{
                width: Fixed (106px);
                height: Hug (34px); 
                padding: 5px 10px;
                border-radius: 20px;
                gap: 10px; 
                border: 1px solid #3A0CA3;
                font-size: .83rem;

            }
        }
    }
    
}