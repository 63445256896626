.enterpriseEditUser{ 
    margin-top: 17px; 

    .form-container{
        @media (min-width:800px) {
            margin: 0 160px;
        }
        
        .btn-back{
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px; 
            color: #303030 !important;
            background-color: #FFFFFF !important;
            box-shadow: none !important;
            width: fit-content;
            text-align: left;
            display: flex-end !important; 

        }

        .form-input{ 
            margin-top: 20px;
            label{
                font-style: normal;
                font-weight: 400;
                font-size: .8rem;
                line-height: 19px;
                margin-bottom: 10px; 
                color: #333333;
            }

                .form-control{ 
                    height: 39px; 
                    background: #FFFFFF;
                    border: 1px solid #DFE4EA;
                    border-radius: 100px;
                } 

                 
                .dropdown-menu{
                    background: #FFFFFF;
                    border: 1px solid #DFE4EA;
                    box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.1);
                    border-radius: 26px;
                    padding: 10px 20px;
                    width:inherit;
                    .dropdown-item{
                        display: flex;
                        flex-direction: row;
                        height: 40px;
                        display: flex; 
                        align-items: center; 

                        &:focus{
                            background-color: #DFE4EA !important;
                            border-radius: 10px;
                        }
                        &:active{
                            background-color: #DFE4EA !important;
                            border-radius: 10px;
                        }
                        &:hover{
                            cursor: pointer;
                            background-color: #DFE4EA !important;
                            border-radius: 10px;
                        }


                            input{
                                padding: 0px !important;
                                height: 20px; 
                            }
                            input[type=checkbox] {
                                accent-color: #F5A623;
                              }
                            label{
                                margin-left: 10px;
                                font-size: .9rem;
                                margin-bottom: 0px;
                            } 
                    }
                }
        }

        hr{ 
            margin-top: 40px;
            color: #9c9595;
            background-color: #F5A623;

        }

        .role-title{
                font-style: normal;
                font-weight: 600;
                font-size: .9rem;
                line-height: 19px; 
                color: #474747; 
                margin-top: 20px;
        }

        .btn{
                width: 160px;
                height: 39px; 
                background: #F5A623; 
                box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
                border-radius: 100px;
                margin-top: 30px;
                font-size: .8rem;
                color: #ffffff;
                font-weight: 700;
        }
        }
}