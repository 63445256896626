.certificate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 9rem;
  height: 66vh;

  .cac-form-card {
    width: 514px;
    padding-left: 20px;
    // margin-right: 14rem;
    > h3 {
      margin-top: 1.5rem;
      font-size: 24px;
      font-weight: 400px;
      color: black;
    }
    > p {
      color: #606060;
      font-size: 16px;
      margin-bottom: 0;
    }
    .cac-form {
      width: 100%;
    }
    .form {
      h3 {
        font-size: 24px;
        font-weight: 400px;
        color: black;
        margin-left: 0.8rem;
      }
      p {
        font-size: 16px;
        margin-left: 0.8rem;
      }
    }
    .form-group {
      > label {
        width: 498px;
        height: 110px;
        border: 1px solid #dfe4ea;
        background: linear-gradient(0deg, #dfe4ea, #dfe4ea),
          linear-gradient(0deg, #f9f9f9, #f9f9f9);
        border-radius: 8px;
        padding: 8px 8px;
        cursor: pointer;
        margin-left: 0.8rem;
        .input {
          display: none;
        }
      }
      .input-div{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-top: 6px;
        >p{
          margin-top: 16px;
          font-size: 16px;
          font-weight: 400;
        color: #333333;

        }
         .input-icon {
          margin-right: 30px;
        height: 64px;
        width: 64px;
      }
      }
   
     
    }
  }
}
@media (min-width: 768px) {
  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
}
@media (min-width: 585px) and (max-width: 749px) {
}

@media (max-width: 1400px) {
}
