.enterpriseBankingAndFinance{
    .history-container{
        .historytitle{ 
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;  
            color: #303030;
        }
    }

    .add-bank{ 
        color: #F5A623; 
    } 
}