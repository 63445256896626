.newsletter{
    h2{ 
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: -0.01em;  
        color: #333333;
        @media (max-width:700px) { 
            font-size: 25px;
            }
            @media (min-width:700px) {
                font-size: 32px;
            }
    }
    
    .form-control{  
        @media (max-width:700px) {  
        width: 100%;
        height: 46px;
        font-size: 13px;
        }
        @media (min-width:700px) { 
            width: 100%;
            height: 56px;
            font-size: 16px;
        }
        background: #EFF2F6;
        border-radius: 100px;
        border: 1px solid #EFF2F6;  
        font-style: normal;
        font-weight: 500;
        line-height: 19px; 
        color: #868484;

        &::placeholder{
            @media (max-width:700px) {  
                font-size: .7rem; 
                }
                @media (min-width:700px) { 
                    font-size: 1rem; 
                }
                
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px; 
            color: #868484;
        }
    }

    .btn{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 8px;  
        background: #3378F3;
        border-radius: 100px;
        color: #ffffff;
        @media (max-width:700px) { 
            width: 100%;
            height: 46px; 
            font-size: .8rem;
            margin-top: 18px;
        }
        @media (min-width:700px) { 
            width: 141px;
            height: 56px; 
        }
    }
}