.enterprisePersonalInformation{

    .password-container {
        position: relative;  
        
        .field-icon {

            float: right; 
            right: 15px;
            bottom:-20px;
            position: absolute;
            z-index: 5;
            cursor: pointer;
            opacity: 0.6;

        }
    }
}