.resources{
    .page-title{
        font-size: 2rem;
        font-weight: 900;
    } 

    .cb-cast-list{  
        .card{
            overflow: hidden;
            border-radius: 10px;
        }
        .cast-item{
            height: 340px;
            background-size: cover;
            background-color: rgb(0, 0, 0);
            transition: all .5s ease-in-out;

            &:hover{
                transform: scale(1.02);
            } 
        }

        .card-body{
            a{
                color: #131313;
                &:hover{
                    text-decoration: underline;
                }
            }
            h2{  
                font-weight: 700;
                font-size: 1.5rem;
                cursor: pointer;
                width: 70%;
                height: 60px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;


                @supports (-webkit-line-clamp: 2) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: initial;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }

            .post-by{  
                color:rgb(255, 145, 0);
                font-size: .8rem;
                font-weight: 700;
            }
            .post-type{   
                font-size: 1rem;
                font-weight: 900;  
            }


        }
    }
}