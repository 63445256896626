.procurement{
    margin-top: 20px;

    .card{
        border: 1px solid #DFE4EA;
        border-radius: 8px;
  
        .card-header{
            background-color: rgb(255, 255, 255);
            padding-bottom: 0px !important;
            .title{
                font-size: .8rem;
                margin-bottom: 7px;
                padding-top: 10px;
            }
            .nav-item{
                
              .nav-link{
                font-size: .7rem;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 25px; 
                color: #606060;
                display: flex;
                flex-direction: row;
                align-items: center; 


                .badge{ 
                    background: #F2994A;
                    width: 14px;
                    height: 14px;
                    border-radius: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: .5rem;
                    margin-left: 6px;
                }

                &:hover{
                    border: none;
                }

                &:active{
                    border: none;
                }
                &:focus{
                    border: none;
                }
                &:left{
                    border: none;
                } 

                &.active { 
                    border:  1px solid #ffff !important;
                    border-bottom: 2px solid #F5A623 !important; 
                    color: #F5A623;
                  }
              }
            }
        }
    }
}