.inventory{
    .move-bulk-btn{
        background-color: #EDB82E;
        border-radius: 50px;
        color: rgb(255, 255, 255);
        font-size: .85rem;
        font-weight: 500;
        span{
            display: inline-block;
            background-color: rgb(9, 9, 9);
            border-radius: 50px;
            width:20px;
            height:20px;
            box-shadow: 1px 1px 1px darkgoldenrod;
        }
    }


    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 200px;
      }

      .qty_available{
        display: inline-block;
        width: 20px; 
      }

      td{
        height: 30px;
        display: table-cell;
        vertical-align: middle;
        align-items: center; 
      }
}