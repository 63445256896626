.category-mega-menu{
    position: relative;
    .dropdown-menu{
        margin-top: 10px !important;
        min-width: 75vw !important;
        overflow-x: auto;
    
        .mega-menu-tab{
            .list-group{
                .list-group-item{ 
                    height: 55px; 
                    border-bottom: 1px solid #DFE4EA !important;
                    border-radius: 0px;
                    background-color: #eff2f6;
                    cursor: pointer; 
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: .9rem;
                    line-height: 18px;  
                    color: #333333; 
                    flex: none;
                    order: 1;
                    flex-grow: 0;

                    .img{
                        width: 34px;
                        height: 34px; 
                        background: #D9D9D9;
                        border-radius: 8px; 
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                    }

                    .nav-icon{
                        width: 7px;
                    }

                    &.active{
                        background-color: #ffffff !important; 
                      }
                }
            }
        }

        .mega-menu-tab-item{
            padding-left: 20px;
            padding-top: 20px;
            
            .mega-menu-head-title{ 
                font-family: 'Lato';
                font-style: normal;
                font-weight: 900 !important;
                font-size: .9rem;
                line-height: 18px;  
                color: #333333; 
                flex: none;
                order: 0;
                flex-grow: 0;
            }
            .list-group-item{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: .9rem;
                line-height: 18px; 
                display: flex;
                align-items: center; 
                color: #333333; 
                flex: none;
                order: 1;
                flex-grow: 0;
             
            }
        }
    }
}