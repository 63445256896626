.order-status {
    border-radius: 100px;
    padding: 5px 12px;
    font-weight: 400;
    font-size: .9rem;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-custom-success {
    background-color: rgba(0, 193, 43, 0.09);
}

.bg-custom-warning {
    background-color: rgba(255,204,0, 0.09);
}

.bg-custom-danger {
    background-color: rgba(204,51,0, 0.09);
}