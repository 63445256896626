.biz-info {
  .title {
    font-size: .9rem;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #767676;
  }
  .item {
    .details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      border: 1px solid #dfe4ea;
      border-radius: 8px;
      padding: 14px 24px;
      height: 72px;
      width: 100%;

      .icon-title{
        display: flex;
        justify-content: flex-start;
        align-items: center;
         .icon {
        height: 24px;
        width: 24px;
        background-color: #e9e9e9;
        opacity: 0.2;
        color: #34ba96;
      }
       .details-title {
        margin-top: 14px;
        margin-left: 14px;
        font-style: normal;
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 19px;
        color: #333333;
      }
      }
      img{
        cursor: pointer;
      }
     
    }
  }
}
