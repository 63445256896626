.PopularProjectItems{
    .card{
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        
        .card-image{
            position: relative;
            height: 181px;
            overflow: hidden;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                transition: all .5s ease-in-out;
                cursor: pointer;

                &:hover{
                    transform: scale(1.1);
                }
            }

            .featured-text{
                // width: 151px;
                height: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border-radius: 20px; 
                background: #F0F3FC;  
                font-size: .6rem;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0px;
                text-align: left;
                color: #3A0CA3; 
                position: absolute;
                top: 15px;
                right: 15px;

            }
        }

        .card-body{
            .price{  
                font-size: 1rem;
                font-weight: 700;
                line-height: 22px;
                letter-spacing: 0px;
                text-align: left;
                color: #3A0CA3; 

                small{
                    font-size: .6rem;
                    font-weight: 400;
                }
            }

            .units{
                width: 74px;
                height: 23px;
                padding: 5px, 8px, 5px, 8px;
                border-radius: 20px; 
                background: #E8F6ED; 
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0px;
                text-align: left;
                color: #01A63E; 
                display: flex;
                justify-content: center;
                align-items: center;
            }


            .project-title{ 
                a{
                    font-size: .9rem;
                    font-weight: 600;
                    line-height: 24px; 
                    text-align: left;
                    color: #000000; 
                    text-decoration: none;
                }
            }
            .project-description{ 
                font-size: .73rem;
                font-weight: 400;
                line-height: 22px; 
                color: #000000;        
                text-overflow: ellipsis;    
                display: -webkit-box;
                -webkit-line-clamp: 3; /* Number of lines to show */
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .project-location{ 
                font-size: .7rem;
                font-weight: 400;
                line-height: 22px; 
                color: #797A7C;

            }
        }
    }
}