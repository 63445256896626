.enterpriceHome{
    .enterprise-header{
        /* linear */ 
        background: linear-gradient(180deg, #3378F3 0%, #034DCF 100%);
        border-radius: 8px;
 
        .page-title{
            font-weight: 700;
            line-height: 58px;
            letter-spacing: -0.01em; 
            color: #FFFFFF;
            text-align: center;

            @media (min-width:500px) { 
                font-size: 3rem;
            }

            @media (max-width:500px) { 
                font-size: 2rem;
                line-height: 39px;
            }
        }

            @media (max-width:500px) { 
                img{
                    padding-top: 20px;
                }
            } 

    }

    .enterprise-intro{
        margin-top: 90px;
        margin-bottom: 80px;
        width: 100%;
        text-align: center;
        
        .enterprise-intro-title{
            font-weight: 700;
            font-size: 32px;
            line-height: 38px; 
            color: #333333;

        }

        .card{
            height: 100px;
            background: #EFF2F6; 

            .card-body{
                height: 100px;
                justify-content: center;
                align-items: center; 
                background: #ffffff;  
                border-radius: 8px;
                padding: 0 0px;
                font-size: 1.1rem;
                font-weight: 500;
                line-height: 25px;

                @media (max-width:500px) {
                    font-weight: 700;
                    font-size: 1.4rem;
                    color: #5d5c5c;
                    line-height: 25px;
                }
            }
        }
    }

    .hiw{
        margin-bottom: 100px;
        .hiw-title{
            font-weight: 700;
            font-size: 32px;
            line-height: 38px; 
            color: #333333;
            margin-bottom: 40px;
            text-align: center;
        }

        .card-image{
            width: 100%;
            height: 100%; 
            background: #EFF2F6; 
            border: 1px solid #f1f0f0;
            border-radius: 8px;

            img{
                width: 100%;
            }
        }

        .tag{ 
            font-style: normal;
            font-weight: 600;
            font-size: .8rem;
            line-height: 150%; 
            color: #3378F3; 
        }

        .title{
            font-style: normal;
            font-weight: 600;
            font-size: 1.3rem;
            line-height: 23px; 
            color: #525050; 
            height: 50px; 
        }

        .desc{
            font-style: normal;
            font-weight: 500;
            font-size: .9rem;
            line-height: 150%; 
            color: #828282;
            margin-top: 20px;
            min-height:110px
        }
    }

    .gst{
        .btn{
            width: 224px;
            height: 54px; 
            background: #3378F3;
            border: 1px solid #DFE4EA;
            border-radius: 100px;
            font-weight: 600;
        }
    }
}