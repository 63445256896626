.home-adsSection{ 
    @media (max-width:600px) { 
        padding-top: 20px;
    }
    @media (min-width:600px) { 
        padding-top: 50px;
    }
    .ads{
        margin-top: 20px;
        @media (max-width:600px) { 
            width: 100%;  
        }
        @media (min-width:600px) { 
            width: 100%;  
        }
 
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.07);
        border-radius: 8px; 

        transition: all .5s ease-in-out;
        position: relative;

        &:hover{
            transform: scale(1.02); 
            box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.341);
        }

        .ads-element{
            height: 50px;
            width: 80%;
            position: absolute;
            
            @media (max-width:600px) { 
                bottom: 0;
                left: 30px;
            }
            @media (min-width:600px) { 
                bottom: 20px;
                left: 30px;
            }
        }
    } 
} 