.testimony-ads{
   

    .col-7{
        position: absolute;
        bottom: 0;
    }
    @media (max-width: 600px) {
      position: relative;
        .testimonial-image{
        //   position: absolute;
        //   bottom: 0;
        //   margin-right: 10px;
        //   height: 100%;
        }
    }
}

.testimonyAds{
    background: #236CF6;
    border-radius: 8px;  
    border-bottom: 5px solid #48E2E4;
    margin-bottom: 30px;
    position: relative; 
 

    @media (min-width: 600px) { 
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 600px) {
    //   height: 30vh;
    }
    h2{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 800;
        letter-spacing: -0.01em; 
        color: #FFFFFF;

        @media (min-width: 600px) {
          font-size: 48px;
          line-height: 58px;
        }
        @media (max-width: 600px) {
          font-size: 1.5rem;
          line-height: 30px;
          padding-top: 20px; 
        }
    }
    .desc{
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      line-height: 150%; 
      color: #DEDEDE;
      @media (min-width: 600px) {
        font-size: 16px;
      }
      @media (max-width: 600px) {
        font-size: .8rem;
      }
    }

    .btn{
      font-style: normal;
      font-weight: 600;
      line-height: 11px; 
      display: flex;
      align-items: center;
      text-align: center;

      color: #3378F3; 
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-top: 10px;

      height: 40px; 

      background: #FFFFFF;
      border: 0.607143px solid #DFE4EA;
      border-radius: 60.7143px;
          
      @media (min-width: 600px) {
        font-size: 16px;
        padding: 13px 40px;
      }
      @media (max-width: 600px) {
        font-size: .8rem;
        padding: 13px 12px;
        margin-bottom: 20px;
      }

    }

    .testimony-ads-content{
        height: 100%;
        margin: auto;
    }

    .testimonial-image{
        flex: 1;
        img{
            order:2;
            margin-top: auto;

        }
    }

}