.accountBalance{
    .title{ 
        font-weight: 400;
        font-size: .9rem;
        line-height: 150%; 
        color: #303030;
    }
    .card{
        background: #EFF2F6; 
        border: 1px solid #DFE4EA;
        border-radius: 8px;
        margin-top: 10px; 
        background: #3378F3; 
        border: 1px solid #DFE4EA;
        border-radius: 8px;
       .card-body{

        border-radius: 8px;
        .amount{
            font-weight: 600;
            font-size: 40px;
            line-height: 48px; 
            letter-spacing: -0.01em; 
            color: #FFFFFF; 
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom-right-radius: 8px;
        }

        .fund{ 
            width: 100%;
            height: 98px; 
            background: #5592FF;
            display:flex;
            justify-content: center;
            align-items: center; 
            border-top: 1px solid #DFE4EA;
            border-right: 1px solid #DFE4EA;
            border-bottom-left-radius: 8px;

            svg{
                width:20px
            }
            .label {
                font-weight: 700;
                font-size: .8rem;
                line-height: 18px; 
                display: flex;
                align-items: center;
                text-align: center; 
                color: #FFFFFF; 
            }

        }

        .widthdraw{ 
            width: 100%;
            height: 98px; 
            background: #5592FF;  
            display:flex;
            justify-content: center;
            align-items: center;
            border-top: 1px solid #DFE4EA;

            svg{
                width:20px
            }

            .label {
                font-weight: 700;
                font-size: .8rem;
                line-height: 18px; 
                display: flex;
                align-items: center;
                text-align: center; 
                color: #FFFFFF; 
            }

        }
    } 
  } 
}