.SellerQuoteDetails{
    .order-summary{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA !important;
        border-radius: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 24px; 
        color: #333333;
    }
    .quote-decline{
        color: red;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;

    }

    .order-time{
        font-weight: 400;
        font-size: 16px;
        line-height: 19px; 
        text-align: right; 
        color: #333333;
    }

    .card-custom-header {
        p{
            
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;

            text-align: left;

        }
        border-bottom: none !important;
        margin-top: 20px;
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
    }
    
    .orde-summary-total {
        border-top:  1px solid #E0E0E0;
        padding-top: 20px;
    }
    
    .price-breakdown {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
    }

    .price-total {
        font-weight: bold;
        font-size: 20px;
        line-height: 19px;
        color: #333333;
    }
    
    .seller-order-details-navlink {
        .btn-track{
        background-color: #34ba96; border: 1px solid #DFE4EA;
        border-radius: 100px;
        padding: 8px 20px;
        width: 179px;;
        height: 46px;;
        font-size: .9rem;
        line-height: 18px;
        color:white;
        font-weight: 500;
        }
    }

    .custom-card-footer {
        padding-top: 30px;

    }
}