@import "./../../../../../../../Assets/Scss/primaryColor";

.product-variant-item {
    .prod-var-list{
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        padding-bottom: 10px;
    }
  .shop-brand-title {
    font-weight: 600;
    font-size: 1rem;
    line-height: 48px;
    letter-spacing: -0.01em;
    color: #303030;
  }
  .card {
    width: 100%;
    border: none !important;
    background: #ffffff;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    overflow: hidden;

    &:hover {
      box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.15) !important;
      transform: scale(1.03);
      z-index: 1;
    }
    .card-body {
      padding: 20px 29px;
      padding-top: 20px !important;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      position: relative;
      overflow: hidden;

      @media (max-width: 700px) {
        .prodImg-container {
          min-height: 23px;
          display: flex;
          justify-content: center;
          align-items: center;
          display: relative;
          overflow: hidden;
          .w--50 {
            height: 80px;
          }
          .wishicon {
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
      @media (min-width: 700px) {
        .prodImg-container {
          min-height: 52px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow: visible;

          .dropdown {
            .dropdown-menu {
              position: absolute;
            }
          }
          .w--50 {
            height: 90px;
          }
          .wishicon {
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }

      .prod-company {
        font-weight: 500;
        font-size: 0.8rem;
        line-height: 150%;
        color: #3378f3;
      }
      .prod-location {
        font-weight: 500;
        font-size: 0.8rem;
        line-height: 150%;
        color: #868484;
      }
      .prod-price {
        font-size: 0.78rem;
        line-height: 150%;
        font-weight: 500;
        color: #ff0000;
      }

      .prod-rating {
        font-weight: 500;
        font-size: 0.7rem;
        line-height: 150%;
        color: #464545;
        span {
          color: $sellerPrimary;
        }
      }
    }

    .popular-title {
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 150%;
      color: #000000;
    }

    .card-footer {
      text-align: center;
      .buy-link {
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.03em;
        color: $sellerPrimary;
        text-decoration: none;
      }
    }
  }

  .dropdown-toggle::after {
    content: none;
  }
  .dropdown-menu {
    z-index: 99;
  }
}

@media (max-width: 500px) {
  .popular-title {
    font-size: 0.7rem !important;
  }
  .prod-company {
    font-size: 0.7rem !important;
  }
  .prod-price {
    font-size: 0.7rem !important;
  }
}
