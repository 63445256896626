@import "../../../../../../../Assets/Scss/primaryColor";
.seller-all-product {
  height: 100%;

  .pagination {
    margin-top: 30px;

    .pagination-bar {
    }
  }

  .sp-title {
    font-weight: 800;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #303030;
    padding-left: 6px;
  }

  .btn-addnew {
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 19px;
    height: 44px;
    border-radius: 100px;
    @media (min-width: 1200px) {
      width: 149px;
    }
    @media (max-width: 1200px) {
      width: 100%;
    }

    height: 37px;
    color: #ffffff;
    box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
      inset 0px -1px 0px rgba(14, 14, 44, 0.4);
    border-radius: 8px;
  }

  .search-container {
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 5rem;
    width: 300px;
    border-radius: 100px;
    .fas {
      // position: absolute;
      // top: 15px;
      // left: 30px;
      opacity: 0.5;
      font-size: 0.9rem;
    }
    .search {
      height: 44px;
      width: 236px;
      border: none;
      margin-right: 20px;

      font-size: 0.9rem;

      &:focus {
        border-color: rgba(224, 224, 224, 0.651);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
          0 0 8px rgba(206, 206, 206, 0.6);
        outline: 0 none;
      }
    }
  }

  .modal-title {
    font-size: 0.8rem !important;
  }

  .emptySearch {
    background-color: #dbdbe0;
    color: #ffffff;
    width: 90px;
    height: 90px;
    padding: 10px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: bolder;
  }
}
