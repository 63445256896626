.global-filter {
  .search-box {
    position: relative;
    .input {
      box-sizing: border-box;
      background: #eff2f6;
      border: 1px solid #ecedef;
      border-radius: 100px;
      padding-left: 50px;
      width: 268px;
      height: 37px;
      &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 18px;
        display: flex;
        align-items: center;
        font-feature-settings: "liga" off;
        color: #828282;
        padding-left: 50px;
      }
    }
    .search-icon {
      position: absolute;
      left: 6px;
      top: 6px;
      width: 15px;
    }
  }
}
