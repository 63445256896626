.biz-profile-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eff2f6;
  border: 1px solid #dfe4ea;
  border-radius: 8px; 
  height: 168px;
  width: 100%;

  .biz-logo-name {
    display: flex;
    height: 102px;
    width: 236px;
    justify-content: flex-start;
    align-items: center;
    .avatar-container{
      width: 102px;
    }
    .biz-info-container{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 26px;
      padding-left: 9px;
      height: 90px;

      .biz-name {
        font-size: 24px;
        margin: 0;
        font-weight: 600;
        line-height: 32px;
        text-align: left;
        color: #333333;
      }
    .cacNo {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
    .address {
            margin: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      width: 236px;
      height: 36px;
    }}
    
  }

  .update-btn {
    background: #ffffff;
    border-radius: 100px;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #34ba96;
    border: 1px solid #34ba96;
   
    @media (min-width: 700px) { 
      height: 48px;
      width: 100%;
      font-size: .9rem;
    
    }

    @media (max-width: 700px) { 
      height: 38px;
      width: 100%;
      font-size: .9rem;

    }

    img {
      margin-right: 5px;
    }
  }
}
