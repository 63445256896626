.enterpriseReorder{
 
        margin-top: 17px;

        .desc-top{
            font-size: .9rem;
            font-style: normal;
            font-weight: 400; 
            line-height: 30px; 
            color: #606060; 
            margin-bottom: 10px;
            margin-top: 30px;
        }
    
        .seach-field{
            position: relative;
    
            @media (max-width:800px) {
                margin-top: 20px;
            }
    
            .search-input{
                background: #FFFFFF;
                border: 1px solid #DFE4EA;
                border-radius: 100px;
                height: 40px;
                padding-left:40px; 
    
                &::placeholder{  
                    font-weight: 500;
                    font-size: .73rem;
                    line-height: 150%; 
                    color: #868484;
                }
            } 
          
        }

        .reorder-count{ 
            font-style: normal;
            font-weight: 400;
            font-size: .9rem;
            line-height: 24px; 
            color: #303030;
            height: 50px;
            display: flex-start; 
            align-items: center;

        }

        .filter-product{
            position: relative;

            select{
                padding-left: 70px;
                padding-right: 10px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 23px; 
                color: #F5A623;
            }

            .filter-icon{
                position: absolute;
                left:10px;
                font-size: .8rem;
                color: #868484;
                display: flex;
                align-items: center;
                @media (max-width:1095px) { 
                   top:30px;
                }
                @media (min-width:1095px) { 
                    top:10px;
                }
            }
        }

        .filter-warehouse{
            position: relative;

            select{
                padding-left: 96px;
                padding-right: 10px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 23px; 
                color: #F5A623;
            }

            .filter-icon{
                position: absolute;
                top:10px;
                left:10px;
                font-size: .8rem;
                color: #868484; 
                display: flex;
                align-items: center;
                @media (max-width:800px) {
                    height: 57px;
                }
            }
        }
    
    
        .search-icon{
            position: absolute;
            top:12px;
            left:10px;
        }
        
        .btn-filter{ 
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 100px;
            font-style: normal;
            font-weight: 500;
            font-size: .76rem;
            line-height: 150%; 
            color: #868484;
            height: 40px;
            width: 100%;
            
            @media (max-width:800px) {
                margin-top: 20px;
            }
    
    
            .btn-icon{
                margin-right: 10px;
                width: 16px;
            }
    
        }
    
        .btn-new-reorder{ 
            background: #F5A623;
            border: 1px solid #DFE4EA;
            border-radius: 100px;font-style: normal;
            font-weight: 400;
            font-size: .8rem;
            line-height: 18px; 
            display: flex;
            align-items: center;
            text-align: center;
            height: 40px;
            width: 80%;
            display: flex;
            justify-content: center; 
            color: #FFFFFF;
    
            @media (max-width:800px) {
                margin-top: 20px;
            }
    
            svg{
                color: #ffffff;
                width: 16px;
                margin-right: 10px;
            }
        }
    
    
    
        .enterprise-table{
            .table{
                border: 1px solid #DFE4EA;
                border-radius: 20px !important;
                margin-top: 20px;
    
                thead{
                    th{
                        font-style: normal;
                        font-weight: 500;
                        font-size: .77rem;
                        line-height: 18px; 
                        color: rgba(76, 76, 76, 0.81);
                    }
                }
    
                tbody{
                    td{
                        font-style: normal;
                        font-weight: 500;
                        font-size: .88rem;
                        line-height: 150%;  
                        color: #606060;

                        .product-image{ 
                            width: 30px;
                            height: 34px;
                            margin-right:5px;
                        }

                        .btn-reorder{ 
                            background: rgba(245, 166, 35, 0.15);
                            border-radius: 100px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: .8rem;
                            line-height: 18px; 
                            display: flex;
                            align-items: center;
                            text-align: center;  
                            color: #F5A623; 
                            flex: none;
                            order: 1;
                            flex-grow: 0;
                        }


    
                    }
    
                    @media (max-width:800px) {  
                        .profile-title{
                            
                        }
    
                        .profile-detail{
                            font-size: .74rem !important;
                            line-height: 13px;
                        }
    
                        .profile-btn{
                            height: 70px; 
                            display: flex;
                            justify-content: center;
                            align-items: center;
    
                            .btn{ 
                                border: 1px solid #DFE4EA;
                                border-radius: 100px;
                                font-style: normal;
                                font-weight: 500;
                                // width: 90px;
                                font-size: .7rem;
    
                            }
                        }
                    }
                    @media (min-width:800px) {  
    
                        .profile-detail{
                            // font-size: .5rem !important;
                            // line-height: 14px;
                        }
                    }
                }
    
                .table-profile{
                    @media (max-width:800px) { 
                        width:55px;
                        height:55px;
                        border-radius: 5px; 
                    }
                    @media (min-width:800px) { 
                        width:28px;
                        height:28px;
                        border-radius: 50px; 
                    }
                }
    
                .dropdown-toggle{
                    &::after{
                        display: none;
                    }
    
                    .d-icon{
                        color: #606060;
                        font-size: .9rem; 
                    }
                }
            }
        }
    
        .dropdown-menu{
            li{
                font-style: normal;
                font-weight: 400;
                font-size: .9rem;
                line-height: 18px; 
                display: flex;
                align-items: center; 
                color: #333333;
            }
        }
    
    }