@import "../../../../../../../Assets/Scss/primaryColor";

.EstateSubscriberRecurrentPaymentDetails{
    .page-title{
        display: flex;
        align-items: center; 
        flex-wrap: wrap;

        h2{ 
            font-style: normal;
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 38px; 
            color: #333333;
            display: flex;
        } 

      h2 {
        margin-right: 10px; 
    }


    
    .ids {
        margin-right: 10px;   
        font-size: .9rem;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: right;

    }

    .open{   
        display: flex;
        justify-content:center;
        padding: 10px;
        border-radius: 15px;
        gap: 10px;  
        font-size: .8rem;
        font-weight: 500;
        line-height: 10px;
        letter-spacing: 0px;
        text-align: right;
        color: #01A63E;
        background: #E8F6ED;
        text-align: center;




    }
     
    @media screen and (max-width: 767px) {
        
       flex-direction: column;
       align-items: flex-start;
      
    }
    
    
}
    .button-container{
        position: relative;

        svg{
            margin-right: 5px;
        }

        .btn-edit{ 
            border: 1px solid $primary-estate;  
            color: $primary-estate;  
            border-radius: 20px;  
            margin-right: 10px;
            font-size: .78rem; 
        }

        .btn-subscribe{
            background-color: $primary-estate; 
            color: $white; 
            border-radius: 20px;  
            font-size: .78rem;
        }
    }

 

    .created-time{
        font-size: .9rem;  
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        color: #797A7C; 
        margin-top: 16px;

    }

    .price{ 
        font-size: 2rem;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;  
        color: #3A0CA3;

        .unit{
            font-size: .7rem;
            color: #333333;
            font-weight: 500;
        }
    }

    .label{  
        font-size: .8rem;
        font-weight: 600; 
        letter-spacing: 0px;
        text-align: left; 
        color: #303132;

    }

    .amount{  
        font-size: .8rem;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left; 
    }
   

    .subscribers-card{
        margin-top: 60px;

        .title{
           font-size: .8rem;
           font-weight: 600;
           color: #000000;
           line-height: 30px;
        }

        .see-all{ 
           font-size: .8rem;
           color: #080808;
           font-weight: 500; 
           line-height: 30px;
        }
    }

    thead{
        td{ 
           font-size: .8rem;
        }
    }
    tbody{
        td{ 
            .profile-avarter{
                  display: inline-flex;
                  height: 25px;
                  width: 25px;
                  justify-content: center;
                  align-items: center;
                  background: #E4E4E5;
                  border-radius: 50px;
                  margin-right: 4px;
                  font-size: .8rem; 
            }
            
            font-size: .8rem;
        }
    }

    .lg-image{
        border-radius: 10px;
    }

    .amount-label-text{ 
        font-size: .9rem;
        font-weight: 400; 
        letter-spacing: 0px;
        text-align: left;
        color: #000000; 
    }
    .amount-text{  
        font-size: 2rem;
        font-weight: 700; 
        letter-spacing: 0px;
        text-align: left;
        color: #3A0CA3; 
    }

    .alert{
        background: linear-gradient(0deg, #FCEAEA, #FCEAEA),
           linear-gradient(0deg, #E73F3F, #E73F3F);  
        font-size: 12px;
        font-weight: 400; 
        letter-spacing: 0px;
        text-align: left;
        border: 1px solid #E73F3F

    }

    .btn-pay-now{
        width: 100%;
        height: 50px; 
        border-radius: 100px; 
        background-color: $primary-estate !important; 
        color:white !important;  
        font-size: .9rem;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;

    }

    .walletInformation{
        .btn{ 
          background-color: $primary-estate; 
        }
    }

      
    .terms{
        input{
            margin-right: 7px;
            accent-color: $primary-estate;
        }
        height: 20px;
        display: flex;
        align-items: center;
        font-size: .9rem;

        a{
            color: $primary-estate;
        }
    }







    input[type="range"] {
        /* removing default appearance */
        -webkit-appearance: none;
        appearance: none; 
        /* creating a custom design */
        width: 100%;
        cursor: pointer;
        outline: none;
        /*  slider progress trick  */
        overflow: hidden;
        border-radius: 16px;
      }
      
      /* Track: webkit browsers */
      input[type="range"]::-webkit-slider-runnable-track {
        height: 15px;
        background: #ccc;
        border-radius: 16px;
      }
      
      /* Track: Mozilla Firefox */
      input[type="range"]::-moz-range-track {
        height: 9px;
        background: #ccc;
        border-radius: 16px;
      }
      
      /* Thumb: webkit */
      input[type="range"]::-webkit-slider-thumb {
        /* removing default appearance */
        -webkit-appearance: none;
        appearance: none; 
        /* creating a custom design */
        height: 15px;
        width: 15px;
        background-color: #fff;
        border-radius: 50%;
        border: 2px solid $primary-estate;
        /*  slider progress trick  */
        box-shadow: -407px 0 0 400px $primary-estate;
      }
      
      
      /* Thumb: Firefox */
      input[type="range"]::-moz-range-thumb {
        height: 15px;
        width: 15px;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid $primary-estate;
        /*  slider progress trick  */
        box-shadow: -407px 0 0 400px $primary-estate;
      }

      .form-range {
        width: 100%;
        height: 0.9rem;
      }
      
}