.productVariation{
    .title{
        font-size: .8rem;
        text-transform: capitalize; 
        line-height: 19px;
        color: #333333;
        margin-bottom: 10px;
    }
    .btn-variable{
        border-radius: 6px;
        border: 1px solid rgb(193, 192, 192);
        color: #525252;
        font-size: .78rem;
        height: 50px; 
    }

    .view_all{
      font-size: .7rem;
      margin-bottom: -40px;
    }
}



.productVariation-modal {
 
  @media (min-width: 900px) { 
    width: 500px !important;
  }
 
  
    #contained-modal-title-vcenter{
        font-size: 1.2rem;
    }
    .imgTag{
        width: 50px;
    }

    .var_attributes{
      font-size: .7rem;
      margin-top: 10px;
    }
    .btn-primary{
        font-size: .7rem;
        font-weight: 600;
    }
    .cart-btn{
      padding: 4px;
    }
    .alert-modal-inner {
      width: 701px;
      height: auto !important;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 24px;
      .icon-container {
        width: 82px;
        height: 82px;
        display: flex;
        justify-self: center;
        align-self: center;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        .d-icon {
          font-size: 3rem;
          font-weight: 900;
          color: white;
        }
      }
    }
    .alertType {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #010f2c;
      margin-top: 60px;
    }
  
    .gap-2 {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .contText {
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 150%;
      text-align: center;
      color: #868484;
      width: 80%;
      margin: 0 auto;
      padding-top: 20px;
    }
  
    .btn0 {
      padding: 12px 24px;
      width: 146px;
      height: 49px;
      font-size: 0.9rem;
      background: #3378f3;
      box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
        inset 0px -1px 0px rgba(14, 14, 44, 0.4);
    }
  
    @media (max-width: 1300px) {
      .alert-modal-inner {
        width: 100% !important;
      }
      .btn0 {
        width: 190px !important;
      }
    }
  }
  