.categories{
    position: relative;
    .section-header{
        h2{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            display: flex;
            align-items: center; 
            color: #333333;
        }
    }
    .category-row{
        display: flex;
        flex-direction: row;

        .col-5{
            flex: 20%;
            margin: 10px; 

            .product-card{ 
                background: #FFFFFF; 
                border: 1px solid #E0E0E0 !important;
                border-radius: 10px; 
                position: relative;

                &:hover{
                    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
                }
               
                .product-image{
                    height:100%;
                    width: 100%;
                    border-radius: 10px; 
                }
              .card-body{
                position: relative;
                 display: flex;
                 justify-content: center;
                 
                .category-name{ 
                    position: absolute;
                    bottom: 20px;
                    z-index: 99;
                    width: 80%;  
                    background: #FFFFFF;
                    border-radius: 40px;
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: .89rem;
                    line-height: 24px; 
                    color: #000000;

                } 
              }
 
            }
        }
    }
 
    .carousel-control-container{ 
        width:80px !important; 
    
        .carousel-control-next{ 
            height: 35px;
            width: 35px;
            background-color: #DFE4EA !important;
            opacity: 1;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center; 
            
            .islarge{
                font-size: 1.3rem;  
                color: #333333;
                font-weight: 600 !important;
            } 
        } 
        
        .carousel-control-prev{ 
            height: 35px;
            width: 35px;
            background-color: #DFE4EA !important;
            opacity: 1;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center; 
            
            .islarge{
                font-size: 1.3rem;  
                color: #333333;
                font-weight: 600 !important;
            } 
        } 
    }
    .carousel-indicators-container{
        position: relative;
        margin-top: 50px;

        .carousel-indicators{
            position: absolute;
            left: 0;
            display: flex;
            flex-direction: row;
            
            button{
                flex: 20%;
                width: 100%;
                height: 1px !important;
                margin: 0;
                background: #a4a4a4 ;
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent; 
                &.active{
                    background: #333333 !important;
                    border-radius: 1px;
                    height: 4px;
                }
            }
        }
    }
}