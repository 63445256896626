.builder360{
    margin-top: 20px;

    .card{ 
        background: #F9F9F9;
        border: 1px solid #DFE4EA;
        border-radius: 8px;

        .card-header{
            background: #F9F9F9; 
            border:none;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%; 
            color: #000000;
        }

        .desc{
            font-weight: 400;
            font-size: .86rem;
            line-height: 150%; 
            color: #868484;
        }

        .menu{

            .card{ 
                height: 131px; 
                background: #ffffff;
                border: 1px solid #DFE4EA;
                border-radius: 8px;
                margin-top: 10px;
                margin-bottom: 10px; 
                
                &.active{ 
                    background: #000000;
                    .title{ 
                        color: #f7f7f7 !important;  
                    }
                }

                .card-body{  
                    height: 131px; 
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    .lock{
                        position: absolute;
                        right: 10px;
                        top:10px
                    }
                }
                

                .title{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center; 
                    color: #333333;  
                }
            }
        }
    }
}