.store-registration-form-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 26px;
  align-items: center;
  background-color: white;
  h3 {
    padding-top: 2rem;
    width: 500px;
  }
  .stepper-container {
    width: 496px;
    margin: 9px 0px;
  }

  .store-registration-form {
    display: flex;
    flex-direction: column;
    row-gap: 46px;
    margin-right: 12px;

    .store-form-btn {
      border: 1px solid #dfe4ea;
      border-radius: 100px;
      width: 240px;
      font-size: 0.9rem;
      height: 46px;
      margin-top: 1.5rem;
      background-color: #34ba96 !important;
      color: #ffffff;
      font-weight: 500;
      // margin-right: auto;
      // margin-left: auto;
    }

    .back{
      margin-right: 20px;
      color:  #34ba96 !important;
       border: 1px solid #34ba96 !important;
       background-color: #ffffff !important;
    }
    .btn-lg {
 
      background-color: #34ba96;
      height: 46px;
      width: 500px;
      color: #ffffff;
      border: 1px solid #dfe4ea;
      border-radius: 100px;
    }

    .form-section-header {
      max-width: 598px;
      margin-bottom: 50px;
    }

    .form-section-title {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #333333;
    }

    .form-section-description {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #606060;
    }
    .form-label {
      font-size: 0.7rem;
    }
    .form-input {
      background: #ffffff;
      border: 1px solid #dfe4ea;
      border-radius: 100px;
      height: 46px;
    }

    .form-input-error {
      border: 1px solid #ff9f9f;
      background: #ffffff;
      border-radius: 100px;
      height: 46px;
    }

    .custom-form-select {
      border: 1px solid #dfe4ea;
      border-radius: 100px;
      height: 46px;
    }

    .custom-form-select-error {
      border: 1px solid #ff9f9f;
      border-radius: 100px;
      height: 46px;
    }

    .input-btn {
      background: #eff2f6;
      border: 1px solid #dfe4ea;
      border-radius: 100px 0px 0px 100px;
      height: 46px;
      font-size: 0.8rem;
    }
    .btns-container {
       margin-right: 4rem;

      @media (min-width: 768px) {
        flex-direction: row;
        column-gap: 20px;
        align-self: flex-end;
      }
    }
  }
}
