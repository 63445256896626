.createProduct{
    .buyerPageTitle{

        .backArrow {
            background: #eff2f700 !important;
            width: 22px !important;
            height: 22px !important;  
            overflow: hidden;
            border-radius: 100%; 
        }
        

        h2{
            font-size: 1rem;
            padding: 0px !important;
            margin-top: 7px; 
            font-weight: 400 !important;
        }
    }


    .form-container{
        padding-bottom: 100px;

        @media(min-width:900px){
            margin: 0 40px;
        }
        label{
            font-size: .8rem;
        }
       .form-control{
         border-radius: 50px;
         border: 1px solid rgba(210, 210, 210, 0.521);
       } 

       .location{
        font-size: .9rem;
        color: rgb(0, 99, 213);
       }

       .multi-variant{
        label{
            display: flex;
            flex-direction: row;
            height: 30px;
            align-items: center;

            span{
                margin-left: 8px;
            }

            input{
                height: 17px;
                width: 17px;
                accent-color: rgb(74, 196, 137);
                border: 1px solid rgba(154, 154, 154, 0.142) !important;
            }
        }
       }

       hr{  
        background: #FFFFFF;
        border: 1px solid #DFE4EA; 
       }
    }

    .upload-container{
        .upload-card{  
            width: 235px;
            height: 175px; 
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F9F9F9;
            border: 1px solid #DFE4EA;
            border-radius: 8px;
            cursor: pointer;

            .card-body{
                text-align: center; 
                height: 175px; 
                align-items: center;
                display: inline-flex;

                label{ 
                  cursor: pointer;
                }
                .text{
                    font-size: .8rem;
                    margin-top:10px;
                    font-style: normal;
                    font-weight: 400; 
                    line-height: 19px;
                    text-align: center; 
                    color: #333333;
                }
            }
        }

        .title{ 
            font-style: normal;
            font-weight: 400;
            font-size: .9rem; 
            color: #000000; 
            margin-bottom: 6px;
        }

        .instruction{ 
            font-style: normal;
            font-weight: 400;
            font-size: 12px;  
            color: #333333;
            margin-bottom: 20px;
        } 
    }


  .uploadedImgCont{
    width: 100%;
    height: 175px; 

    .card-body{
      position: relative;
      overflow: hidden;
      .image-wrap{ 
        width: 100%;
        height: 175px; 
        display: flex;
        justify-content: center;
        align-items: center;
      }
       .btn{
        width: 15px;
        height: 15px;
        position:absolute;
        right:0;
        border:1px solid #000000;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        i{
          font-size: .5rem !important;
        }
      } 
    }
  }
    .upload-button{
        margin-top: 40px;
        margin-bottom: 50px;
        .btn-create{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 12px 24px;   
            width: 230px;
            height: 56px; 
            background: #34BA96; 
            box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
            border-radius: 100px;
            color: #DFE4EA; 
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            display: flex;
            align-items: center;
            letter-spacing: 0.03em; 
            color: #FFFFFF;  
            flex: none;
            order: 1;
            flex-grow: 0;
        }
    }

    .create-brand{
      display: flex;
      justify-content: flex-end;
        .btn{
          font-size: .8rem;
          height: 30px;  
          display: flex;
          flex-direction: row;

          span:first-child{
            display:inline-block;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center; 
            font-size: .8rem;
          }
          span:nth-child(2){
            display:inline-block;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center; 
            font-size: .9rem;
          }
        } 
     } 



       //  Add and select input field
  .dropdown-form-element {
    margin: 20px auto;
    position: relative;

    .dropdown-select {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      position: relative;
      overflow: hidden;
      background-color: #fff;
      box-sizing: content-box;

      .select-btn {
        position: absolute;
        width: 30px;
        top: 0;
        right: 0;
        height: 100%;
        border-left: 1px solid #ddd;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .select-btn:active {
        background-color: #f1f1f1;
      }
    }

    .dropdown-list {
      width: 100%;
      background-color: #fff;
      border: 1px solid #ddd;
      z-index: 10;
      list-style: none;
      margin: 0;
      padding: 0;
      height: 150px;
      overflow: auto;
      position: absolute;
      margin-top: 2px;

      .dropdown-item {
        padding: 5px 20px;
        margin: 0;
        cursor: pointer;
        .form-add-new {
          border-radius: 8px !important;
          font-size: 0.7rem;
          color: rgb(56, 56, 56);
          &:focus {
            box-shadow: none !important;
            border: 1px solid rgb(230, 230, 230) !important;
            outline: rgb(230, 230, 230);
            border-radius: 5px !important;
          }
        }
        .btn {
          border: 1px solid rgb(230, 230, 230) !important;
          color: rgb(170, 169, 169);
          &:hover {
            color: rgb(247, 247, 247);
          }
        }
        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }

  .commissionText{
    font-size: .7rem;
  }


 
}


.createBrandModal {
    margin-top: 200px; 

    @media (max-width: 600px) { 
        width: 100%; 
      } 

      @media (min-width: 600px) { 
        width: 436px; 
      } 

    // .modal-content {
    background: #FFFFFF;
    border: 1px solid #DFE4EA;
    border-radius: 28px;
    //   }

    .modal-content{
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 28px;
    }
    .form-control{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        height: 50px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px; 
        font-feature-settings: 'liga' off; 
        color: #828282;

      

        &:focus{
            outline: none !important;
            box-shadow: none !important;
        }
    }

    select{
        font-size: .8rem !important;
        option{ 
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: .8rem !important;
            line-height: 18px; 
            font-feature-settings: 'liga' off; 
            color: #828282;
        }

    }
    
    
    .apply{  
        width: 100%;
        height: 56px; 
        background: #3378F3; 
        color: #DFE4EA;
        font-weight: 800;
        box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
        border-radius: 100px;
    }

    .apply-outline-success{ 
        width: 100%;
        height: 56px; 
        font-weight: 800;
        color: #FFFFFF;
        background: #34BA96 !important; 
        border-radius: 100px;
        background: #3379f300; 
        box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
        border-radius: 100px;

    }
   
   .btn-close {
        height: 15px;
        width: 15px; 
        border: 1px solid grey;
        border-radius: 50px; 
        font-size: .7rem !important;
    } 

    label{
        font-size: .8rem;
        cursor: pointer;
    }
    .brand-upload{
        width: 100%;
        height: 139px; 
        background: #F9F9F9;
        border: 1px solid #DFE4EA;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
 
       .card-body{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: .7rem;
        line-height: 19px;
        text-align: center; 
        color: #333333;
        cursor: pointer;

       }
    }
 }