@import "../../../../../../../Assets/Scss/primaryColor";
.enterpriseWareHouseShipToSiteCheckout{
   margin-top: 17px;

   .order-summary-card{
        background: #F2F2F2;
        border: 1px solid #DFE4EA;
        border-radius: 8px;
        padding-left: 10px;
        padding-right: 10px;
            .value{
                margin-top: 20px;
                font-style: normal;
                font-weight: 400;
                font-size: .9rem;
                line-height: 19px; 
                color: #333333;
            }
            .title{
                font-style: normal;
                font-weight: 700;
                font-size: .9rem;
                line-height: 24px;  
                color: #333333;
            }
            .amount{ 
                font-style: normal;
                font-weight: 700;
                font-size: .9rem;
                line-height: 24px;  
                color: #333333;
            } 
            .card-footer{ 
                background: #F2F2F2;
                border-top: 1px solid #E0E0E0 !important;
            }
    }

    .payment-option{
        font-size: .8rem;
        margin-top: 20px;
        font-weight: 600; 
        line-height: 19px; 
        color: #000000; 

        img{
            width: 25px;
            margin-top: 6px;
        }
    }

    .paynow{   
        width: 100%;
        height: 50px; 
        background: #F5A623;
        border-radius: 100px;
        margin-top: 20px;
        font-style: normal;
        font-weight: 700;
        font-size: .9rem;
        line-height: 18px;  
        color: #FFFFFF; 
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .paynow-estate{ 
        background: $primary-estate !important;
    }

    input[type="checkbox"]{
        accent-color:#F5A623;
    } 

    .text-terms{
        color: #F5A623;
    }

    .terms{
        height:30px;
        display: flex; 
        align-items: center;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #E0E0E0;
        font-size: .8rem;
    }

    .payment-card{ 
        border: 1px solid #DFE4EA;
        border-radius: 8px;

        .list-group{
            .list-group-item{
                height: 20px;
                display: flex; 
                align-items: center;
                padding-bottom: 30px;
                font-size: .9rem;

               
            }
        }

       
        .card-header{
            font-style: normal;
            font-weight: 700;
            font-size: 1.12rem;
            line-height: 29px;  
            color: #333333;
            background-color: #ffffff; 
            border: none;
        }

        .wallet{
            .card{
                @media (max-width:800px) {
                    width:100%
                }
            }
        }

        .wallet{
            margin-top: 10px;
             .card{
                width: 439px; 
                background: #F2F2F2;
                border: 1px solid #DFE4EA;
                border-radius: 8px;

            .title{
                font-size: .9rem;
            }
            .price{
                font-style: normal;
                font-weight: 700;
                font-size: 1rem;
                line-height: 29px; 
                display: flex;
                align-items: center; 
                color: #333333;
            }
            .btn{
                width: 100%;
                height: 46px;  
                border: 1px solid #F5A623;
                border-radius: 100px; 
                font-style: normal;
                font-weight: 400;
                font-size: .8rem;
                line-height: 18px; 
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center; 
                color: #F5A623;
                margin-top: 30px;
            }
        }
        }
    }


    .btn{
        &.active{ 
            background: #4379EB;
            color:rgb(255, 255, 255);
            border: 1px solid #4379EB;
        }
    }


    .site-card{
        background: #FFFFFF; 

        .accordion-button{
            font-size: .9rem;
            height: 46px;
            background-color: #ffffff;
            color: #292D32 !important;
        }

        .accordion-button::after {
             background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-plus-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");

            transform: scale(.7) !important; 
            border-radius: 50px !important;
            padding: 10px; 
            background-size: center !important; 
            text-align: center;  
            color: #292D32 !important;
          }

          
          .accordion-button:not(.collapsed)::after { 
            border-radius: 50px !important;
            border: 1.5px solid #292D32;  
            color: #292D32 !important;
            padding: 10px; 
            background-position:center!important;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  width='16' height='16' fill='currentColor' class='bi bi-plus-circle' viewBox='0 0 23 25'%3E%3Cpath d='M15.9199 12.75H7.91992C7.50992 12.75 7.16992 12.41 7.16992 12C7.16992 11.59 7.50992 11.25 7.91992 11.25H15.9199C16.3299 11.25 16.6699 11.59 16.6699 12C16.6699 12.41 16.3399 12.75 15.9199 12.75Z'/%3E%3C/svg%3E");
 
          }

          .site-item{
            border: 1px solid #DFE4EA;
            tr{
                border-bottom: 1px solid #eeeeee;
                
                td{
                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-size:.84rem
                }
            }
            .site-item-image{
                width:50px

            }
          }
    }

    .form-check-input:checked {
        background-color: #F5A623;
        border-color: #F5A623;
      }
    
}

 

 