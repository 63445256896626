@import "../../../../../../../Assets/Scss/primaryColor";

.add-new-bank-account-modal{
  margin-top: 200px;  
  background: #FFFFFF;
  border: 1px solid #DFE4EA;
  border-radius: 28px; 

  .modal-title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 32px; 
    display: flex;
    align-items: center; 
    color: #333333;
  }

  .modal-content{
      background: #FFFFFF;
      border: 1px solid #DFE4EA;
      border-radius: 28px; 
  }
  .form-control{ 
      background: #FFFFFF;
      border: 1px solid #DFE4EA;
      border-radius: 100px;
      height: 50px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px; 
      font-feature-settings: 'liga' off; 
      color: #828282;

    

      &:focus{
          outline: none !important;
          box-shadow: none !important;
      }
  }

  select{
      font-size: .8rem !important;
      option{ 
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: .8rem !important;
          line-height: 18px; 
          font-feature-settings: 'liga' off; 
          color: #828282;
      }

  }

  .apply{  
      width: 100%;
      height: 56px; 
      background: #3378F3; 
      color: #DFE4EA;
      font-weight: 800;
      box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
      border-radius: 100px;
  }

  .apply-outline{ 
      width: 100%;
      height: 56px; 
      font-weight: 800;
      color: #3378F3;
      background: #3379f300; 
      box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
      border-radius: 100px;

  }
 
 .btn-close {
      height: 15px;
      width: 15px; 
      border: 1px solid grey;
      border-radius: 50px; 
      font-size: .7rem !important;
  } 


    .add-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      border: 1px solid $primary;
      box-sizing: border-box;
      border-radius: 100px; 
      font-weight: 600;
      font-size: 0.9rem;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 0.03em;
      color: $primary;
      background-color: $primary;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 20px 0;
      &:hover {
        color: rgb(243, 242, 242);
      }
  }

  label{
    font-style: normal;
    font-weight: 400;
    font-size: .8rem;
    line-height: 19px; 
    color: #333333;

  }
}


// .add-bank-account {
//   .add-bank-account-cont {
//     height: 50vh;
//     .add-bank-account-title {
//       font-weight: 500;
//       font-size: 0.97rem;
//       line-height: 150%;
//       color: $dark;
//     }
//   }
//   .form-control {
//     height: 45px;
//     border: none;
//     border-radius: 8px;
//     padding-left: 50px;
//     background: #ecf1f4;
//     box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1) !important;
//     border-radius: 8px;
//     font-size: 0.8rem;
//     font-weight: 500;
//   }
//   .form-input-wrap {
//     position: relative;
//     .form-input-symbol {
//       position: absolute;
//       height: 45px;
//       width: 45px;
//       left: 50;
//       border: 1px solid #dedede;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       border-top-left-radius: 10px;
//       border-bottom-left-radius: 10px;
//       font-weight: 500;
//       font-size: 14px;
//       line-height: 150%;
//       color: #868484;
//     }
//     .form-input-logo {
//       position: absolute;
//       top: 0;
//       right: 0;
//       padding-right: 20px;
//       padding-top: 7px;
//       img {
//         width: 37px;
//         height: 24px;
//         margin: 5px;
//         background-color: $white;
//         padding: 5px;
//       }
//     }
//   }
//   .add-btn {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding: 12px 24px;
//     border: 1px solid $primary;
//     box-sizing: border-box;
//     border-radius: 8px;

//     font-weight: 600;
//     font-size: 0.9rem;
//     line-height: 19px;
//     display: flex;
//     align-items: center;
//     letter-spacing: 0.03em;
//     color: $primary;
//     flex: none;
//     order: 1;
//     flex-grow: 0;
//     &:hover {
//       color: rgb(243, 242, 242);
//     }
//   }
// }

// .bankConfirmPass {
//   font-weight: 500;
//   font-size: 0.9rem;
//   line-height: 150%;
//   color: #444444;
// }
// .rounded-confirm-bank {
//   border-radius: 10px;
// }
// .addbank-btn {
//   width: 450px;
//   height: 40px;
//   background: $primary;
//   box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
//     inset 0px -1px 0px rgba(14, 14, 44, 0.4);
//   border-radius: 8px;
// }

// .form-control--add-bank {
//   height: 45px;
//   width: 100%;
//   border: none;
//   border-radius: 8px;
//   padding-left: 50px;
//   background: #ecf1f4;
//   box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1) !important;
//   border-radius: 8px;
//   font-size: 0.8rem;
//   font-weight: 500;
//   &:focus {
//     border-color: rgba(224, 224, 224, 0.651);
//     box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
//       0 0 8px rgba(206, 206, 206, 0.6);
//     outline: 0 none;
//   }
// }
