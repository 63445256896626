.buyerNotification{
  margin-top: 17px;

  .card{
    background: #FFFFFF;
    border: 1px solid #DFE4EA;
    border-radius: 8px;
    margin-top: 20px;

    
     .card-header{
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 32px; 
        display: flex;
        align-items: center; 
        color: #333333;
        background-color: rgb(255, 255, 255);
     }

     .card-body{
        .list-group-item:last-child{
          border-bottom: none !important;
        }
        .list-group-item{ 
          border-top: none !important;
          border-left: none !important;
          border-right: none !important;
          border-bottom: 1px solid #e9ebee !important;
        }
        .active{ 
          background: #EFF2F6;
          border: 1px solid #EFF2F6;
          color: #303030;
        }
        .content{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%; 
            display: flex;
            align-items: center; 
            color: #303030;
        }

        .time{
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 140%; 
            color: #606060;
        }
     }
  }
}