.seller-nav-card  {
    list-style: none; 
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #DFE4EA;
    border-radius: 0px;
    // display: flex;
    // column-gap: 18px;
    // align-items: center;
    // padding: 15px 18px;
    // margin-top: 20px;
 
        .icon-container {
            width: 62px;
            height: 62px;
            background: #EFF2F6;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {

            }
        }
        .link-details {
            display: flex;
            flex-direction: column;
            row-gap: 8px;

            .title {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;
                color: #333333;

            }

            .description {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #828282;

            }
        } 
}