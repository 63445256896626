@import "../../../../Assets/Scss/primaryColor";
.sellers-content {
  display: fles;
  flex-direction: column;
  justify-content: space-between;
  .seller-logo{
    width: 130px;
  }
  .nav-item {
    .nav-link {
      font-weight: 400;
      font-size: 0.82rem;
      color: #606060;
      min-height: 38px;
      min-width:200px;
      svg{
        width:24px;
        height: 24px;
        
        path{
          stroke: rgb(50, 50, 50) ; 
          fill: rgb(253, 253, 253) !important; 
        } 
      }

      .bf-icon{ 
        path{
          fill: rgb(50, 50, 50) !important;  
        } 
      }

      .w-22{
        width:17px; 
      }
      .icon {
        width: 20px;
        height: 20px;
        opacity: 0.8;
      }

      .d-arrowhead-right{
        padding-top: 2px;
        color: rgb(255, 255, 255);
      }

      .w-20{
        width:20px
      }

      &.logout-active{
        background: rgba(228, 90, 90, 0.09);
        border-radius: 10px;

        span{ 
          font-style: normal;
          font-weight: 700;  
          color: #E45A5A;
        }
      }
    }

    .active {
      border-radius: 10px;
      color:#34BA96;
;
        svg{
          width:17px;
          path{
            stroke: #34BA96;
            fill: rgb(255, 255, 255) !important; 
          }
        }
        .bf-icon{           
          path{

            fill: rgb(255, 255, 255) !important; 
          } 
        }
        .d-arrowhead-right{
          padding-top: 2px;
          color: rgb(255, 255, 255);
        }
    }
  }
}


.businessProfileModal{
      margin-top: 180px; 
      width: 436px;  
      // .modal-content {
      background: #FFFFFF;
      border: 1px solid #DFE4EA;
      border-radius: 28px;


      .modal-content{
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 28px;
    }
    .form-control{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        height: 50px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px; 
        font-feature-settings: 'liga' off; 
        color: #828282;

      

        &:focus{
            outline: none !important;
            box-shadow: none !important;
        }
    }
    textarea{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        height: 70px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px; 
        font-feature-settings: 'liga' off; 
        color: #828282;

      

        &:focus{
            outline: none !important;
            box-shadow: none !important;
        }

        &::placeholder{
          font-size: .8rem
        }
    }

    label{  
      font-style: normal;
      font-weight: 400;
      font-size: .8rem;
      line-height: 19px; 
      color: #333333; 
    }

    select{
        font-size: .8rem !important;
        option{ 
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: .8rem !important;
            line-height: 18px; 
            font-feature-settings: 'liga' off; 
            color: #828282;
        }

    }
    .desc{
      font-size: .7rem;
      font-weight: 400; 
      line-height: 19px; 
      color: #333333;

    }

    .btn-close {
      height: 12px;
      width: 12px; 
      border: 1px solid grey;
      border-radius: 50px; 
      font-size: .7rem !important;
    }
}