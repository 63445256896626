.VerificationDocumentUploadCard {
    margin-top: 30px;
   
    .title{
        font-size: .8rem;
        margin-bottom: 10px;
        font-weight: 400;
        color: #000000;
    }

   .verificationDocumentUploadCardInner{
        display: flex;
        flex-direction: column;
        align-items: center; 
        padding-top: 30px;
        padding-bottom: 30px;  
        background: #f8f6f6;
        border: 1px solid #DFE4EA;
        border-radius: 8px;

      
   
        .document-desc {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #606060;
        }

        .file-upload {  
            cursor: pointer;

            .file-upload-input {
                display: none;
            }

            .file-upload-label { 
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                text-align: center; 
                display: flex;
                align-items: center;
                text-align: center;
                color: #4379EB;
            }
        }
    }
    .file-upload-preview{ 
        background: #f8f6f6;
        border: 1px solid #DFE4EA;
        border-radius: 8px;

        .file-name{
            font-size: .8rem;
        }

        .uploaded-size{
            font-size: .7rem ; 
        }
        .uploaded-message{
            font-size: .7rem ;
            color: #0000fc; 
        }
    }


}