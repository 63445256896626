.cartMenu{
   position: relative;
    .cart-count{
      position:absolute;
      top: -1px;
      left: 20px; 
      background: #F2994A;
      padding: 2px;
      width:17px;
      height:17px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      color: rgb(255, 255, 255);
      font-weight: 700;
      font-size: .7rem;

    }
}