.secondary-wallet{ 
        &.card{ 
            border: 1px solid #DFE4EA;
            border-radius: 8px;  
            height: 170px;
     
            @media (min-width:700px) { 
                margin-top: 70px !important; 
                background-size:cover;  
             }
             @media (max-width:700px) {   
                background-size:cover; 
                width: 100%;
             }
    
            .card-body{
                position: relative;
    
                .wallet-type{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 150%; 
                    color: #DFE4EA;
                }
    
                .wallet-type-primary-wallet{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 150%; 
                    color: #101010;
                }
                    
    
                .balance{
                    font-size: 2rem;
                    font-weight: 700;
                    color: #FFFFFF;
                }
    
                .balance-primary-wallet{
                    font-size: 2rem;
                    font-weight: 700;
                    color: #101010;
    
                    svg{
                        color: #101010 !important;
                        fill: #101010 !important; 
                    }
                }
                .btn{
                    width: 140px;
                    height: 40px; 
                    border: 1px solid #F5A623;
                    border-radius: 100px;
                    color: #F5A623;
                    position: absolute;
                    bottom: 20px;
                    font-size: .8rem;
                }
                
                .btn-primary-wallet{
                    width: 140px;
                    height: 40px; 
                    border: 1px solid #101010;
                    border-radius: 100px;
                    color: #101010;
                    position: absolute;
                    bottom: 20px;
                    font-size: .8rem;
    
                    svg{
                        color: #101010 !important;
                        fill: #101010 !important; 
                    }
    
                }
    
                    .btn-wallet-type{
                        position: absolute;
                        top:8px;
                        right:-13px !important;
                        font-style: normal;
                        font-weight: 500;
                        font-size: .689rem !important;
                        display: block;
                        line-height: 120%; 
                        color: #000000;
                        width:100px;
                        border: none !important;
                    }
    
                    .btn-wallet-type-primary-wallet{
                        position: absolute;
                        top:0px;
                        right:-13px !important;
                        font-style: normal;
                        font-weight: 700;
                        font-size: .689rem !important;
                        display: block;
                        line-height: 120%; 
                        color: #fcfbfb !important;
                        background-color: #000000; 
                        width:100px;
                        border: none !important;
                        border-radius: 0px;
                        border-bottom-left-radius: 20px;
                        height: 47px;
                    }
    
                    .add-btn{
                        background-color: #F5A623 !important; 
                    }
    
    
                border-radius: 8px;
                
            } 
        } 
    }