.sellerPageTitle{
    h2{ 
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 38px; 
        color: #333333;
        margin-left: 18px;
    }


    .backArrow {
        background: #EFF2F7;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
 
}