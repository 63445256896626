.enterpriseUserManagement{ 
    margin-top: 17px;

    .seach-field{
        position: relative;

        @media (max-width:800px) {
            margin-top: 20px;
        }

        .search-input{
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 100px;
            height: 40px;
            padding-left:40px; 

            &::placeholder{  
                font-weight: 500;
                font-size: .73rem;
                line-height: 150%; 
                color: #868484;
            }
        } 
      
    }


    .search-icon{
        position: absolute;
        top:12px;
        left:10px;
    }
    
    .btn-filter{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        font-style: normal;
        font-weight: 500;
        font-size: .76rem;
        line-height: 150%; 
        color: #868484;
        height: 40px;
        width: 100%;
        
        @media (max-width:800px) {
            margin-top: 20px;
        }


        .btn-icon{
            margin-right: 10px;
            width: 16px;
        }

    }

    .btn-new-user{ 
        background: #F5A623;
        border: 1px solid #DFE4EA;
        border-radius: 100px;font-style: normal;
        font-weight: 400;
        font-size: .8rem;
        line-height: 18px; 
        display: flex;
        align-items: center;
        text-align: center;
        height: 40px;
        width: 100%;
        display: flex;
        justify-content: center; 
        color: #FFFFFF;

        @media (max-width:800px) {
            margin-top: 20px;
        }

        svg{
            color: #ffffff;
            width: 16px;
            margin-right: 10px;
        }
    }



    .enterprise-table{
        .table{
            border: 1px solid #DFE4EA;
            border-radius: 20px !important;
            margin-top: 20px;

            thead{
                th{
                    font-style: normal;
                    font-weight: 500;
                    font-size: .77rem;
                    line-height: 18px; 
                    color: rgba(76, 76, 76, 0.81);
                }
            }

            tbody{
                td{
                    font-style: normal;
                    font-weight: 500;
                    font-size: .88rem;
                    line-height: 150%;  
                    color: #606060;

                }

                @media (max-width:800px) {  
                    .profile-title{
                        
                    }

                    .profile-detail{
                        font-size: .74rem !important;
                        line-height: 13px;
                    }

                    .profile-btn{
                        height: 70px; 
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .btn{ 
                            border: 1px solid #DFE4EA;
                            border-radius: 100px;
                            font-style: normal;
                            font-weight: 500;
                            width: 60px;

                        }
                    }
                }
                @media (min-width:800px) {  

                    .profile-detail{
                        // font-size: .5rem !important;
                        // line-height: 14px;
                    }
                }
            }

            .table-profile{
                @media (max-width:800px) { 
                    width:55px;
                    height:55px;
                    border-radius: 5px; 
                }
                @media (min-width:800px) { 
                    width:28px;
                    height:28px;
                    border-radius: 50px; 
                }
            }

            .dropdown-toggle{
                &::after{
                    display: none;
                }

                .d-icon{
                    color: #606060;
                    font-size: .9rem; 
                }
            }
        }
    }

    .dropdown-menu{
        li{
            font-style: normal;
            font-weight: 400;
            font-size: .9rem;
            line-height: 18px; 
            display: flex;
            align-items: center; 
            color: #333333;
        }
    }

}