.review-list{
    background: #F2F2F2;
    border:none; 
    margin-bottom: 10px; 
    .card-header{ 
        font-style: normal;
        font-weight: 700;
        font-size: .8rem;
        line-height: 24px; 
        display: flex;
        align-items: center; 
        color: #333333;
        background: #F2F2F2;
        border:none;

    }

    .review-demacation{
        border-top: 1px solid rgb(231, 231, 231);
        border-radius: 0px;
        margin-top: 10px; 

    }
    .card-body{
        // border-top: 1px solid grey;
    }
    .review-rating{ 
        font-size: .8rem;
        img{
            width:12px;
        }
        span{ 
          font-size: .8rem;
        }
    }

    .review-time{
        font-size: .8rem;
        text-align: right;
    }
}
