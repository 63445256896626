@import "../../../../../../../Assets/Scss/primaryColor";

.EstateManagersSubscriberSetupCompleted{ 

    .login-header{ 
        @media (max-width:768px) {
            padding: 0 30px;
        }
        position: absolute !important;
        top:30px !important; 
        width: 200px; 
        display: flex;
        flex-direction: row;
        height: 30px;
        align-items: center;

        .login-back{ 
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 30px;

            a{ 
                text-decoration: none;
                span{ 
                    font-size: 1.4rem; 
                    padding: 0px;
                    width: 37px;
                    height: 37px; 
                    background: #EFF2F6; 
                    border-radius: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #333333 !important;
                }
            }  
        }

        .login-logo{ 
            img{ 
                width:150px;
            }
        }
    }

    .completed-container{
        height: 90vh;

        .title{ 
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0em;  
        }

        .label{ 
            font-size: .9rem;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center; 
        }

        .btn-login{
            background: $primary-estate;
            border: 1px solid #DFE4EA;
            border-radius: 100px;
            width: 70%;
            height: 46px;
            font-weight: 400;
            font-size: .8rem;
            line-height: 18px;  
            color: #FFFFFF; 
            margin-top: 50px;
        }
    }
}