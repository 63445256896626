@import "./../../../../../Assets/Scss/primaryColor";

.loginCard{
    .card{
        background-color: transparent;

        border: 1px solid #E0E0E0 !important;
        border-radius: 8px;

    h1{ 
        @media (min-width:768px) { 
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 29px; 
            color: #000000;
            margin-bottom: 20px;
        }
        @media (max-width:768px) { 
            font-style: normal;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 29px; 
            color: #1b1b1b;
            margin-bottom: 20px;
        }
    }  

    .form-group{
        label{
            font-style: normal;
            font-weight: 400;
            font-size: .9rem;
            line-height: 19px; 
            color: #333333;
            margin-bottom: 10px;
        }
        .form-control{
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 100px;
        }

        .btn-login{
            background: $primary;
            border: 1px solid #DFE4EA;
            border-radius: 100px;
            width: 100%;
            height: 46px;
            font-weight: 400;
            font-size: .8rem;
            line-height: 18px;  
            color: #FFFFFF; 
        }
    }

    .forgot-password{
        font-style: normal;
        font-weight: 400;
        font-size: .9rem;
        line-height: 19px;
        display: flex;
        align-items: center; 
        color: $primary;
        margin-top: 10px;
        text-decoration: none;
    }

    .signup{
        font-size: .9rem;
        text-decoration: none; 
        color: #000000;
        a{
            text-decoration: none;
        }
    }

    .alert{
        font-size: .8rem;
        .btn-close{
            font-size: .6rem;
            &:focus{
                box-shadow: none!important;
                outline: none !important;
            }
        }
    }

    .password-container {
        position: relative;  
        .field-icon {
          float: right; 
          right: 15px;
          top:9px;
          position: absolute;
          z-index: 5;
          cursor: pointer;
          opacity: 0.6;

        }
      }

      .err{
        font-size: .7rem;
        text-align: end;
      }
    }
}