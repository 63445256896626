.seller-dispute-form {
    margin-top: 50px;

    .seller-dispute-form-label {
        color:#333333;
        font-size: 16px;
        line-height: 19px;
    }

    .seller-dispute-form-field-rounded {
        border-radius: 100px !important;
        border: 1px solid #DFE4EA;
        font-size: 16px;
        line-height: 18px;
        padding: 10px 0;
    }

    textarea {
        border-radius: 20px;
        resize: none !important;
        border: 1px solid #DFE4EA;
        height: 162px;
    }

    button {
        background-color: #34ba96;
        font-size: 16px;
        line-height: 18px;
        padding-top: 20px;
        padding-bottom: 18px;
    }
}