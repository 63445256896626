.step {
    width: 158px;
    height: 5px;
    border-radius: 20px;
    list-style: none;
}

.step-visited {
    background: #F2994A;
}

.step-unvisited {
    background: #D9D9D9;
}