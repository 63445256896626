.SubscribeToProject{

    .title{ 
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0px;
        text-align: center; 
    }

    .desc{ 
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;

    }

    .btn-cancel{
        width: 146px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 100px; 
        border: 1px solid #3A0CA3;
        color: #3A0CA3; 
        font-size: .9rem; 
    }

    .btn-proceed{
        width: 146px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 100px; 
        background: #3A0CA3;
        color: #ffffff;
        font-size: .9rem; 

    }

    .control-btn{
        width: 100%;

        .container{
            width: 100px;
            display: flex;
            justify-content: center;
            font-size: .9rem;
            button{
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #ffffff;
                border: 1px solid #e1e1e1;
                border-radius: 5px;
            }
        }
    }
}