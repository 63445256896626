 
    .btn-save-later{
        width: 100%;
        height: 46px; 
        background: #ffffff;
        border: 1px solid #4379EB;
        border-radius: 100px; 
        img{
            width:15px
           }
           &:hover{ 
             border: 1px solid #0748d2;
           }
           &:active{ 
             border: 1px solid #0748d2;
           }
    }