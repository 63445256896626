.checkout{
    position: relative;
    .section-header{
        h2{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            display: flex;
            align-items: center; 
            color: #333333;
        }
    }

    h1{
        font-weight: 700;
        font-size: 2rem;
        line-height: 48px;  
        color: #333333;

    }

    .location-selected{
        width:15px;
    }

    .location-address{
        font-size: .7rem;
    }

    .steps{ 
        display: inline-block;
        .badge{ 
            width: 25px;
            height: 25px;
            font-style: normal;
            font-weight: 700;
            font-size: .8rem; 
            text-align: center; 
            display: inline-flex;
            justify-content: center;
            color: #FFFFFF;
            background: #333333;
            border-radius: 50px;
            margin-right: 7px;

            &.inactive{
                font-weight: 700; 
                background: #BDBDBD!important;  
                color: #FFFFFF !important;
            }
        }

        span{ 
            font-style: normal;
            font-weight: 700;
            font-size: .9rem; 
            color: #333333;
        }
    }
   

    .ordertype{
        .btn{ 
            @media (max-width:1200px) {  
                width: 100%;
                height: 51px;  
                margin-top:10px
            }

            @media (min-width:1200px) {
                width: 339px;
                height: 51px; 
            }

            border-radius: 8px;
 
            font-style: normal;
            font-weight: 400;
            font-size: .8rem;
            line-height: 19px; 
            color: #333333;
            border: 1px solid #232323;

            &.active{ 
              border-width: 2px;
              border: 2px solid #2a69f0;
            }

            img{
              width:15px;
              margin-right: 7px;
            }
        }
    }


   .cart-item-list{
        margin-top: 30px;
        position: relative;

        .select-pickup{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: .9rem;
            line-height: 19px;
            display: flex;
            align-items: center; 
            color: #333333;
            
        }

     .cart-item{
        border-bottom: 1px solid rgb(231, 231, 231);
        padding-bottom: 15px; 
        position: relative;
        border-radius: 8px;

        hr{
            border: 1px solid rgb(231, 231, 231);
            margin-top: 20px;
        }
      
     .cart-item-list-image{
        width: 100%;
        height: 137px; 
        background: #D9D9D9;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
            width: 100%;
            height: 100%; 
        }
    }
   
    .cart-item-name{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        display: flex;
        align-items: center; 
        color: #333333;

    }

    .cart-item-variation{ 
        font-weight: 400;
        font-size: .9rem;
        line-height: 17px; 
        display: flex;
        align-items: center; 
        color: #353434; 
        margin-top: 5px;
    }

    .cart-item-price{ 
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        height: 137px;
        display: flex-end;
        align-items: center;  
        color: #F2994A; 

    }

  
   .product-attibutes{ 
        position: absolute;
        bottom:15px;
        width: 100%;
        .product-qty{
            width: 80px;
            height: 35px; 
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 100px; 
            padding: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            .form-control{ 
                height: 30px; 
                border: 1px solid #DFE4EA;
                border-radius: 100px; 
                text-align: center;
                &.active{
                    border: 1px solid #d9d9d900;
                    outline: none !important;
                    box-shadow: none !important;
                }

                &.focus{
                    border: 1px solid #d9d9d900;
                    outline: none !important;
                    box-shadow: none !important;
                }
        }
        } 
    }

    .remove-product{
        text-align: center; 
        height: inherit; 
        padding-top: 6px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px; 
        display: flex;
        align-items: center; 
        color: #333333;
    }

    .save-product{
        text-align: center;
        height: inherit; 
        padding-top: 6px; 
        font-weight: 400;
        font-size: 14px;
        line-height: 17px; 
        display: flex;
        align-items: center; 
        color: #333333;
    }

   }
}

.continue-shoping{
    .btn{
        width: 100%;
        height: 40px;  
        border-radius: 100px;    
        margin-top: 20px;    
        background: #4379EB;
        border: 1px solid #DFE4EA;
        border-radius: 100px; 
        color: #FFFFFF;
        font-size: .8rem;
        line-height: 18px;    
        font-weight: 400;  
        display: flex;
        align-items: center;
        justify-content: center;  

    }
}

    .wallet{
        margin-top: 10px;
      .card{
        width: 439px; 
        background: #F2F2F2;
        border: 1px solid #DFE4EA;
        border-radius: 8px;

        .title{
            font-size: .9rem;
        }
        .price{
            font-style: normal;
            font-weight: 700;
            font-size: 1rem;
            line-height: 29px; 
            display: flex;
            align-items: center; 
            color: #333333;
        }
        .btn{
            width: 100%;
            height: 46px;  
            border: 1px solid #4379EB;
            border-radius: 100px; 
            font-style: normal;
            font-weight: 400;
            font-size: .8rem;
            line-height: 18px; 
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center; 
            color: #4379EB;
            margin-top: 30px;
        }
      }
    }


    hr{
        border: 1px solid rgb(216, 216, 216);
        margin-top: 20px;
    }

    .cart-summary{ 
        background: #F2F2F2;
        border: 1px solid #DFE4EA;
        border-radius: 8px;

        .card-header{
            background: #F2F2F2;
            font-weight: 700;
            font-size: .98rem;
            line-height: 24px; 
            display: flex;
            align-items: center; 
            color: #333333;
            
        }

        .card-body{
            font-size: .9rem;
            background: #F2F2F2;
            .cart-total{
                font-weight: 900;
            }
            .cart-el{
                font-size: .9rem;
                margin-top: 6px;
            }
        }

        .checkout-btn{
            width: 379px;
            height: 46px; 
            background: #4379EB;
            border: 1px solid #DFE4EA;
            border-radius: 100px;
            font-weight: 400;
            font-size: .9rem;
            line-height: 18px;  
            color: #FFFFFF;
        }
        hr{ 
        border: 1px solid #E0E0E0;
        }

        .havecoupon{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: .8rem;
            line-height: 19px; 
            color: #333333;
            margin-bottom: 15px;
        }

        .form-control{ 
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 100px;
        }
        .apply-btn{
            width: 379px;
            height: 56px; 
            background: #333333;
            border: 1px solid #DFE4EA;
            border-radius: 100px;
            font-weight: 400;
            font-size: .9rem;
            line-height: 18px;  
            color: #FFFFFF;
        }

       
    }
 
    .payment-option{
        font-size: .8rem;
        margin-top: 20px;
        font-weight: 600; 
        line-height: 19px; 
        color: #000000; 

        img{
            width: 25px;
            margin-top: 6px;
        }
    }

    .accordion{
        background-color: #fdfbfb;
        border-radius: 8px;

        .accordion-header{
            border: none;
        }
        .accordion-body{ 
            background-color: #fdfbfb;
            border-radius: 8px;
            border: 1px solid rgba(103, 101, 101, 0.152);
        }
        .accordion-collapse{
            background-color: #fdfbfb !important;

            &.show{
                border: 1px solid red;
            }
        }

        .accordion-button:not(.collapsed)
        { 
            box-shadow: none !important;
            border-radius: 8px;

        }

        .accordion-button 
        { 
            box-shadow: none !important;
            border-radius: 8px;

        }
    .accordion-item{
        background-color: #ffffff00 !important; 
        border: 1px solid #57575719;
        border-radius: 8px;
        margin-bottom: 10px ;

        .accordion-button:not(.collapsed) {
            background-color: #ffffff00 !important;
        }

        .accordion-button::after {
            position: absolute;
            top:20px;
            right:20px;
            color:#000000 !important;
        }
      }
    }
    .list-group{
        .list-group-item{
            background-color: #fbfbfb00 !important;
            font-weight: 400;
            font-size: .9rem;
            line-height: 24px; 
            display: flex;
            align-items: center; 
            color: #333333;
            
        }
    }


    .form-check-input{
        width: 15px;
        height: 15px;
    }

    .checkout-total{
        margin-top: 20px;
        .row{ 
            font-weight: 700;
            font-size: 1rem; 
            display: flex;
            align-items: center; 
            color: #333333;
        }
      .btn{
        width: 100%;
        height: 45px; 
        background: #BDBDBD;
        border-radius: 100px;
        margin-top: 20px; 
        font-style: normal;
        font-weight: 700;
        font-size: .9rem;
        line-height: 18px;  
        color: #828282;
      }
    }

    .cart-products{
        margin-top: 30px;
        .your-order{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: .8rem;
            line-height: 19px;
            display: flex;
            align-items: center; 
            color: #000000;
        }
    }

    .inactive{
        font-weight: 700; 
        color: #BDBDBD !important; 
    }
}