.faq-container {
  height: 409px;
  width: 1140.8704833984375px;
  background: #34ba96;
  border-radius: 8px;
  margin-top: 20px;
  padding: 49px 35px;

  .user-first-name {
    font-style: normal;
    font-weight: 800;
    font-size: 33.291px;
    line-height: 40px;
    color: white;
    letter-spacing: -0.01em;
  }

  .cta-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
  }
}
