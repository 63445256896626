@import "../../../../../../../Assets/Scss/primaryColor";
.history {
  @media (max-width: 984px) {
    b {
      font-weight: 600;
      font-size: 0.8rem;
      line-height: 24px;
      color: #303030;
    }
    .iconImage {
      width: 18px;
    }
    .desc {
      font-weight: 700;
      font-size: 0.6rem;
      line-height: 24px;
    }
  }
  @media (min-width: 984px) {
    b {
      font-weight: 600;
      font-size: 1rem;
      line-height: 24px;
      color: #303030;
    }
    .iconImage {
      width: 20px;
    }

    .desc {
      font-weight: 700;
      font-size: 0.77rem;
      line-height: 24px;
    }
  }

  .timeTrans {
    font-size: 0.67rem;
  }

  .d-circle {
    font-size: 0.5rem;
    width: 11px;
    height: 11px;
  }

  .amount {
    font-weight: 700;
    font-size: 0.77rem;
    line-height: 24px;
  }

  .status {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 100px;
    padding: 20px 5px;

  }

  .status-pending { 
    background: rgba(237, 184, 46, 0.15);
    color: #EDB82E;
  }
  
  .status-confirmed {
    background: rgba(0, 193, 43, 0.15);
    color:#00C12B;
  }

  .status-declined {
    background: rgba(228, 90, 90, 0.15);;
    color: #E45A5A;
  }

  .history-desc {
    min-width: 100px;
  }

  .pagination {
    .page-link {
      font-size: 0.8rem;
      color: #868484;
      font-weight: 500;
      padding: 12px;
    }
    .page-item.active {
      .page-link {
        background: rgba(82, 140, 245, 0.1);
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        border: 1px solid rgba(82, 140, 245, 0.1);
        color: $dark;
      }
    }
  }
  .formSlectOption-wrap {
    height: auto;
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 30px;

    .form-control {
      background: #ffffff;
      box-shadow: inset 0px 1px 1px -1px rgba(86, 86, 121, 0.1);
      border-radius: 5px;
      font-size: 0.9rem;
      padding: 10px 10px;
      width: 100px;
      height: 43px;
      &:focus {
        border-color: rgba(224, 224, 224, 0.651);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
          0 0 8px rgba(206, 206, 206, 0.6);
        outline: 0 none;
      }
    }
  }
  @media (max-width: 984px) {
    .atr {
      width: 150px;
    }
    .amount {
      font-weight: 700;
      font-size: 0.6rem !important;
      line-height: 24px;
    }
  }

  .history-column{
    height:80px;
    display: flex; 
    align-items: center;

    .title_label{  
      font-style: normal; 
      font-size: .9rem;
      line-height: 18px;   
    }
  }


  .dropdown-toggle::after {
    display: none; 
  }

  .history-inner{
    background: #FFFFFF;
    border: 1px solid #DFE4EA;
    border-radius: 8px;
  }

  .history-title{
    font-weight: 500;
    font-size: 1rem;
    line-height: 150%; 
    color: #303030; 
  }

  span{
    font-weight: 400;
    font-size: .88rem;
    line-height: 22px; 
    color: #34BA96; 
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
