.enterpriseProduct{
    .page-header{
        margin-top: 34px;
        margin-bottom: 10px;
        padding: 0 22px;
        .page-title{
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px; 
            color: #303030;
        }
    }

    .search-container{
        position: relative;
        @media (max-width:800px) {
            margin-top: 10px;
        }
        input{
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 100px;
            width: 100%;
            padding: 0 15px 0 35px;
            height: 41px;

            &::placeholder{ 
                font-weight: 500;
                font-size: 14px;
                line-height: 150%; 
                color: #868484;
            }
        }

        .search-icon{
            width: 15px;
            position: absolute;
            left:10px;
            top: 14px; 
        }
    }


    .filter-container{
        position: relative;
        height: 41px;
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        display: flex;
        justify-content: flex-end;

        @media (max-width:800px) {
            margin-top: 10px;
        }

        select{
            background: #FFFFFF;
            border: 1px solid #ffffff;
            border-radius: 100px;
            width: 90%;
            padding: 0 15px 0 35px;
            height: 30px; 
            margin: 2px; 
            font-size: .9rem !important;
            color: #F5A623;  
            margin-top: 5px; 

            &::placeholder{ 
                font-weight: 500;
                font-size: .9rem;
                line-height: 150%; 
                color: #868484;
            }
            option{
                padding-left: 20px;
                font-size: .9rem !important;
                font-style: normal;
                font-weight: 400; 
                line-height: 23px; 
                color: #F5A623 !important; 
            }
        }

        .filter-icon-text{ 
            position: absolute;
            left:10px;
            top: 10px; 
            font-size: .9rem;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%; 
            color: #868484;
        }

    }


    .pagination{
        position: relative;
        .pagination-item{
            background-color: #ffffff!important;
            border: 1px solid #ffffff;

            &.selected{ 
                font-weight: 500;
                font-size: 14px;
                line-height: 150%; 
                color: #F5A623 !important;  
                background-color: #ffffff!important;
                border: 1px solid #ffffff;
            }
        } 
        
        .pagination-item:last-child{ 
            border: 1px solid grey;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 50px;
            position: absolute;
            right: 0; 
            
            .right{
                content: "\2192"; 
            }
        }

        .pagination-item:first-child{ 
            border: 1px solid grey;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 50px;
            position: absolute;
            left: 0;

            
        } 
        .left::before{  

        }
        .right::before{  

        }
    }

    .horizontaLoader{
        height: 30px;
    }
}