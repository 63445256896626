.bankAccountItemCard {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: stretch;
    padding:30px;
    background: #EFF2F6;
    border: 1px solid #DFE4EA;
    border-radius: 8px;

    .item {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
    }

    .value {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
    }
}