.productQuantity{ 
    label{  
        font-size: .8rem;
        line-height: 19px; 
        color: #333333; 
        margin-bottom: 15px;
    }

    .form-control{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
    }
}