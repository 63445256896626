.seller-main-plp {
    .plp-apply-btn {
        background: #34ba96;
        border: 1px solid #DFE4EA;
        border-radius: 80px;
        height: 36px; 
        font-size: .9rem; 
        font-style: normal;
        font-weight: 400; 
        line-height: 18px;  
        text-align: center; 
        color: #FFFFFF;
    }   

    .noPLP{
        height: 250px;
        width: 100%;
        background: #EFF2F6; 
        border: 1px solid #DFE4EA;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: .9rem;
        line-height: 19px; 
        color: #000000;

    }
}