.enterprise-bankingAndFinance{ 
    .buyerPageTitle{
        a{ 
          color: #F5A623 !important;
        }
    }
    
    .card{
        @media (max-width:700px) {
            margin: 15px 7px;
        }

        @media (min-width:700px) { 
            margin: 8px 5px;
        }

        height: 215px; 
        background: #FFFFFF;
        border: 1px solid #e9e6e6;
        border-radius: 8px;
        font-size: .8rem;
        line-height: 16px;
        cursor: pointer;
        
        .card-body{ 
            height: 215px; 
            display: flex;
            justify-content: center;
            align-items: center;


            .card-container{
                width: inherit; 
                display: flex;
                justify-content: center; 
                
                .card-icon{
                    width: 72px;
                    height: 72px; 
                    background: #FFFAF5;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center; 
                    
                } 

                .card-icon-estate{ 
                    width: 72px;
                    height: 72px; 
                    background: #F0F3FC; 
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center; 
                }
            } 

            .lock{
                position: absolute;
                right: 10px;
                top:10px
            }
        }
    }
}