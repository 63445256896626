@import "../../../../../../../Assets/Scss/primaryColor";
.buyer-profile-contact-details {
    .title {
        font-style: normal;
        font-weight: 400;
        font-size: .9rem;
        line-height: 19px;
        text-transform: uppercase;
        color: #767676;
    }

    .item {
        .details {
            background: #FFFFFF;
            border: 1px solid #DFE4EA;
            border-radius: 8px;
            padding: 14px 24px;
            .details-title {
                font-style: normal;
                font-weight: 400;
                font-size: .9rem;
                line-height: 19px;
                color: #333333;
            }

            .details-value {
                font-style: normal;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 18px;
                color: #333333;
            }

        }
    }

    .form-control{
        background: #EFF2F6;
        border: 1px solid #DFE4EA;
        border-radius: 100px;

    }

    .btn{
        width: 100%;
        background: #FFFFFF; 
        border: 1px solid #F5A623;
        border-radius: 100px;
        color:#F5A623;
        margin-top: 20px; 
        font-style: normal;
        font-weight: 600;
        font-size: .8rem;
        line-height: 30px;
    }

    .btn-estate{
        width: 100%;
        background: $primary-estate; 
        border: 1px solid $primary-estate;
        border-radius: 100px;
        color: #ffffff;
        margin-top: 20px; 
        font-style: normal;
        font-weight: 600;
        font-size: .8rem;
        line-height: 40px; 
    }

}