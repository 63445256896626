@import "../../../../Assets/Scss/primaryColor";
.business-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-right: 9rem;

  .bank-form-card {
    width: 514px;
    padding: 20px;
    // margin-right: 14rem;
    > h3 {
      font-size: 24px;
      font-weight: 400px;
      color: black;
    }
    > p {
      color: #606060;
      font-size: 16px;
    }
    .bank-form {
      width: 100%;
      // margin-left: 16px;
    }
  }
  .form-group {
    padding-left: 10px;
    padding-right: 20px;
    .bank-input {
      border: 1px solid #dfe4ea;
      margin-top: 6px;
      border-radius: 50px;
      width: 480px;
    }
  }
}
@media only screen and (max-width: 479px) {
}

@media (min-width: 768px) {
  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
}
@media (min-width: 585px) and (max-width: 749px) {
}

@media (max-width: 1400px) {
}
