.biz-basic-Info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) { 
    padding: 0px 0px;
    height: 130px;
    width: 100%;
  }

  @media (min-width: 600px) { 
    padding: 3px 31px; 
    height: 130px;
    width: 479px;
  }

  .company-logo {
    display: flex;
    row-gap: 18px;
    height: 102px;
    width: 236px;
    justify-content: space-around;
  }
  .header-text-container {
    height: 75px;
    width: 331px;
    .heading {
      height: 19px;
      width: 105px; 
      font-weight: 400;
      line-height: 19px;

      @media (max-width: 600px) { 
        font-size: .7rem;
      }
    
      @media (min-width: 600px) { 
        font-size: 16px;
      }
    }
    .text {
      height: 48px;
      width: 331px; 
      font-weight: 450;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      @media (max-width: 600px) { 
        font-size: .7rem;
      }
    
      @media (min-width: 600px) { 
        font-size: 16px;
      }
    }
  }
}
